import Button from "../../../components/Button/Button"
import CustomEditor from "../../../components/Form/Editor/CustomEditor"

interface NewsFormProps {
	content: string
	onSave: () => void
	setContent: (newContent: string) => void
}

function NewsForm({ content, onSave, setContent }: NewsFormProps) {
	return (
		<>
			<CustomEditor value={content} setValue={setContent} />
			<Button type="submit" className="bg-primary" onClick={onSave}>
				Publier
			</Button>
		</>
	)
}

export default NewsForm
