interface CardProps {
	children: React.ReactNode
	className?: string
	onClick?: any
}

function Card({ children, className, onClick }: CardProps) {
	return (
		<div
			className={`card ${className ?? ""}  ${
				onClick ? "hover:border-primary cursor-pointer" : ""
			}`}
			data-testid="card"
			onClick={onClick}
		>
			{children}
		</div>
	)
}

export default Card
