import Modal from "../../../components/Dialog/Modal"
import TableInstance from "../../../components/Table/Instance"
import usePaginationSearchSortOnTable from "../../../hooks/usePaginationSearchSortOnTable"
import { columns } from "./Constants"
import ReasonForm from "./ReasonForm"
import useReasonsAdmin from "./hooks/useReasonsAdmin"

function ReasonsPage() {
	const {
		query,
		page,
		size,
		onChangeSize,
		onSearch,
		onChangePage,
		sortBy,
		onChangeSortBy,
	} = usePaginationSearchSortOnTable()

	const {
		rows,
		reasons,
		openReasonDialog,
		reason,
		dialog,
		closeDialog,
		onSubmit,
	} = useReasonsAdmin(query, size, page, sortBy)
	return (
		<div className="page reasons">
			<h1 className="text-center pb-3 pt-3">Les raisons</h1>

			<TableInstance
				cols={columns}
				rows={rows}
				query={{ value: query, setValue: onSearch }}
				openFormDialog={() => openReasonDialog()}
				setSortBy={onChangeSortBy}
				manualPagination
				page={page}
				size={size}
				setPage={onChangePage}
				setSize={onChangeSize}
				rowData={reasons.data}
			/>

			<Modal
				title={reason.id ? "Editer une raison" : "Créer une raison"}
				isOpen={dialog}
				onClose={closeDialog}
			>
				<ReasonForm reason={reason} onSubmit={onSubmit} />
			</Modal>
		</div>
	)
}

export default ReasonsPage
