import React from "react"
import { useForm } from "react-hook-form"
import Reason from "../../../api/models/Reason"
import Button from "../../../components/Button/Button"
import Input from "../../../components/Form/Input/Input"

interface ReasonFormProps {
	reason: Reason
	onSubmit: (data: { label: string }) => void
}

function ReasonForm({ reason, onSubmit }: ReasonFormProps) {
	const form = useForm<{ label: string }>({
		defaultValues: {
			label: reason.label,
		},
	})

	return (
		<form onSubmit={form.handleSubmit(onSubmit)}>
			<Input
				name="label"
				register={form.register}
				type="text"
				error={form.formState.errors.label}
				label="Intitulé"
				required
			/>

			<Button type="submit" className="bg-primary w-full mt-4">
				{reason.id ? "Sauvegarder" : "Créer"}
			</Button>
		</form>
	)
}

export default ReasonForm
