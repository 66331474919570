import { useState } from "react"
import { useQuery } from "react-query"
import News from "../../../../api/models/News"
import newsService from "../../../../api/services/NewsService"
import { newsId } from "../../../Home/Constants"

interface UseGetNewsReturn {
	content: string
	setContent: (newContent: string) => void
}

function useGetNewsAsEditorState(): UseGetNewsReturn {
	const [content, setContent] = useState<string>("")

	useQuery<News>("news", () => newsService.getById(newsId), {
		onSuccess: (data: News) => {
			setContent(data.html)
		},
	})

	return { content, setContent }
}

export default useGetNewsAsEditorState
