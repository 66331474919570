import SimpleUser from "../models/SimpleUser"
import StartChatUser from "../models/StartChatUser"
import StatusModel from "../models/StatusModel"
import User from "../models/User"
import GeneralService from "./GeneralService"

class UserService extends GeneralService<User | any> {
	getUserStartingChatInfo(id: string): Promise<StartChatUser> {
		return this.get(`/${id}/starting-chat-info`)
	}

	getPublicProfile(id: number): Promise<SimpleUser> {
		return this.get(`/${id}/public`)
	}

	updateUserNotation(id: number, note: StatusModel) {
		return this.post(note, `/${id}/notation`)
	}
}

const userService = new UserService("users")

export default userService
