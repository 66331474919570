import { StarIcon } from "@heroicons/react/outline"

interface ShowUserNotationProps {
	note: number
	noteCount: number
	className?: string
}

export default function ShowUserNotation({
	note,
	noteCount,
	className,
}: ShowUserNotationProps) {
	return (
		<div className={`${className} flex flex-row`}>
			<StarIcon className="w-6 fill-yellowStar" />
			<p className="font-bold">
				{Math.round(note * 10) / 10}
				/5 ({noteCount} avis)
			</p>
		</div>
	)
}
