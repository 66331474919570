import Location from "./Location"

class ExchangePlace extends Location {
	id: number = 0

	name: string = ""

	numbersOfPosts: number = 0
}

export default ExchangePlace
