import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline"
import { useState } from "react"
import Input from "./Input"

interface PasswordProps {
	label?: string
	name: string
	register: any
	required: boolean
	placeholder?: string
	error?: any
}

function Password({
	label,
	name,
	register,
	required = false,
	placeholder = "",
	error,
}: PasswordProps) {
	const [passwordType, setPasswordType] = useState("password")
	const togglePassword = () => {
		if (passwordType === "password") {
			setPasswordType("text")
			return
		}
		setPasswordType("password")
	}
	return (
		<Input
			label={label}
			name={name}
			placeholder={placeholder}
			register={register}
			type={passwordType}
			required={required}
			error={error}
			className="pr-5"
		>
			{passwordType === "password" ? (
				<EyeIcon
					onClick={togglePassword}
					className="w-4 absolute right-1 top-9 cursor-pointer"
				/>
			) : (
				<EyeOffIcon
					onClick={togglePassword}
					className="w-4 absolute right-1 top-9 cursor-pointer"
				/>
			)}
		</Input>
	)
}

export default Password
