import ChangePasswordForm from "./ChangePasswordForm"
import PageWrapper from "./PageWrapper"
import ProfileForm from "./ProfileForm"
import ProfileNote from "./ProfileNote"
import useSelfProfile from "./hooks/useSelfProfile"

function SelfProfile() {
	const { selfUser, onProfilSubmit, passwordForm, onPasswordSubmit } =
		useSelfProfile()
	return (
		<PageWrapper>
			<>
				{selfUser.isSuccess && (
					<ProfileForm
						user={selfUser.data}
						onProfilSubmit={onProfilSubmit}
					/>
				)}
				{selfUser.isSuccess && <ProfileNote user={selfUser.data} />}
				{selfUser.data?.facebookId ? (
					""
				) : (
					<ChangePasswordForm
						passwordForm={passwordForm}
						onPasswordSubmit={onPasswordSubmit}
					/>
				)}
			</>
		</PageWrapper>
	)
}

export default SelfProfile
