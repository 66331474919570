import Post from "../../../api/models/Post"
import ToggleStatusModel from "../../../api/models/ToggleStatusModel"
import Modal from "../../../components/Dialog/Modal"
import TableInstance from "../../../components/Table/Instance"
import Status from "../../../enums/Status"
import useMultipleStatuses from "../../../hooks/useMultipleStatuses"
import usePaginationSearchSortOnTable from "../../../hooks/usePaginationSearchSortOnTable"
import { columns } from "./Constants"
import MultipleStatusSelect from "./MultipleStatusSelect"
import SelectMarketForm from "./SelectMarketForm"
import UpdateStatusForm from "./UpdateStatusForm"
import usePostsAdmin from "./hooks/usePostsAdmin"
import useSelectMarketForm from "./hooks/useSelectMarketForm"

function AdminPosts() {
	const {
		query,
		page,
		size,
		onChangeSize,
		onSearch,
		onChangePage,
		sortBy,
		onChangeSortBy,
	} = usePaginationSearchSortOnTable()

	const { statuses, onChangeStatuses } = useMultipleStatuses([
		Status.TO_VALIDATE,
	])

	const {
		selectedPost,
		setSelectedPost,
		rows,
		posts,
		updateStatus,
		selectedStatus,
		setSelectedStatus,
	} = usePostsAdmin(query, size, page, sortBy, statuses)

	const { onSubmit, marketId, onChangeMarketSelection, getOptions } =
		useSelectMarketForm(
			setSelectedStatus,
			selectedStatus,
			updateStatus.mutate
		)

	return (
		<div className="page admin-posts">
			<h1 className="text-center pb-3 pt-3">Les annonces</h1>

			<Modal
				isOpen={selectedPost.id > 0}
				onClose={() => setSelectedPost(new Post())}
				title="Modifier le statut"
			>
				<UpdateStatusForm
					post={selectedPost}
					setPost={setSelectedPost}
					updateStatus={updateStatus.mutate}
				/>
			</Modal>

			<Modal
				isOpen={selectedStatus.id > 0}
				onClose={() => setSelectedStatus(new ToggleStatusModel())}
				title="Choix du marché"
			>
				<SelectMarketForm
					status={selectedStatus}
					onSubmit={onSubmit}
					marketId={marketId}
					onChangeMarketSelection={onChangeMarketSelection}
					options={getOptions()}
				/>
			</Modal>

			{rows ? (
				<TableInstance
					Filter={
						<MultipleStatusSelect
							statuses={statuses}
							OnChangeStatuses={onChangeStatuses}
						/>
					}
					cols={columns}
					setSortBy={onChangeSortBy}
					rows={rows}
					query={{ value: query, setValue: onSearch }}
					manualPagination
					page={page}
					size={size}
					setPage={onChangePage}
					setSize={onChangeSize}
					rowData={posts.data}
				/>
			) : (
				""
			)}
		</div>
	)
}

export default AdminPosts
