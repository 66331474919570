import CGUModal from "./CGUModal"
import FacebookMethod from "./FacebookMethod"
import useCGUModal from "./hooks/useCGUModal"
import useQuizIsAnswer from "./hooks/useQuizIsAnswer"
import useRegisterForm from "./hooks/useRegisterForm"
import RegisterForm from "./RegisterForm"

function Register() {
	useQuizIsAnswer()

	const {
		title,
		responseFacebook,
		facebookUsedClassName,
		onSubmit,
		register,
		handleSubmit,
		errors,
		setValue,
		hidePassword,
		control,
	} = useRegisterForm()

	const {
		isOpenModalCGU,
		onCheckboxChange,
		isChecked,
		acceptCGU,
		closeCGUModal,
		canAcceptCGU,
		allowAcceptCGU,
	} = useCGUModal()

	return (
		<div className="page register">
			<FacebookMethod
				title={title}
				responseFacebook={responseFacebook}
				facebookUsedClassName={facebookUsedClassName}
			/>
			<RegisterForm
				onSubmit={onSubmit}
				register={register}
				handleSubmit={handleSubmit}
				errors={errors}
				onCheckboxChange={onCheckboxChange}
				setValue={setValue}
				title={title}
				hidePassword={hidePassword}
				CGUChecked={isChecked}
				control={control}
			/>
			<CGUModal
				acceptCGU={acceptCGU}
				allowAcceptCGU={allowAcceptCGU}
				canAcceptCGU={canAcceptCGU}
				closeCGUModal={closeCGUModal}
				isOpenModalCGU={isOpenModalCGU}
			/>
		</div>
	)
}

export default Register
