import { useState } from "react"

interface UseCguModalType {
	isOpenModalCGU: boolean
	onCheckboxChange(e: React.ChangeEvent<HTMLInputElement>): void
	isChecked: boolean
	acceptCGU(): void
	closeCGUModal(): void
	allowAcceptCGU(e: any): void
	canAcceptCGU: boolean
}

function useCGUModal(): UseCguModalType {
	const [isOpenModalCGU, setIsOpenModalCGU] = useState<boolean>(false)
	const [isChecked, setIsChecked] = useState<boolean>(false)
	const [canAcceptCGU, setCanAcceptCGU] = useState<boolean>(false)

	const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setIsChecked(e.target.checked)
		setIsOpenModalCGU(e.target.checked)
	}

	function acceptCGU() {
		setIsChecked(true)
		setIsOpenModalCGU(false)
		setCanAcceptCGU(false)
	}

	function closeCGUModal() {
		setIsChecked(false)
		setIsOpenModalCGU(false)
		setCanAcceptCGU(false)
	}

	function allowAcceptCGU(e: any) {
		const bottom =
			e.target.scrollHeight - e.target.scrollTop <=
			e.target.clientHeight + 20
		if (bottom) {
			setCanAcceptCGU(true)
		}
	}

	return {
		isOpenModalCGU,
		onCheckboxChange,
		isChecked,
		acceptCGU,
		closeCGUModal,
		allowAcceptCGU,
		canAcceptCGU,
	}
}

export default useCGUModal
