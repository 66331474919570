import React, { useContext, useEffect, useState } from "react"
import { useMutation, useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import Post from "../../../api/models/Post"
import SimpleUser from "../../../api/models/SimpleUser"
import postService from "../../../api/services/PostService"
import userService from "../../../api/services/UserService"
import Button from "../../../components/Button/Button"
import Card from "../../../components/Card/Card"
import NotationField from "../../../components/Form/Notation/NotationField"
import UserContext from "../../../contexts/UserContext"
import Status from "../../../enums/Status"
import { showConfirm } from "../../../utils/Popup"

function NotationPage() {
	const params = useParams()
	const navigate = useNavigate()
	const { user: currentUser } = useContext(UserContext)
	const [rating, setRating] = useState<number>(0)

	const user = useQuery<SimpleUser>(
		"user",
		() => userService.getPublicProfile(Number(params.id)),
		{
			onSuccess: (data: SimpleUser) => {
				if (currentUser.id === data.id) {
					showConfirm(
						"Tu ne peux pas t'auto-noter. Ça serait trop simple, non ?",
						"error"
					)
					navigate("/")
				}
			},
		}
	)

	const post = useQuery<Post>(
		"post",
		() => postService.getById(Number(params.postId)),
		{
			retry: false,
			onSuccess: (data: Post) => {
				if (currentUser.id !== data.receiverId) {
					showConfirm(
						"Tu ne peux pas noter cet utilisateur.",
						"error"
					)
					navigate("/")
				}
			},
			onError: () => {
				navigate("/")
			},
		}
	)

	const postNotation = useMutation(
		() =>
			userService.updateUserNotation(user.data!.id, {
				note: rating,
				status: Status.ACTIVATED,
				postId: post.data!.id,
				userId: String(user.data?.id),
				user: user.data,
				askedInMarket: post.data!.askedInMarket,
			}),
		{
			onSuccess: () => {
				showConfirm(
					`Merci d'avoir évaluer votre échange avec ${user.data?.firstName} ${user.data?.lastName}`
				)
				navigate("/")
			},
		}
	)

	const saveNotation = () => {
		postNotation.mutate()
	}

	useEffect(() => {
		user.refetch()
		post.refetch()
	}, [params.id, params.postId])

	return (
		<div className="page user-notation">
			<h1 className="my-4 text-center">
				Evaluer votre échange avec {user.data?.firstName}{" "}
				{user.data?.lastName}
			</h1>
			<Card className="section data p-4">
				<h4 className="text-center">
					Merci de donner un nombre d'étoiles compris entre 1 et 5.
				</h4>
				<NotationField setNote={setRating} />
				{rating > 0 ? (
					<Button
						type="button"
						className="my-4 bg-primary w-full"
						onClick={saveNotation}
					>
						Soumettre
					</Button>
				) : (
					""
				)}
			</Card>
		</div>
	)
}

export default NotationPage
