import Filters from "../pages/Post/Search/Filters"
import { IPostType } from "./Constants"

const formatPostFilters = (
	filters: Filters,
	selectedType: IPostType,
	options: any = {}
) => ({
	...filters,
	...options,
	type: selectedType.id,
	statuses: filters.statuses.join(","),
	category: filters.categoryId ? filters.categoryId : null,
	subCategory: filters.subCategoryId ? filters.subCategoryId : null,
	categoryId: null,
	subCategoryId: null,
})

export default formatPostFilters
