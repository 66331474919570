import Report from "../../../api/models/Report"
import ReportForm from "../../../components/Dialog/Form/ReportForm"
import Modal from "../../../components/Dialog/Modal"

interface ReportModalType {
	isOpen: boolean
	report: Report
	onClose(): void
	setReport: (newReport: Report) => any
	postReport: any
}

function ReportModal({
	isOpen,
	onClose,
	report,
	setReport,
	postReport,
}: ReportModalType) {
	return (
		<Modal title="Signaler une annonce" isOpen={isOpen} onClose={onClose}>
			<ReportForm
				report={report}
				setReport={setReport}
				onSave={postReport.mutate}
			/>
		</Modal>
	)
}

export default ReportModal
