import NoContact from "../../../assets/images/NoContact-rafiki.svg"

export default function NoContactsInfo() {
	return (
		<div className="flex flex-col w-full text-center p-5">
			<h3 className="text-center">
				Vous n'avez pas encore de discussions, consulter les annonces ou
				créer en une !
			</h3>
			<img
				className="mx-auto mt-auto mb-0 h-3/4"
				src={NoContact}
				alt="No contacts"
			/>
		</div>
	)
}
