class RoleModel {
	id: number

	role: string

	constructor(id: number, role: string) {
		this.id = id
		this.role = role
	}
}

export default RoleModel
