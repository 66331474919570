import { useForm } from "react-hook-form"
import ToggleStatusModel from "../../../api/models/ToggleStatusModel"
import Button from "../../../components/Button/Button"
import Select, { Option } from "../../../components/Form/Select/Select"

interface SelectMarketFormProps {
	status: ToggleStatusModel
	onSubmit: (data: ToggleStatusModel) => any
	marketId: string
	onChangeMarketSelection: (evt: any) => void
	options: Option[]
}

function SelectMarketForm({
	status,
	onSubmit,
	marketId,
	onChangeMarketSelection,
	options,
}: SelectMarketFormProps) {
	const form = useForm<ToggleStatusModel>({
		defaultValues: status,
	})

	return (
		<form onSubmit={form.handleSubmit(onSubmit)}>
			<Select
				name="marketId"
				register={form.register}
				error={form.formState.errors.status}
				label="Selectionner un marché"
				options={options}
				value={marketId}
				onChange={onChangeMarketSelection}
			/>

			<Button type="submit" className="bg-primary w-full my-4">
				Soumettre
			</Button>
		</form>
	)
}

export default SelectMarketForm
