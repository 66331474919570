import useGetUserInfo from "../../../hooks/useGetUserInfo"
import useGetPostQuery from "./hooks/useGetPostQuery"
import useReportForm from "../../../hooks/useReportForm"
import ReportModal from "./ReportModal"
import SinglePost from "./SinglePost"

function ViewPage() {
	const { post } = useGetPostQuery()
	const { user } = useGetUserInfo()

	const {
		openReportModal,
		reporting,
		closeReportModal,
		setReport,
		postReport,
		report,
	} = useReportForm(post?.data, user?.data)

	return (
		<div className="page post-view">
			{post.data && (
				<SinglePost
					openReportModal={openReportModal}
					post={post.data}
					user={user.data}
					ReportModal={
						<ReportModal
							isOpen={reporting}
							onClose={closeReportModal}
							postReport={postReport}
							report={report}
							setReport={setReport}
						/>
					}
				/>
			)}
		</div>
	)
}

export default ViewPage
