import { useRef, useState } from "react"
import { Collapse } from "react-collapse"
import { ChevronDownIcon } from "@heroicons/react/outline"
import { ISubMenuItem } from "../../../utils/Constants"
import Link from "./Link"
import useOutsideClick from "../../../hooks/OutsideClick"

interface NavigationLinkProps {
	url: string
	text?: string
	Icon: any
	setOpenSidebar?: any
	subMenu?: Array<ISubMenuItem>
	onClick?: any
}

function NavigationLink({
	url,
	text,
	Icon,
	setOpenSidebar,
	subMenu,
	onClick,
}: NavigationLinkProps) {
	const [openSubMenu, setOpenSubMenu] = useState<boolean>(false)

	const refSubMenu = useRef<HTMLDivElement>(null)

	useOutsideClick(refSubMenu, () => setOpenSubMenu(false))

	const handleClickOnMenu = () => {
		if (subMenu) {
			setOpenSubMenu(!openSubMenu)
		} else {
			setOpenSidebar(false)
		}
	}

	const handleClickOnSubMenu = () => {
		setOpenSubMenu(!openSubMenu)
		setOpenSidebar(false)
	}

	return (
		<>
			<div className="flex items-center" onClick={onClick}>
				<Link
					className={subMenu ? "!text-black" : ""}
					url={subMenu ? "#" : url}
					Icon={Icon}
					text={text ?? ""}
					onClick={() => handleClickOnMenu()}
				/>
				{subMenu && (
					<ChevronDownIcon
						onClick={() => handleClickOnMenu()}
						className={`${
							openSubMenu ? "rotate-180" : ""
						} w-3  mr-1 transition duration-300 cursor-pointer ml-auto`}
					/>
				)}
			</div>

			{subMenu && (
				// for subMenu
				<div className="sub-menu" ref={refSubMenu}>
					<Collapse isOpened={openSubMenu}>
						{subMenu.map(s => (
							<Link
								key={s.url}
								url={s.url}
								Icon={s.Icon}
								text={s.text}
								onClick={() => handleClickOnSubMenu()}
							/>
						))}
					</Collapse>
				</div>
			)}
		</>
	)
}

export default NavigationLink
