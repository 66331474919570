import Post from "../../../../api/models/Post"
import useGetExchangePlaceQuery from "../hooks/api/useGetExchangePlaceQuery"
import useGetPostQuery from "../hooks/api/useGetPostQuery"
import useUpdatePostQuery from "../hooks/api/useUpdatePostQuery"
import useGenericForm from "../hooks/basic/useGenericForm"
import PostForm from "../PostForm"
import {
	checkIfAddressIsSelected,
	currentValidationForm,
	exchangePlacesToReactSelectionOptions,
	formatFormPostToPost,
} from "../utils/util"

function Edit() {
	const { connectedUser, selectedIndex, setSelectedIndex } = useGenericForm()

	const { postQueryResult } = useGetPostQuery()

	const { updatePostQuery } = useUpdatePostQuery()

	const { places } = useGetExchangePlaceQuery()

	const submitForm = (data: any) => {
		const post: Post = formatFormPostToPost(
			data,
			connectedUser,
			places.data || []
		)
		if (checkIfAddressIsSelected(post)) {
			updatePostQuery.mutate(post)
		}
	}

	return postQueryResult.data ? (
		<PostForm
			preloadedData={postQueryResult.data}
			submitForm={submitForm}
			selectedIndex={selectedIndex}
			currentValidationForm={currentValidationForm}
			setSelectedIndex={setSelectedIndex}
			exchangePlaceOptions={
				(places.data &&
					exchangePlacesToReactSelectionOptions(places.data)) ||
				[]
			}
		/>
	) : (
		<div />
	)
}

export default Edit
