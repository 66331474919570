import TableInstance from "../../../components/Table/Instance"
import usePaginationSearchSortOnTable from "../../../hooks/usePaginationSearchSortOnTable"
import { columns } from "./Constants"
import useAlertsAdmin from "./hooks/useAlertsAdmin"

function AlertsPage() {
	const {
		query,
		page,
		size,
		onChangeSize,
		onSearch,
		onChangePage,
		sortBy,
		onChangeSortBy,
	} = usePaginationSearchSortOnTable()

	const { rows, alerts } = useAlertsAdmin(query, size, page, sortBy)

	return (
		<div className="page alerts">
			<h1 className="text-center pb-3 pt-3">Mes alertes</h1>

			<TableInstance
				cols={columns}
				rows={rows}
				manualPagination
				page={page}
				size={size}
				query={{ value: query, setValue: onSearch }}
				rowData={alerts.data}
				setPage={onChangePage}
				setSize={onChangeSize}
				setSortBy={onChangeSortBy}
			/>
		</div>
	)
}

export default AlertsPage
