interface GlobalFilterProps {
	globalFilter: string | undefined
	setGlobalFilter: any
}

function GlobalFilter({ globalFilter, setGlobalFilter }: GlobalFilterProps) {
	return (
		<input
			value={globalFilter || ""}
			onChange={evt => setGlobalFilter(evt.target.value || undefined)}
			data-testid="filter"
			className="input-control h-10 p-2"
			placeholder="Recherche global par mot-clé"
		/>
	)
}

export default GlobalFilter
