import Market from "./Market"
import Status from "../../enums/Status"

class ToggleStatusModel {
	status: Status = Status.ACTIVATED

	id: number = 0

	askedInMarket: boolean = false

	market?: Market
}

export default ToggleStatusModel
