import { Control, Controller, FieldValues } from "react-hook-form"
import ReactSelectOption from "../../../api/models/ReactSelectOption"
import ReactSelect from "./ReactSelect"

interface ExchangeFieldProps {
	control: Control<FieldValues>
	options: ReactSelectOption[]
}

function ExchangeField({ control, options }: ExchangeFieldProps) {
	return (
		<Controller
			name="exchangePlaces"
			control={control}
			render={({ field: { value, onChange, onBlur } }: any) => (
				<ReactSelect
					className="mb-3"
					options={options}
					isMulti
					isClearable
					isSearchable
					placeholder="Sélectionner les lieux d'échanges"
					onChange={(newValues: ReactSelectOption[]) =>
						onChange(newValues?.map(option => option.value))
					}
					onBlur={onBlur}
					value={options.filter((option: any) =>
						value?.includes(option.value)
					)}
				/>
			)}
		/>
	)
}

export default ExchangeField
