interface PageWrapperProps {
	children: JSX.Element
}

export default function PageWrapper({ children }: PageWrapperProps) {
	return (
		<div className="page profile">
			<h1 className="profile-title">Mon profil</h1>
			<div className="profile-forms">{children}</div>
		</div>
	)
}
