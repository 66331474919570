import { POST_TYPES } from "../../../utils/Constants"
import { ITabProps } from "./Constants"

function PostTypeForm({ form, setSelectedIndex }: ITabProps) {
	const { watch } = form

	return (
		<div className="post-types">
			{POST_TYPES.map(p => (
				<label
					className={`card post-type p-3 hover:border-primary cursor-pointer ${
						Number(watch("type")) === p.id ? "!border-primary" : ""
					}`}
					key={p.id}
				>
					<input
						onClick={() => setSelectedIndex(1)}
						{...form.register("type", {
							required: "Le type de don doit être sélectionné",
						})}
						type="radio"
						value={p.id}
					/>

					<img src={p.Image} alt={p.label} width="200" />
					<h3 className="post-type-title">{p.label}</h3>
				</label>
			))}
		</div>
	)
}

export default PostTypeForm
