import ReactFacebookLogin from "react-facebook-login/dist/facebook-login-render-props"
import { Link as RouterLink } from "react-router-dom"

import Facebook from "../../../assets/images/Facebook.png"
import Button from "../../../components/Button/Button"
import Input from "../../../components/Form/Input/Input"
import Password from "../../../components/Form/Input/Password"
import useLoginForm from "./hooks/useLoginForm"

const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID || ""

function Login() {
	const { handleSubmit, onSubmit, register, errors, responseFacebook } =
		useLoginForm()

	return (
		<div className="page login">
			<h1 className="login-title">Se connecter</h1>
			<div className="login-methods">
				<div className="login-method">
					<form
						className="form login-method-action"
						onSubmit={handleSubmit(onSubmit)}
					>
						<Input
							label="Adresse e-mail"
							name="email"
							type="email"
							placeholder="votre.mail@exemple.fr"
							register={register}
							required
							error={errors.email}
						/>

						<Password
							label="Mot de passe"
							name="password"
							placeholder="************"
							register={register}
							required
							error={errors.password}
						/>
						<RouterLink
							to="/forgot-password"
							className="text-primary hover:underline text-sm"
						>
							Mot de passe oublié ?
						</RouterLink>

						<Button
							type="submit"
							className="bg-primary w-full mt-5 mb-2"
						>
							Se connecter
						</Button>
						<RouterLink
							to="/rules"
							className="text-primary hover:underline text-center"
						>
							Pas encore de compte ? Inscrivez-vous ici !
						</RouterLink>
					</form>
				</div>
				<div className="login-method">
					<div className="login-method-action">
						<ReactFacebookLogin
							appId={facebookAppId}
							callback={responseFacebook}
							fields="id,name,email,first_name,last_name"
							scope="public_profile,email"
							render={renderProps => (
								<button
									type="button"
									onClick={renderProps.onClick}
									className="button-facebook bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
								>
									<img src={Facebook} alt="FB" />
									<span>Se connecter avec Facebook</span>
								</button>
							)}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Login
