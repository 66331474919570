import { XIcon } from "@heroicons/react/outline"
import File from "../../../components/Form/Input/File"
import Input from "../../../components/Form/Input/Input"
import TextArea from "../../../components/Form/Input/TextArea"
import CategoryField from "../../../components/Form/Select/CategoryField"
import { ITabProps } from "./Constants"
import usePhotosInput from "./hooks/basic/usePhotosInput"
import { isDonationType } from "./utils/util"

function PostInfoForm({ form }: ITabProps) {
	const { onUploadPhoto, onDeletePhoto } = usePhotosInput(form)

	return (
		<div>
			<div className="my-5 mx-10 post-info-create">
				<Input
					name="title"
					register={form.register}
					required
					type="text"
					label="Titre de l'annonce"
					placeholder="Entrer un titre"
					error={form.formState.errors.title}
				/>
				<TextArea
					name="description"
					register={form.register}
					required
					label="Description de l'annonce"
					placeholder="Entrer une brève description"
					error={form.formState.errors.description}
				/>
				<div className="file-field">
					<File
						name="newPhoto"
						register={form.register}
						required={false}
						onChange={onUploadPhoto}
						label="Images associées à l'annonce (3 photos maximum)"
						className="p-2"
						error={form.formState.errors.image}
					/>
					<div className="preview">
						{form
							.watch("photos")
							?.map((photo: any, index: number) => (
								<div
									className="file-preview"
									key={`preview_${index}`}
								>
									<img
										src={photo.image}
										alt="preview_image"
									/>
									<XIcon
										className="bg-deletion"
										onClick={() => onDeletePhoto(index)}
									/>
								</div>
							))}
					</div>
				</div>
				<CategoryField
					getValues={form.getValues}
					setValue={form.setValue}
					register={form.register}
					category={{
						error: form.formState.errors.categoryId,
						label: "Categorie",
					}}
					subCategory={{
						error: form.formState.errors.subCategoryId,
						label: "Sous-catégorie",
					}}
					required
				/>
				{isDonationType(Number(form.watch("type"))) && (
					<div className="mt-3">
						<input
							id="askedInMarket"
							className="checkbox text-primary scale-150 ml-2"
							type="checkbox"
							{...form.register("askedInMarket")}
						/>
						<label
							htmlFor="askedInMarket"
							className="pl-4 pr-2 text-sm font-medium cursor-pointer"
						>
							Je souhaite que mon don soit sur un marché.
						</label>
					</div>
				)}
			</div>
		</div>
	)
}

export default PostInfoForm
