import { BadgeCheckIcon, UserCircleIcon } from "@heroicons/react/outline"
import ChatRoom from "../../../api/models/ChatRoom"
import Role from "../../../enums/Role"
import { hasRoles } from "../../../utils/Rights"
import DefaultImage from "../../../assets/images/Images-rafiki.svg"

interface ChatRoomListProps {
	chatRoomId: number | undefined
	chatRoomList: Array<ChatRoom>
	onChatRoomClick: (chatRoom: ChatRoom) => void
}
function ChatRoomList({
	chatRoomId,
	chatRoomList,
	onChatRoomClick,
}: ChatRoomListProps) {
	return (
		<div className="flex flex-col h-full overflow-auto  border-r-2 border-gray absolute w-full bg-white tablet:relative tablet:w-3/12">
			<div className="chat-room-list__header">
				<h3 className="text-2xl">Mes discussions</h3>
			</div>
			{chatRoomList?.map(chatRoom => {
				const isAdmin = hasRoles(
					[Role.ADMIN],
					chatRoom.recipient?.roles?.map(x => x.role)
				)
				return (
					<div
						className={`cursor-pointer pl-1 py-2 border-b-2 border-gray h-[6.5rem] overflow-y-auto overflow-x-hidden
						 ${chatRoom.id === chatRoomId ? "bg-gray text-primary" : ""}`}
						key={chatRoom.id}
						onClick={() => onChatRoomClick(chatRoom)}
					>
						<div className="flex h-full">
							<img
								className="w-20 object-cover rounded"
								src={
									chatRoom.post?.photo?.image ?? DefaultImage
								}
								alt={chatRoom.post?.title ?? "Default"}
							/>

							<div className="w-full pl-2 overflow-hidden whitespace-nowrap">
								<h4 className="overflow-hidden text-ellipsis">
									{chatRoom.post?.title}
								</h4>
								{chatRoom.post && (
									<>
										<i>Référence : </i>
										{chatRoom.post?.id}
									</>
								)}
								<div className="flex">
									{isAdmin ? (
										<BadgeCheckIcon className="w-5 mr-1  text-redAdmin" />
									) : (
										<UserCircleIcon className="w-5 min-w-5 mr-1" />
									)}
									<h5
										className={`overflow-hidden text-ellipsis ${
											isAdmin ? "text-redAdmin" : ""
										}`}
									>{`${chatRoom.recipient.firstName} ${chatRoom.recipient.lastName}`}</h5>
								</div>
								<div>
									<h6 className="italic text-xs">
										{chatRoom.post?.type.label}
									</h6>
								</div>
								{chatRoom.unReadMessageCount > 0 && (
									<span className="text-info text-center text-xs">
										{`${chatRoom.unReadMessageCount} message(s) non lu(s)`}
									</span>
								)}
							</div>
						</div>
					</div>
				)
			})}
		</div>
	)
}

export default ChatRoomList
