/**
 *
 * @param roles  accepted roles
 * @param userRoles  current user roles
 * @return true if the user has the roles, false otherwise
 */

export function hasRoles(roles: string[], userRoles: string[]): boolean {
	// Mean it's public route
	if (roles.length === 0) {
		return true
	}
	let hasRole = false
	roles.forEach(role => {
		if (userRoles.includes(role)) {
			hasRole = true
		}
	})
	return hasRole
}

export function isAuth(): boolean {
	return !!localStorage.getItem("user")
}
