import Card from "../../../components/Card/Card"
import StartChatImg from "../../../assets/images/StartChat-rafiki.svg"

export default function ReactToPost() {
	return (
		<Card className="hidden w-1/2 p-3 text-center m-auto laptop:block h-[514px]">
			<h3>Réagissez vite à son annonce !</h3>
			<img src={StartChatImg} alt="start a chat" />
		</Card>
	)
}
