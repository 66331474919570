function CGUText({ onScroll }: any) {
	return (
		<div onScroll={onScroll} className="CGU-container">
			<p>
				Les présentes conditions générales régissent l'utilisation de la
				plateforme{" "}
				<strong>
					<em>Effetdon</em>
				</strong>{" "}
				(la « Plateforme»), disponible au travers de l’application
				mobile dédiée téléchargeable sur Apple Store et sur Google Play
				(ensemble désignée l’ « Application »), ou directement sur le
				site internet à l’adresse{" "}
				<strong>
					<em>Effetdon</em>.fr
				</strong>{" "}
				(le « Site Internet »).
			</p>
			<p>
				Ces Conditions Générales sont mises à la disposition des
				Utilisateurs sur le Site Internet et au sein de l’Application.
				Tout Utilisateur déclare avoir pris connaissance et accepté les
				présentes conditions générales pour toute utilisation de{" "}
				<strong>
					<em>Effetdon</em>
				</strong>
				.
			</p>
			<h2>
				<strong>1. Définitions</strong>
			</h2>
			<p>
				“Annonceur” désigne tout Utilisateur-Donateur-Receveur qui
				propose un Article Donné, signale un Article Demandé.
			</p>
			<p>
				“Application” désigne indifféremment les applications mobiles
				Android ou Apple{" "}
				<strong>
					<em>Effetdon</em>
				</strong>
				, téléchargeables respectivement sur Google Play ou l’Apple
				Store permettant aux Utilisateurs d’accéder aux Services.
			</p>
			<p>
				“Article” désigne tout Article Donné, tout Article Demandé et/ou
				tout Article Abandonné.
			</p>
			<p>
				Les Articles peuvent être alimentaires non entamés (nourriture)
				ou non alimentaires. Un Article sera présenté au sein de la
				Plateforme au sein de la section “Objets” ou de la section
				“Nourriture” en fonction de sa nature alimentaire ou non.
			</p>
			<p>
				“Article Abandonné” désigne tout objet abandonné au sein de
				l’espace public (n’ayant pas de propriétaire manifeste) et
				signalé par un Utilisateur.
			</p>
			<p>
				“Article Demandé” désigne tout objet et/ou bien consommable
				recherché en don par un Utilisateur et faisant l’objet d’une
				demande publique au sein de la section dédiée (“Demandes”) sur
				la Plateforme.
			</p>
			<p>
				“Article Donné” désigne tout objet et/ou bien consommable que
				son propriétaire propose au don sur la Plateforme.
			</p>
			<p>
				“Plateforme” désigne indistinctement l’Application et/ou le Site
				Internet.
			</p>
			<p>
				“Service” désigne le service proposé à tout Utilisateur sur la
				Plateforme leur permettant de proposer au don, de signaler pour
				récupération un bien, de faire des demandes ou de répondre à des
				annonces.
			</p>
			<p>
				“Site Internet” désigne le site internet{" "}
				<strong>
					<em>Effetdon</em>
				</strong>
				, disponible à partir du lien URL{" "}
				<strong>
					<em>Effetdon</em>
				</strong>
				.fr, permettant aux Utilisateurs d’accéder aux Services.
			</p>
			<p>
				“Association” désigne l'Association Les Chrysalides, dont
				l'adresse est située à Dunkerque (59140) et immatriculée sous le
				numéro d’identification 894 272 939.
			</p>
			<p>
				“Utilisateur” désigne tout utilisateur, personne physique
				majeure, particulier, de la Plateforme ou des Services y compris
				tout simple visiteur.
			</p>
			<h2>
				<strong>2. Objet des conditions générales</strong>
			</h2>
			<p>
				Les présentes Conditions Générales définissent les conditions
				d’utilisation de la Plateforme et des Services par les
				Utilisateurs et les Annonceurs et régissent les relations entre
				les Utilisateurs du Service.
			</p>
			<p>
				En utilisant la Plateforme, tout Utilisateur déclare accepter
				les présentes Conditions Générales sans réserve et confirme
				expressément être une personne physique capable et non
				professionnelle.
			</p>
			<p>
				Les présentes Conditions Générales sont en conséquence
				opposables à tout Utilisateur.
			</p>
			<p>
				L'Association se réserve le droit de modifier les présentes
				Conditions Générales. Elles seront applicables dès leur mise en
				ligne.
			</p>
			<p>
				Les Utilisateurs du site sont invités à les consulter
				régulièrement afin de prendre connaissance des changements
				apportés.
			</p>
			<p>
				Les conditions générales d'utilisation doivent être acceptées
				par tout Utilisateur souhaitant accéder au site. Cette
				acceptation ne peut être que pleine et entière. Dans le cas
				contraire, l'utilisateur s'engage à ne pas utiliser ou visiter
				la Plateforme.
			</p>
			<h2>
				<strong>3. Inscription des utilisateurs</strong>
			</h2>
			<p>
				Pour utiliser le Service, afin d'être identifié sur la
				Plateforme, l'Utilisateur doit créer un compte.
			</p>
			<p>
				Tout utilisateur déclare expressément être une personne physique
				majeure en s'inscrivant sur la Plateforme.
			</p>
			<p>
				Un Utilisateur dispose de quatre méthodes pour créer un compte
				et s'identifier :
			</p>
			<ul>
				<li>
					Utiliser une adresse e-mail, utilisée comme identifiant, et
					un mot de passe.
				</li>

				<li>Utiliser son compte Facebook</li>
			</ul>
			<p>
				L'Utilisateur est seul responsable de son compte et de
				l'utilisation qu'il en fait.
			</p>
			<p>
				En s'inscrivant sur la Plateforme, l'utilisateur confirme avoir
				lu et accepté les conditions générales d'utilisation.
			</p>
			<h2>
				<strong>4. Règles d'utilisation du Service</strong>
			</h2>
			<h3>
				<strong>4.1 Publications par l'Utilisateur</strong>
			</h3>
			<p>
				Les Utilisateurs de la Plateforme sont responsables des messages
				qu'ils envoient, du contenu de leur profil, des annonces et
				photos qu'ils publient ou de toutes autres informations qu'ils
				décident de transmettre à partir de la Plateforme ou en dehors
				de la Plateforme.
				<br />
				<br />
				Tout Annonceur déclare expressément en publiant une annonce être
				le propriétaire de l’Article Donné concerné et s’engage à faire
				une description la plus fidèle possible de tout Article.
			</p>
			<p>
				En publiant une annonce, tout Utilisateur accepte expressément
				que le contenu de cette annonce soit utilisé par l'Association à
				des fins de communication et sa duplication sur tout site
				Internet exploité par l'Association.
			</p>
			<h3>
				<strong>4.2 Récupération d'un Article</strong>
			</h3>
			<p>
				Tout Utilisateur a accès à la liste des Articles, aux
				descriptifs et photos des Articles et au profil des Annonceurs.
			</p>
			<p>
				Un Utilisateur intéressé par un Article peut prendre contact
				avec l’Annonceur concerné directement par le service de
				messagerie pour lui faire connaître son intérêt pour l’Article
				Donné.
			</p>
			<p>
				L’Annonceur a accès en temps réel à la liste des Utilisateurs
				intéressés par l’Article Donné qu’il propose et peut organiser
				avec l’Utilisateur de son choix les modalités de récupération de
				l’Article Donné.
			</p>
			<h2>
				<strong>5. Responsabilité et engagements</strong>
			</h2>
			<p>
				Les Services régis par les présentes Conditions Générales sont
				ceux qui figurent sur la Plateforme. Les Articles y sont
				présentés et décrits sous la responsabilité des Annonceurs. La
				responsabilité de l'Association ne saurait être engagée pour des
				erreurs ou omissions.
			</p>
			<p>
				L'Association ne sera pas responsable de tout préjudice indirect
				que pourrait subir un Utilisateur du service dans le cadre d’une
				quelconque activité à la suite de l’utilisation ou de la
				consommation d’un Article ou encore du fait de son état.
			</p>
			<h3>
				<strong>
					5.1 Responsabilités et engagements de l'Association Les
					Chrysalides
				</strong>
			</h3>
			<p>
				L'Association est un tiers aux correspondances et relations
				entre les Annonceurs et les Utilisateurs, et exclut de ce fait
				toute responsabilité à cet égard.
			</p>
			<p>
				L'Association ne saurait en aucun cas être tenue responsable du
				contenu des annonces publiées par les Annonceurs et ne donne
				aucune garantie, expresse ou implicite, à cet égard.
			</p>
			<p>
				L'Association exerce aussi une modération a posteriori sur
				certaines des publications et se réserve le droit de stopper
				leurs mises en ligne, sans avoir à s'en justifier auprès du
				membre.
			</p>
			<p>
				En revanche, L'Association peut prendre connaissance des
				messages échangés entre Utilisateurs au travers du service de
				messagerie instantanée présent sur la Plateforme, notamment à
				des fins de prévention des fraudes, d’amélioration de ses
				services, d’assistance utilisateur, ou de vérification du
				respect par les Utilisateurs des présentes Conditions Générales.
				Par exemple, afin d’éviter qu’un Annonceur ne réclame la moindre
				somme d’argent ou contrepartie de quelque nature que ce soit,
				L'Association s’autorise à parcourir et analyser les messages
				échangés via la messagerie.
			</p>
			<p>
				Cependant, L'Association ne prend jamais connaissance de vos
				communications avec d’autres Utilisateurs à des fins
				promotionnelles ou de ciblage publicitaire.
			</p>
			<p>
				Les annonces figurant sur la Plateforme sont contrôlées
				manuellement avant d'être publiées.
			</p>
			<p>
				Toutefois, il se peut que des Utilisateurs peu scrupuleux
				essaient de proposer des dons afin d'obtenir vos coordonnées ou
				essaient de vous contacter pour réaliser une vente. Les
				Chrysalides est présente pour aider l'Utilisateur et le
				conseiller.
			</p>
			<h3>
				<strong>5.2 Engagements de l'Utilisateur</strong>
			</h3>
			<p>
				Tout Utilisateur déclare et garantit en utilisant les Services
				qu’il ne contrevient à aucune réglementation en vigueur et qu’il
				ne porte atteinte à aucun droit de tiers. En particulier, les
				Utilisateurs s’interdisent tout comportement contraire aux
				bonnes mœurs ainsi que de tenir tout propos insultant ou
				dégradant à l’égard des tiers.
			</p>
			<p>
				L'Utilisateur s'engage à ce que son compte personnel ne comporte
				aucune information obligatoire fausse et/ou mensongère, aucune
				information portant atteinte aux droits d'un tiers.
			</p>
			<p>
				Dans ce cadre, l’Utilisateur déclare et reconnaît qu’il est seul
				responsable des informations renseignées lors de la création de
				son compte personnel.
			</p>
			<p>
				L’Utilisateur reconnaît et accepte que la l’Association puisse
				supprimer, à tout moment et sans indemnité, un compte qui serait
				contraire notamment à la loi française ou aux présentes
				Conditions Générales.
			</p>
			<h3>
				<strong>5.3 Engagements de l'Annonceur</strong>
			</h3>
			<p>
				Tout Annonceur déclare et garantit en publiant toute annonce
				qu’il ne contrevient à aucune réglementation en vigueur et qu’il
				ne porte atteinte à aucun droit de tiers.
			</p>
			<p>
				En utilisant le Service, l'Utilisateur s'engage à ce que toute
				annonce qu'il publie ne contient aucune proposition relative à
				un produit illicite ou pharmaceutique, aucun lien hypertexte
				redirigeant les Utilisateurs notamment vers des sites extérieurs
				et non exploités par L'Association, aucune information fausse,
				mensongère ou de nature à induire en erreur les Utilisateurs,
				aucun contenu à caractère promotionnel ou publicitaire en lien
				avec l’activité de l’Annonceur.
			</p>
			<p>Concernant les dons alimentaires :</p>
			<p>
				L'Annonceur garantit de ne pas donner d’objet dangereux ou de
				don alimentaire entamé et dont la Date Limite de Consommation
				est dépassée. Il garantit de bonnes conditions de stockage et de
				transport. Il s’engage également à renseigner de manière exacte
				la Date Limite de Consommation de l’Article, lorsqu’elle existe,
				au sein du formulaire de création d’annonce, et à ne pas
				proposer l’Article Donné alimentaire en cas de doute sur cette
				dernière.
			</p>
			<p>
				L’Utilisateur s’engage à ne proposer sur la Plateforme que des
				Articles alimentaires dont il est garant des bonnes conditions
				de transport, stockage et de manipulation, notamment en matière
				de respect de la chaîne du froid.
			</p>
			<p>
				L’Annonceur s’engage à ne proposer dans les annonces que des
				Articles Donnés dont il dispose. L’Annonceur s’engage, en cas
				d’indisponibilité de l’Article Donné, à retirer l’annonce sans
				délai.
			</p>
			<p>
				Toute annonce qui ne serait pas postée avec l’intention réelle
				de donner l’article à un tiers, est formellement interdite et
				pourra conduire à la suspension de façon permanente ou
				temporaire du compte de l’Utilisateur concerné.
			</p>
			<p>
				Tout Annonceur s’engage expressément à ne pas réclamer la
				moindre somme d’argent ou contrepartie de quelque nature que ce
				soit en contrepartie du don ou du signalement d’un Article.
			</p>
			<p>
				En conséquence, tout Annonceur relève expressément L'Association
				de toutes responsabilités, les garantit contre tout recours ou
				action qui pourrait être intenté contre lui par tout tiers au
				titre d’une Annonce qu’il aurait publié, et prendra à sa charge
				tous les dommages et intérêts ainsi que les frais et dépens
				auxquels il pourrait être condamné ou qui seraient stipulés à
				son encontre dans un accord transactionnel, sans préjudice du
				droit de L'Association à réclamer auprès dudit Annonceur la
				réparation des dommages subis par ces derniers à ce titre.
			</p>
			<p>
				En déposant toute annonce, un Annonceur reconnaît et accepte que
				L'Association puisse la refuser ou la supprimer à tout moment,
				sans indemnité, en particulier toute annonce qui s’avèrerait
				contraire aux lois et réglementations applicables ou aux
				présentes Conditions Générales.
			</p>
			<h3>
				<strong>
					5.4 Désinscription de compte, limitation d'accès
				</strong>
			</h3>
			<p>
				L'Utilisateur peut se désinscrire du Service à tout moment. Pour
				cela, il suffit de faire une demande de suppression de compte
				par email à l'adresse contact.effetdon@gmail.com. Par conséquent
				le profil de l’Utilisateur sera définitivement supprimé.
			</p>
			<h3>
				<strong>5.5 Limite de responsabilité</strong>
			</h3>
			<p>
				L'Association s’engage à mettre en œuvre tous les moyens
				nécessaires afin d’assurer au mieux la fourniture des Services
				aux Utilisateurs et aux Annonceurs et l’accessibilité de la
				Plateforme.
			</p>
			<p>
				L'Association n'offre aucune garantie de service. Nous ne
				garantissons pas que le Service sera fonctionnel ou accessible à
				tout moment, qu'il ne comportera pas de bug, qu'il n'y aura pas
				de panne de serveur, défaillance du réseau, ralentissement,
				problème de messagerie ou tout autre problème indépendant de
				notre volonté.
			</p>
			<p>
				L'Association ne sera pas tenue responsable si le Service
				devient hors d'usage, si des messages privés sont perdus, si un
				membre quitte le site ou est supprimé pour une quelconque
				raison.
			</p>
			<p>
				Toutefois, L'Association décline toute responsabilité en cas de
				:
			</p>
			<ul>
				<li>
					Dommages directs ou indirects causés à l’Utilisateur ou
					l’Annonceur, quelle qu’en soit la nature, résultant du
					contenu des annonces et/ou de l’accès, de la gestion, de
					l’utilisation, de l’exploitation, du dysfonctionnement et/ou
					de l’interruption de la Plateforme et/ou des Services,
				</li>

				<li>
					La perte de données ou d’informations stockées par
					l'Association. Il incombe aux Annonceurs de prendre toutes
					précautions nécessaires pour conserver les annonces qu’ils
					publient sur la Plateforme,
				</li>
				<li>
					Interruptions, pannes, inaccessibilité momentanée, de
					modifications et de dysfonctionnement de la Plateforme, quel
					que soit le support de communication utilisé et ce quelles
					qu’en soient l’origine et la provenance, notamment pour des
					problèmes techniques,
				</li>
				<li>
					Attaque ou piratage informatique, privation, suppression ou
					interdiction, temporaire ou définitive, et pour quelque
					cause que ce soit, de l’accès au réseau internet.
					<br />
				</li>
				<li>
					Utilisation anormale ou d’une exploitation illicite de la
					Plateforme et des Services par tout Utilisateur ou
					Annonceur.
				</li>
			</ul>
			<p>
				La responsabilité de L'Association ne pourra être engagée que
				pour les dommages directs subis par un Utilisateur, résultant
				d’un manquement à ses obligations contractuelles telles que
				définies aux présentes.
			</p>
			<p>
				L’Utilisateur ou l’Annonceur renonce donc à demander réparation
				à L'Association à quelque titre que ce soit, de dommages
				indirects tels que le manque à gagner, la perte de chance, le
				préjudice commercial ou financier, l’augmentation de frais
				généraux ou les pertes trouvant leur origine ou étant la
				conséquence de l’exécution des présentes.
			</p>
			<p>
				Tout Utilisateur et Annonceur est alors seul responsable des
				dommages causés aux tiers et des conséquences des réclamations
				ou actions qui pourraient en découler. L’Utilisateur renonce
				également à exercer tout recours contre L'Association dans le
				cas de poursuites diligentées par un tiers à son encontre du
				fait de l’utilisation et/ou de l’exploitation illicite de la
				Plateforme ou des Services, en cas de perte par un Utilisateur
				ou un Annonceur de son mot de passe ou en cas d’usurpation de
				son identité.
			</p>
			<h2>
				<strong>6. Données personnelles</strong>
			</h2>
			<p>
				Conformément aux dispositions de la loi n° 78-17 du 6 janvier
				1978 relative à l’informatique, aux fichiers et aux libertés,
				tout Utilisateur dispose d’un droit d’accès, de modification, de
				rectification et de suppression des données qui le concerne.
			</p>
			<p>
				L'Association dispose d’une Politique de Confidentialité,
				décrivant la façon dont les données à caractère personnel des
				Utilisateurs sont traitées et protégées. Elle est disponible au
				lien suivant :{" "}
				<u>Politique de confidentialité - Données personnelles</u>.
			</p>
			<h2>
				<strong>7. Loi applicable</strong>
			</h2>
			<p>
				Les présentes Conditions Générales sont soumises au droit
				français.
			</p>
			<p>
				En cas de litige ou de réclamation et aux fins de trouver en
				priorité un accord amiable, le Client adressera ses griefs à
				L'Association par courrier recommandé avec accusé de réception.
				À défaut d’un accord amiable, tout litige ou réclamation pourra
				être porté devant la juridiction compétente.
			</p>
		</div>
	)
}

export default CGUText
