import { useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation, useQueryClient } from "react-query"
import GtuAnswer from "../../../api/models/GtuAnswer"
import GtuQuestion from "../../../api/models/GtuQuestion"
import gtuAnswerService from "../../../api/services/GtuAnswerService"
import Card from "../../../components/Card/Card"
import Checkbox from "../../../components/Form/Input/Checkbox"
import Input from "../../../components/Form/Input/Input"
import { showConfirm, showConfirmAction } from "../../../utils/Popup"
import FormAction from "../../../components/Form/Admin/FormAction"

interface AnswerFormProps {
	answer: GtuAnswer
	index: number
	questionId: number
	setAddAnswer: (value: boolean) => void
	className?: string
}

function AnswerForm({
	answer,
	index,
	setAddAnswer,
	className = "",
	questionId,
}: AnswerFormProps) {
	const {
		handleSubmit,
		register,
		formState: { errors },
	} = useForm<GtuAnswer>()
	const [checked, setChecked] = useState(answer.isCorrect)
	const [editAnswer, setEditAnswer] = useState<boolean>(index === 0)
	const queryClient = useQueryClient()

	const deleteAnswer = useMutation(
		(id: number) => gtuAnswerService.delete(id),
		{
			onSuccess: (data, variables) => {
				queryClient.setQueryData(["gtuQuestions"], (old: any) => {
					const lOld = [...old]
					lOld.forEach((question: GtuQuestion) => {
						if (question.id === questionId) {
							question.answers.forEach(
								(a: GtuAnswer, i: number) => {
									if (a.id === variables) {
										question.answers.splice(i, 1)
									}
								}
							)
						}
					})
					return lOld
				})
				showConfirm("La réponse a été supprimée", "success")
			},

			onError: () => {
				showConfirm("Erreur lors de la suppression", "error")
			},
		}
	)

	const updateAnswer = useMutation(
		(answerToUpdate: GtuAnswer) =>
			gtuAnswerService.put(answerToUpdate, answerToUpdate.id),
		{
			onSuccess: (data, variables) => {
				queryClient.setQueryData(["gtuQuestions"], (old: any) => {
					const lOld = [...old]
					lOld.forEach((question: GtuQuestion) => {
						if (question.id === questionId) {
							question.answers.forEach(
								(a: GtuAnswer, i: number) => {
									if (a.id === variables.id) {
										question.answers[i] = variables
									}
								}
							)
						}
					})
					return lOld
				})
				showConfirm("La réponse a été mise à jour", "success")
			},

			onError: () => {
				showConfirm("Erreur lors de la mise à jour", "error")
			},
		}
	)

	const createAnswer = useMutation(
		(answerToCreate: GtuAnswer) => gtuAnswerService.post(answerToCreate),
		{
			onSuccess: data => {
				queryClient.setQueryData(["gtuQuestions"], (old: any) => {
					const lOld = [...old]
					lOld.forEach((question: GtuQuestion) => {
						if (question.id === questionId) {
							question.answers.push(data)
						}
					})
					return lOld
				})
				showConfirm("La réponse a été créée", "success")
			},

			onError: () => {
				showConfirm("Erreur lors de la création", "error")
			},
		}
	)

	const onSubmit = (data: GtuAnswer) => {
		data.isCorrect = checked
		data.questionId = questionId
		if (answer.id) {
			data.id = answer.id
			updateAnswer.mutate(data)
			setEditAnswer(false)
		} else {
			createAnswer.mutate(data)
			setAddAnswer(false)
		}
	}
	return (
		<Card
			className={` mb-2 flex justify-between ml-auto mr-auto w-3/4 ${
				className ?? ""
			}`}
		>
			<form className="gtu-answer-form" onSubmit={handleSubmit(onSubmit)}>
				<b className="mr-2">➢ </b>
				{editAnswer ? (
					<div className="w-9/12 ml-1">
						<Input
							name="text"
							type="text"
							defaultValue={answer.text}
							register={register}
							required
							error={errors.text}
						/>
					</div>
				) : (
					<span className="w-9/12 truncate text-lg">
						{answer?.text}
					</span>
				)}

				<Checkbox
					className="scale-150 ml-2"
					disabled={!editAnswer}
					name="isCorrect"
					register={register}
					label="Correct"
					checked={checked ?? false}
					onChange={() => setChecked(!checked)}
				/>
				<FormAction
					setEdit={setEditAnswer}
					setAdd={setAddAnswer}
					isEditing={editAnswer}
					index={index}
					onDelete={() =>
						showConfirmAction(() => deleteAnswer.mutate(answer.id))
					}
				/>
			</form>
		</Card>
	)
}

export default AnswerForm
