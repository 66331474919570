import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import authService from "../../../api/services/AuthService"
import Button from "../../../components/Button/Button"
import Input from "../../../components/Form/Input/Input"
import { showConfirm } from "../../../utils/Popup"

function ForgotPassword() {
	const navigate = useNavigate()

	const {
		handleSubmit,
		register,
		formState: { errors },
	} = useForm<{ email: string }>()

	const onSubmit = (data: { email: string }) => {
		authService.forgotPassword(data.email).then(() => {
			navigate("/login")
			showConfirm(
				"Votre lien de réinitialisation vous a été envoyé (vérifiez vos spams)."
			)
		})
	}

	return (
		<div className="page login">
			<h1 className="login-title">Mot de passe oublié ?</h1>
			<div className="login-methods">
				<div className="login-method">
					<form
						className="form login-method-action"
						onSubmit={handleSubmit(onSubmit)}
					>
						<Input
							label="Adresse e-mail"
							name="email"
							type="email"
							placeholder="votre.mail@exemple.fr"
							register={register}
							required
							error={errors.email}
						/>
						<Button
							type="submit"
							className="bg-primary w-full mt-5 mb-2"
						>
							Réinitialiser mon mot de passe
						</Button>
					</form>
				</div>
			</div>
		</div>
	)
}

export default ForgotPassword
