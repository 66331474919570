import { Controller, useForm } from "react-hook-form"
import { useQuery } from "react-query"
import ReactSelectOption from "../../../api/models/ReactSelectOption"
import User from "../../../api/models/User"
import selfService from "../../../api/services/SelfService"
import Button from "../../../components/Button/Button"
import Card from "../../../components/Card/Card"
import LocationField from "../../../components/Form/Autocomplete/LocationField"
import Input from "../../../components/Form/Input/Input"
import CategoryField from "../../../components/Form/Select/CategoryField"
import ReactSelect from "../../../components/Form/Select/ReactSelect"
import { DEFAULT_ADDRESS, distances } from "./Constants"
import Filters from "./Filters"

interface SearchFiltersProps {
	filters: Filters
	onSearch: any
}

function SearchFilters({ filters, onSearch }: SearchFiltersProps) {
	const form = useForm<Filters>({
		defaultValues: filters,
	})

	useQuery<User>("user", () => selfService.getCurrentUser(), {
		onSuccess: (data: User) => {
			form.setValue("address", data.address)
			form.setValue("latitude", data.latitude)
			form.setValue("longitude", data.longitude)
			form.setValue("distance", "5")
			onSubmit(form.getValues())
		},
		onError: () => {
			form.setValue("address", DEFAULT_ADDRESS.address)
			form.setValue("latitude", DEFAULT_ADDRESS.latitude)
			form.setValue("longitude", DEFAULT_ADDRESS.longitude)
			form.setValue("distance", DEFAULT_ADDRESS.distance)
			onSubmit(form.getValues())
		},
	})

	const onSubmit = (data: Filters) => {
		onSearch(data)
	}

	return (
		<Card className="py-2 px-4 filters-card">
			<form onSubmit={form.handleSubmit(onSubmit)}>
				<Input
					name="query"
					register={form.register}
					required={false}
					type="text"
					error={form.formState.errors.query}
					label="Quel annonce recherchez-vous ?"
					placeholder="Rechercher une annonce..."
					defaultValue={filters.query}
				/>

				<div className="form-group pr-1">
					<LocationField
						register={form.register}
						error={form.formState.errors.address}
						setValue={form.setValue}
					/>
					<label
						className="text-sm font-medium
					"
					>
						Distance (en km)
					</label>
					<Controller
						name="distance"
						control={form.control}
						render={({
							field: { value, onChange, onBlur },
						}: any) => (
							<ReactSelect
								isSearchable
								options={distances}
								placeholder="Choisissez..."
								onBlur={onBlur}
								value={distances.find(
									(c: ReactSelectOption) => c.value === value
								)}
								onChange={(val: ReactSelectOption) =>
									onChange(val.value)
								}
							/>
						)}
					/>
				</div>

				<div className="form-group pl-1">
					<CategoryField
						getValues={form.getValues}
						setValue={form.setValue}
						register={form.register}
						required={false}
						category={{
							error: form.formState.errors.categoryId,
							label: "Categorie",
						}}
						subCategory={{
							error: form.formState.errors.subCategoryId,
							label: "Sous-catégorie",
						}}
					/>
				</div>

				<Button type="submit" className="bg-primary w-full my-4">
					Rechercher les annonces
				</Button>
			</form>
		</Card>
	)
}

export default SearchFilters
