import React from "react"

export interface Option {
	text: string
	value: string
}

interface InputProps {
	label: string
	name: string
	className?: string
	labelClassName?: string
	inputClassName?: string
	register: any
	options: Option[]
	error: any
	onChange?: any
	required?: boolean
	multiple?: boolean

	value?: any
}

function Select({
	label,
	name,
	className = "",
	labelClassName = "",
	inputClassName = "",
	register,
	options,
	error,
	onChange,
	required = false,
	multiple = false,
	value = "",
}: InputProps) {
	return (
		<div className={`input-group ${className || ""}`}>
			<label
				htmlFor={name}
				data-testid="label"
				className={`text-sm font-medium ${labelClassName || ""}`}
			>
				{error?.type !== "required" ? (
					label
				) : (
					<span className="input-error">{label} est requis</span>
				)}
			</label>
			<select
				value={value}
				{...register(name, { required })}
				name={name}
				id={name}
				data-testid="select"
				autoComplete={name}
				multiple={multiple || undefined}
				onChange={onChange}
				className={`input-control overflow-auto ${
					inputClassName || ""
				}`}
			>
				{options.map((opt, i) => (
					<option value={opt.value} key={`${opt.value}-${i}-option`}>
						{opt.text}
					</option>
				))}
			</select>
		</div>
	)
}

export default Select
