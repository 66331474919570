import Category from "../../../api/models/Category"
import Location from "../../../api/models/Location"
import SubCategory from "../../../api/models/SubCategory"
import Status from "../../../enums/Status"

class Filters extends Location {
	query: string = ""

	inMarket: boolean = false

	type: string = ""

	distance: string = ""

	categoryId: string = ""

	category!: Category

	subCategoryId: string = ""

	subCategory!: SubCategory

	userId: number = 0

	date: string = "desc"

	statuses: Status[] = [Status.ACTIVATED]
}

export default Filters
