import { ITableColumn } from "../../../utils/Constants"

export const PAGE: number = 0
export const SIZE: number = 10

export const columns: ITableColumn[] = [
	{
		accessor: "name",
		Header: "Libellé",
	},
	{
		accessor: "address",
		Header: "Adresse",
	},
	{
		accessor: "startedAt",
		Header: "Date de début",
	},
	{
		accessor: "endedAt",
		Header: "Date de fin",
	},
	{
		accessor: "category.name",
		Header: "Catégorie",
	},
	{
		accessor: "subCategory.name",
		Header: "Sous-catégorie",
	},
	{
		accessor: "action",
		Header: "Actions",
	},
]
