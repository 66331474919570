import Status from "../../enums/Status"
import Category from "./Category"
import ExchangePlace from "./ExchangePlace"
import Location from "./Location"
import Market from "./Market"
import Photo from "./Photo"
import PostType from "./PostType"
import SubCategory from "./SubCategory"
import User from "./User"

class Post extends Location {
	id: number = 0

	status: Status = Status.TO_VALIDATE

	title: string = ""

	description: string = ""

	reactivated: boolean = false

	alreadyNoted: boolean = false

	receiverId?: number

	askedInMarket: boolean = false

	type?: PostType

	market?: Market

	exchangePlaces?: ExchangePlace[]

	photos: Photo[] = []

	newPhoto?: string

	subCategory!: SubCategory

	subCategoryId: string = ""

	category!: Category

	categoryId: string = ""

	user?: User

	userId: number = 0

	createdAt: Date = new Date()

	updatedAt: Date = new Date()
}

export default Post
