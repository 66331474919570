import { Link } from "react-router-dom"
import NotFound from "../../../assets/images/NotFound-rafiki.svg"

function PageNotFound() {
	return (
		<section className="page flex flex-col w-full text-center">
			<img
				className="mx-auto mt-auto mb-0 h-3/4"
				src={NotFound}
				alt="404"
			/>
			<div className="mt-0 mx-auto mb-auto">
				<h3>Ooops! La page que vous recherchée n'existe pas.</h3>
				<p>
					Vous pouvez revenir à l'accueil en cliquant{" "}
					<Link className="text-secondary hover:underline" to="/">
						cliquant ici.
					</Link>
				</p>
			</div>
		</section>
	)
}

export default PageNotFound
