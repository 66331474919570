import { useState } from "react"
import { Control, useForm } from "react-hook-form"
import { useMutation } from "react-query"
import { useNavigate } from "react-router-dom"
import User from "../../../../api/models/User"
import authService from "../../../../api/services/AuthService"
import { showConfirm } from "../../../../utils/Popup"

interface UseRegisterFormType {
	title: string
	responseFacebook(data: any): void
	onSubmit(data: User): void
	hidePassword: boolean
	handleSubmit: any
	register: any
	errors: any
	setValue(key: string, value: string): void
	facebookUsedClassName: string
	control: Control<User, any>
}
function useRegisterForm(): UseRegisterFormType {
	const navigate = useNavigate()
	const [title, setTitle] = useState<string>("S'inscrire")
	const [facebookUsedClassName, setFacebookUsedClassName] = useState<string>(
		"register-method mb-3"
	)
	const [hidePassword, setHidePassword] = useState<boolean>(false)
	const {
		handleSubmit,
		register,
		formState: { errors },
		setValue,
		control,
	} = useForm<User>()

	const postRegister = useMutation(
		(newUser: User) => authService.register(newUser),
		{
			onSuccess: () => {
				navigate("/login")
				showConfirm(
					"Un lien d'activation vous a été envoyé sur votre adresse e-mail (vérifiez vos spams)."
				)
			},
		}
	)

	const onSubmit = (data: User) => {
		if (data.latitude && data.longitude) {
			postRegister.mutate(data)
		} else {
			showConfirm("Vous devez sélectionner une adresse.", "error")
		}
	}

	const responseFacebook = (data: any) => {
		if (data.accessToken) {
			setValue("facebookAccessToken", data.accessToken)
			setValue("email", data.email)
			setValue("firstName", data.first_name)
			setValue("lastName", data.last_name)
			setTitle("Finaliser mon inscription")
			setFacebookUsedClassName("hidden")
			setHidePassword(true)
			showConfirm(
				"Vos informations Facebook ont été pré-remplis dans le formulaire d'inscription."
			)
		} else {
			showConfirm("L'inscription avec Facebook a échoué.", "warning")
		}
	}
	return {
		title,
		responseFacebook,
		onSubmit,
		hidePassword,
		handleSubmit,
		register,
		errors,
		setValue,
		facebookUsedClassName,
		control,
	}
}

export default useRegisterForm
