import PatchUser from "../../../../api/models/PatchUser"
import User from "../../../../api/models/User"

interface UseUserFormReturn {
	onSubmit: (data: User) => void
}

export default function useUserForm(
	onSaveUser: (id: number, toUpdate: PatchUser) => void
): UseUserFormReturn {
	const onSubmit = (data: User) => {
		const patchUser = new PatchUser(
			data.activated,
			data.roles.map(role => role.role)
		)
		onSaveUser(data.id, patchUser)
	}
	return { onSubmit }
}
