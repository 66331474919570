import {
	ChatAlt2Icon,
	CheckCircleIcon,
	EyeIcon,
	PencilAltIcon,
	PencilIcon,
	TrashIcon,
} from "@heroicons/react/outline"
import dayjs from "dayjs"
import { useState } from "react"
import {
	UseMutationResult,
	UseQueryResult,
	useMutation,
	useQuery,
} from "react-query"
import { Link, useNavigate } from "react-router-dom"
import Page from "../../../../api/models/Page"
import Post from "../../../../api/models/Post"
import ToggleStatusModel from "../../../../api/models/ToggleStatusModel"
import postService from "../../../../api/services/PostService"
import Status, { parseStatus } from "../../../../enums/Status"
import { showConfirm, showConfirmAction } from "../../../../utils/Popup"

interface UsePostsAdminReturn {
	selectedPost: Post
	setSelectedPost: React.Dispatch<Post>
	posts: UseQueryResult<Page<Post>>
	rows: any[]
	setSelectedStatus: React.Dispatch<React.SetStateAction<ToggleStatusModel>>
	selectedStatus: ToggleStatusModel
	updateStatus: UseMutationResult<any, unknown, ToggleStatusModel, unknown>
}

function usePostsAdmin(
	query: string,
	size: number,
	page: number,
	sortBy: string,
	statuses: Status[]
): UsePostsAdminReturn {
	const navigate = useNavigate()
	const [rows, setRows] = useState<any[]>([])
	const [selectedPost, setSelectedPost] = useState<Post>(new Post())
	const [selectedStatus, setSelectedStatus] = useState<ToggleStatusModel>(
		new ToggleStatusModel()
	)

	const posts = useQuery<Page<Post>>(
		["posts", query, size, page, statuses, sortBy],
		() =>
			postService.getAllPaginated({
				page,
				size,
				query: query || undefined,
				sort: sortBy || undefined,
				askedInMarket: true,
				statuses: statuses.length > 0 ? statuses.join(",") : undefined,
			}),
		{
			retry: false,
			keepPreviousData: false,
			onSuccess: (data: Page<Post>) => {
				setRows(
					data.content.map((p: Post) => ({
						...p,

						highlighting: p.reactivated,
						askedInMarket: p.market ? (
							<Link
								to={`/admins/markets/${p.market.id}/posts`}
								className="text-info hover:underline"
							>
								{p.market.name}
							</Link>
						) : p.askedInMarket ? (
							"A valider"
						) : (
							"Non"
						),
						status: p.reactivated
							? "Réactivé"
							: parseStatus(p.status),
						createdAt: dayjs(p.createdAt).format(
							"DD MMMM YYYY HH:mm"
						),
						actions: (
							<div className="cell-action">
								<span title="Consulter">
									<EyeIcon
										className="w-6 transition duration-200 text-secondary mr-2 cursor-pointer hover:scale-125"
										onClick={() =>
											window.open(
												`/post/view/${p.id}`,
												"_blank"
											)
										}
									/>
								</span>

								{p.status !== Status.ARCHIVED ? (
									<>
										{p.user ? (
											<span title="Envoyer un message">
												<ChatAlt2Icon
													className="w-6 transition duration-200 text-primary mr-2 cursor-pointer hover:scale-125"
													onClick={() =>
														navigate(
															`/start-chat/${
																p.user!.id
															}/post/${p.id}`
														)
													}
												/>
											</span>
										) : (
											""
										)}

										{p.status === Status.TO_VALIDATE ? (
											<span title="Valider l'annonce">
												<CheckCircleIcon
													className="w-6 transition duration-200 cursor-pointer text-green mr-2 hover:scale-125"
													onClick={() =>
														toggleStatus(p)
													}
												/>
											</span>
										) : (
											<span title="Mettre à jour le statut">
												<PencilAltIcon
													className="w-6 transition duration-200 cursor-pointer text-primary mr-2 hover:scale-125"
													onClick={() =>
														toggleStatus(p)
													}
												/>
											</span>
										)}

										<span title="Editer">
											<PencilIcon
												className="w-6 transition duration-200 cursor-pointer text-primary mr-2 hover:scale-125"
												onClick={() =>
													navigate(
														`/post/edit/${p.id}`
													)
												}
											/>
										</span>

										<span title="Supprimer l'annonce">
											<TrashIcon
												className="w-6 transition duration-200 cursor-pointer text-redCustom mr-2 hover:scale-125"
												onClick={() =>
													showConfirmRemoval(p)
												}
											/>
										</span>
									</>
								) : (
									""
								)}
							</div>
						),
					}))
				)
			},
		}
	)

	const updateStatus = useMutation(
		(data: ToggleStatusModel) => postService.toggleStatus(data),
		{
			onSuccess: (_data, variables) => {
				posts.refetch()
				if (variables.askedInMarket) {
					showConfirm(
						variables.status === Status.ACTIVATED
							? `L'annonce est désormais disponible sur le marché "${variables.market?.name}".`
							: `L'annonce n'est plus disponible sur le marché "${variables.market?.name}".`
					)
				} else {
					showConfirm(
						variables.status === Status.ACTIVATED
							? "L'annonce est désormais accessible sur le site."
							: "L'annonce n'est désormais plus accessible sur le site."
					)
				}
				setSelectedStatus(new ToggleStatusModel())
				setSelectedPost(new Post())
			},
		}
	)

	const deletePost = useMutation(
		(data: Post) => postService.delete(data.id),
		{
			onSuccess: (_data, variables) => {
				posts.refetch()
				showConfirm(
					`L'annonce n°${variables.id} "${variables.title}" a été définitivement supprimée.`
				)
			},
		}
	)

	const toggleStatus = (data: Post) => {
		if (data.status === Status.TO_VALIDATE) {
			if (data.askedInMarket) {
				setSelectedStatus({ ...data })
			} else {
				showConfirmAction(
					() => {
						updateStatus.mutate({
							id: data.id,
							status: Status.ACTIVATED,
							askedInMarket: false,
						})
					},
					"Voulez-vous valider la publication de cette annonce ?",
					"Valider"
				)
			}
		} else {
			setSelectedPost(data)
		}
	}

	const showConfirmRemoval = (post: Post) => {
		showConfirmAction(() => {
			deletePost.mutate(post)
		})
	}

	return {
		selectedPost,
		setSelectedPost,
		rows,
		posts,
		updateStatus,
		selectedStatus,
		setSelectedStatus,
	}
}

export default usePostsAdmin
