import { LockClosedIcon } from "@heroicons/react/outline"
import { UseFormReturn } from "react-hook-form"
import UpdatePassword from "../../../api/models/UpdatePassword"
import Button from "../../../components/Button/Button"
import Password from "../../../components/Form/Input/Password"

interface ChangePasswordFormProps {
	passwordForm: UseFormReturn<UpdatePassword>
	onPasswordSubmit: (updatePassword: UpdatePassword) => void
}

export default function ChangePasswordForm({
	passwordForm,
	onPasswordSubmit,
}: ChangePasswordFormProps) {
	return (
		<>
			<h2 className="profile-section flex flex-row items-center mt-6">
				<LockClosedIcon className="w-8 h-8 mr-2" /> Mon mot de passe
			</h2>
			<form
				className="profile-card"
				onSubmit={passwordForm.handleSubmit(onPasswordSubmit)}
			>
				<div className="form-group-three">
					<Password
						label="Mot de passe actuel"
						name="oldPassword"
						placeholder="Votre mot de passe actuel"
						register={passwordForm.register}
						required
						error={passwordForm.formState.errors.oldPassword}
					/>

					<Password
						label="Nouveau mot de passe"
						name="newPassword"
						placeholder="Votre nouveau mot de passe"
						register={passwordForm.register}
						required
						error={passwordForm.formState.errors.newPassword}
					/>

					<Password
						label="Confirmation du mot de passe"
						name="confirmPassword"
						placeholder="Confirmation de votre mot de passe"
						register={passwordForm.register}
						required
						error={passwordForm.formState.errors.confirmPassword}
					/>
				</div>

				<Button type="submit" className="bg-primary w-full mt-5 mb-2">
					Changer mon mot de passe
				</Button>
			</form>
		</>
	)
}
