class PatchUser {
	activated: boolean

	roles: String[]

	constructor(activated: boolean, roles: String[]) {
		this.activated = activated
		this.roles = roles
	}
}

export default PatchUser
