import {
	LogoutIcon,
	UserIcon,
	ArchiveIcon,
	ChatAlt2Icon,
	BellIcon,
} from "@heroicons/react/outline"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"

import selfService from "../../api/services/SelfService"
import UserContext from "../../contexts/UserContext"
import { showConfirm } from "../../utils/Popup"

interface UserPopoverProps {
	unReadMessagesCount: number
	setUnReadMessagesCount: (count: number) => void
}
function UserPopover({
	unReadMessagesCount,
	setUnReadMessagesCount,
}: UserPopoverProps) {
	const navigate = useNavigate()

	const { setUser } = useContext(UserContext)

	const logout = () => {
		localStorage.removeItem("user")
		selfService.setRoles([])
		setUser(null)
		navigate("/login")
		showConfirm("Vous êtes à présent déconnecté de la plateforme.")
	}

	const profile = () => {
		navigate("/self/profile")
	}

	const messages = () => {
		setUnReadMessagesCount(0)
		navigate("/messages")
	}

	const posts = () => {
		navigate("/self/posts")
	}

	const alerts = () => {
		navigate("/self/alerts")
	}

	return (
		<div className="popover" data-testid="popover">
			<div
				className="popover-item popover-item--profile"
				onClick={() => profile()}
			>
				<UserIcon className="w-6 h-6 mb-2 mr-2" />
				Mon profil
			</div>
			<div className="popover-item" onClick={() => posts()}>
				<ArchiveIcon className="w-6 h-6 mb-2 mr-2" />
				Mes annonces
			</div>
			<div className="popover-item" onClick={() => alerts()}>
				<BellIcon className="w-6 h-6 mb-2 mr-2" />
				Mes alertes
			</div>
			<div className="popover-item" onClick={() => messages()}>
				<ChatAlt2Icon className="w-6 h-6 mb-2 mr-2" />
				Mes messages
				{unReadMessagesCount > 0 && (
					<span className="pl-1"> ({unReadMessagesCount}) </span>
				)}
			</div>
			<span />
			<div
				className="popover-item popover-item--logout"
				onClick={() => logout()}
			>
				<LogoutIcon className="w-6 h-6 mr-2" />
				Me déconnecter
			</div>
		</div>
	)
}

export default UserPopover
