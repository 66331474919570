import { Controller, UseFormReturn } from "react-hook-form"
import ReactSelect, { SingleValue } from "react-select"
import ReactSelectOption from "../../../api/models/ReactSelectOption"
import Button from "../../../components/Button/Button"
import NotationField from "../../../components/Form/Notation/NotationField"
import SimpleUser from "../../../api/models/SimpleUser"
import StatusModel from "../../../api/models/StatusModel"

interface StatusFormProps {
	onSubmit: (data: any) => void
	form: UseFormReturn<StatusModel>
	options: ReactSelectOption[]
	users: SimpleUser[]
	setRating: (rating: number) => void
}

function StatusForm({
	onSubmit,
	form,
	options,
	users,
	setRating,
}: StatusFormProps) {
	return (
		<form onSubmit={form.handleSubmit(onSubmit)}>
			<h3 className="my-4 text-center">
				Voulez-vous désactiver cette annonce ?
			</h3>
			<p className="my-2 text-center">
				Une fois désactivé, l'annonce sera invisible par tous les{" "}
				utilisateurs.
			</p>
			<Controller
				name="userId"
				control={form.control}
				render={({ field: { value, onChange, onBlur } }: any) => (
					<ReactSelect
						className="h-9"
						isSearchable
						options={options}
						placeholder="Choisissez..."
						onBlur={onBlur}
						value={options.find(
							(c: ReactSelectOption) => c.value === value
						)}
						onChange={(val: SingleValue<ReactSelectOption>) =>
							onChange(val?.value)
						}
					/>
				)}
			/>

			{form.watch("userId") && form.watch("userId") !== "0" ? (
				<div className="my-2">
					<h4>
						Notez votre échange avec{" "}
						{
							users.find(x => x.id === +form.watch("userId"))
								?.firstName
						}{" "}
						:
					</h4>
					<NotationField setNote={setRating} />
				</div>
			) : (
				""
			)}
			<Button type="submit" className="my-4 bg-primary w-full">
				Désactiver l'annonce
			</Button>
		</form>
	)
}

export default StatusForm
