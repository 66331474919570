class News {
	id: number

	html: string

	constructor(id: number, html: string) {
		this.id = id
		this.html = html
	}
}

export default News
