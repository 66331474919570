import { useForm } from "react-hook-form"
import { useMutation, useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"

import authService from "../../../api/services/AuthService"
import Button from "../../../components/Button/Button"
import Password from "../../../components/Form/Input/Password"
import { showConfirm } from "../../../utils/Popup"

function ResetPassword() {
	const navigate = useNavigate()

	const { token } = useParams()

	const {
		handleSubmit,
		register,
		formState: { errors },
	} = useForm<{ password: string; confirmation: string }>()

	const getUserId = useQuery(
		[],
		() => authService.hasResetPasswordToken(token?.toString()),
		{
			retry: false,
		}
	)

	const postResetPassword = useMutation(
		(password: string) =>
			authService.resetPassword(password, Number(getUserId.data)),
		{
			onSuccess: () => {
				navigate("/login")
				showConfirm(
					"Votre mot de passe a été changé avec succès, vous pouvez désormais vous connecter."
				)
			},
		}
	)

	const onSubmit = (data: { password: string; confirmation: string }) => {
		if (data.password === data.confirmation) {
			postResetPassword.mutate(data.password)
		} else {
			showConfirm(
				"Le mot de passe et sa confirmation doivent être identiques.",
				"error"
			)
		}
	}

	return (
		<div className="page login">
			<h1 className="login-title">Réinitialiser mon mot de passe</h1>
			<div className="login-methods">
				<div className="login-method ">
					<h3
						className={`text-center text-info ${
							!getUserId.error ? "hidden" : ""
						}`}
					>
						Réinitialisation impossible, veuillez réessayer.
					</h3>
					<form
						className={
							Number(getUserId.data) > 0
								? "form login-method-action"
								: "hidden"
						}
						onSubmit={handleSubmit(onSubmit)}
					>
						<Password
							label="Mot de passe"
							name="password"
							placeholder="**************"
							register={register}
							required
							error={errors.password}
						/>
						<Password
							label="Confirmation"
							name="confirmation"
							placeholder="**************"
							register={register}
							required
							error={errors.confirmation}
						/>
						<Button
							type="submit"
							className="bg-primary w-full mt-5 mb-2"
						>
							Réinitialiser mon mot de passe
						</Button>
					</form>
				</div>
			</div>
		</div>
	)
}

export default ResetPassword
