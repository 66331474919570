import { useQuery, UseQueryResult } from "react-query"
import { useParams } from "react-router-dom"
import Post from "../../../../../api/models/PostWithoutUser"
import postService from "../../../../../api/services/PostService"

interface UseGetPostQueryType {
	postQueryResult: UseQueryResult<Post>
}

function useGetPostQuery(): UseGetPostQueryType {
	const params = useParams()
	const postQueryResult = useQuery<Post>(
		["post", params.id],
		() => postService.getById(Number(params.id)),
		{
			enabled: params.id !== undefined,
		}
	)

	return { postQueryResult }
}

export default useGetPostQuery
