import {
	ArchiveIcon,
	ChatAlt2Icon,
	LogoutIcon,
	UserCircleIcon,
	UserIcon,
} from "@heroicons/react/outline"
import { useContext, useEffect } from "react"
import { Link, NavLink, useNavigate } from "react-router-dom"
import Logo from "../../../assets/images/Chrysalides.png"

import selfService from "../../../api/services/SelfService"
import UserContext from "../../../contexts/UserContext"
import { showConfirm } from "../../../utils/Popup"
import Button from "../../Button/Button"

interface SidebarProps {
	openMenu: boolean
	links: any[]
	refSidebar: any
	inNavigator?: boolean
	unReadMessagesCount: number
	setOpenSidebar: (value: boolean) => void
}

function Sidebar({
	openMenu,
	links,
	refSidebar,
	inNavigator = true,
	unReadMessagesCount = 0,
	setOpenSidebar,
}: SidebarProps) {
	const navigate = useNavigate()

	const { user, setUser } = useContext(UserContext)

	const logout = () => {
		localStorage.removeItem("user")
		selfService.setRoles([])
		setUser(null)
		setOpenSidebar(false)
		navigate("/login")
		showConfirm("Vous êtes à présent déconnecté de la plateforme.")
	}

	useEffect(() => {
		if (inNavigator) {
			if (openMenu) {
				document.getElementById("overlay")!.style.display = "unset"
			} else {
				document.getElementById("overlay")!.style.display = "none"
			}
		}
	}, [openMenu])

	const ifLogged = user ? (
		<>
			<NavLink to="/self/profile" onClick={() => setOpenSidebar(false)}>
				<UserIcon className="w-6 h-6 mr-1" /> Mon profil
			</NavLink>
			<NavLink to="/self/posts" onClick={() => setOpenSidebar(false)}>
				<ArchiveIcon className="w-6 h-6 mr-1" /> Mes annonces
			</NavLink>
			<NavLink
				to="/messages"
				onClick={() => setOpenSidebar(false)}
				className="chat-message-link"
			>
				<ChatAlt2Icon className="w-6 h-6 mr-1" /> Mes messages
				{unReadMessagesCount > 0 && (
					<span className="chat-message-link__notification">
						{unReadMessagesCount}
					</span>
				)}
			</NavLink>
			<a className="lougout-link" onClick={() => logout()}>
				<LogoutIcon className="w-6 h-6 mr-1" /> Me déconnecter
			</a>
		</>
	) : (
		<NavLink to="/login" onClick={() => setOpenSidebar(false)}>
			<UserCircleIcon className="w-6 h-6 mr-1" /> Connexion
		</NavLink>
	)

	return (
		<div
			ref={refSidebar}
			data-testid="sidebar"
			className={`sidebar ${
				openMenu ? "sidebar--active" : "sidebar--closed"
			}`}
		>
			<header className="sidebar-header">
				<Link to="/">
					<img
						src={Logo}
						alt="Logo Les Chrysalides"
						className="header-logo"
					/>
				</Link>
			</header>
			<div className="sidebar-links">
				{links}
				{ifLogged}
				<Button
					type="button"
					className="bg-primary w-48 ml-6 mt-4"
					onClick={() => {
						if (user === null) {
							showConfirm(
								"Veuillez vous connecter pour pouvoir créer une annonce.",
								"info"
							)
						}
						navigate(user ? "/post/new" : "/login")
					}}
				>
					Créer une annonce
				</Button>
			</div>
		</div>
	)
}

export default Sidebar
