/**
 * Used to extend all other services.
 * It supplies basic functions of CRUD
 */
import axios, { AxiosInstance } from "axios"

import { showConfirm } from "../../utils/Popup"
import AuthResponse from "../models/AuthResponse"
import Page from "../models/Page"

export class GeneralService<T> {
	// Variables
	protected url: string

	protected http: AxiosInstance

	protected user: AuthResponse = new AuthResponse()

	protected spinnerIsActivated: boolean = true

	static requestCounter: number = 0

	constructor(endPoint: string) {
		this.http = axios.create()
		this.url = `${process.env.REACT_APP_API_URL}/${endPoint}`
		// Intercept every requests
		this.http.interceptors.request.use(
			config => {
				const newConfig = config
				// Show loading spinner
				if (this.spinnerIsActivated) {
					document.getElementById("overlay")!.style.display = "unset"
					document.getElementById("overlay-spin")!.style.display =
						"unset"
					GeneralService.requestCounter += 1
				}

				// Add jwtToken to every request
				const storageItem = localStorage.getItem("user")
				if (storageItem !== null) {
					this.user = JSON.parse(storageItem)
				} else {
					this.user = new AuthResponse()
				}

				if (this.user && this.user.jwtToken) {
					newConfig!.headers!.Authorization = `Bearer ${this.user.jwtToken}`
				}

				return newConfig
			},
			error => Promise.reject(error)
		)
		// Intercept every responses
		this.http.interceptors.response.use(
			response => {
				if (this.spinnerIsActivated) {
					// Remove loading spinner
					GeneralService.requestCounter -= 1
					if (GeneralService.requestCounter <= 0) {
						document.getElementById("overlay")!.style.display =
							"none"
						document.getElementById("overlay-spin")!.style.display =
							"none"
					}
				}

				return response
			},
			err =>
				new Promise(() => {
					if (
						err.response?.status === 401 &&
						!!localStorage.getItem("user")
					) {
						localStorage.removeItem("user")
						window.location.replace("/login")
					}
					if (this.spinnerIsActivated) {
						// Remove loading spinner
						GeneralService.requestCounter -= 1
						if (GeneralService.requestCounter <= 0)
							document.getElementById("overlay")!.style.display =
								"none"
					}
					throw err
				})
		)
	}

	getById(id: any): Promise<T> {
		return this.http
			.get<T>(`${this.url}/${id}`)
			.then(this.handleResponse)
			.catch(this.handleError)
	}

	get(url: string): Promise<T> {
		return this.http
			.get<T>(`${this.url}${url}`)
			.then(this.handleResponse)
			.catch(this.handleError)
	}

	getAllWithFilters(filters?: Object, url?: string): Promise<T[]> {
		// ajout des filtres

		return this.http
			.get<T[]>(this.url + (url ?? ""), { params: filters })
			.then(this.handleResponse)
			.catch(this.handleError)
	}

	getAllPaginated(filters?: Object, url?: string): Promise<Page<T>> {
		return this.http
			.get<T[]>(`${this.url + (url ?? "")}`, { params: filters })
			.then((res: any) => res.data)
			.catch(this.handleError)
	}

	post(entity: T | null, specificUrl: string = ""): Promise<T> {
		return this.http
			.post<T>(this.url + specificUrl, entity)
			.then(this.handleResponse)
			.catch(this.handleError)
	}

	put(entity: T, id: number | string): Promise<T> {
		return this.http
			.put<T>(`${this.url}/${id}`, entity)
			.then(this.handleResponse)
			.catch(this.handleError)
	}

	patch(entity: T, id: number | string): Promise<T> {
		return this.http
			.patch<T>(`${this.url}/${id}`, entity)
			.then(this.handleResponse)
			.catch(this.handleError)
	}

	delete(id: number | string): Promise<T> {
		return this.http
			.delete<T>(`${this.url}/${id}`)
			.then(this.handleResponse)
			.catch(this.handleError)
	}

	getUrl(): string {
		return this.url
	}

	handleResponse(response: any): any {
		if (response.data) {
			if (response.data?.content) {
				return response.data.content
			}
			return response.data
		}
		return response
	}

	handleError(error: any): void {
		if (error.response?.data?.message) {
			showConfirm(error.response.data?.message, "error")
		}
		throw error.response
	}
}

export default GeneralService
