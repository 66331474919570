import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import { FORMATS, MODULES } from "./Constants"

interface CustomEditorProps {
	value: string
	setValue: (newValue: string) => void
}

function CustomEditor({ value, setValue }: CustomEditorProps) {
	return (
		<ReactQuill
			theme="snow"
			value={value}
			onChange={setValue}
			formats={FORMATS}
			modules={MODULES}
		/>
	)
}

export default CustomEditor
