import Modal from "../../../components/Dialog/Modal"
import TableInstance from "../../../components/Table/Instance"
import usePaginationSearchSortOnTable from "../../../hooks/usePaginationSearchSortOnTable"
import { columns } from "./Constants"
import MarketForm from "./MarketForm"
import useMarketsAdmin from "./hooks/useMarketAdmin"

function AdminMarkets() {
	const {
		query,
		page,
		size,
		onChangeSize,
		onSearch,
		onChangePage,
		sortBy,
		onChangeSortBy,
	} = usePaginationSearchSortOnTable()

	const {
		rows,
		markets,
		openMarketDialog,
		market,
		closeMarketDialog,
		onSubmit,
		dialog,
	} = useMarketsAdmin(query, size, page, sortBy)

	return (
		<div className="page admin-markets">
			<h1 className="text-center pb-3 pt-3">Les marchés</h1>

			<TableInstance
				cols={columns}
				rows={rows}
				rowData={markets.data}
				openFormDialog={openMarketDialog}
				query={{ value: query, setValue: onSearch }}
				manualPagination
				page={page}
				size={size}
				setPage={onChangePage}
				setSize={onChangeSize}
				setSortBy={onChangeSortBy}
			/>

			<Modal
				title={market.id ? "Editer un marché" : "Créer un marché"}
				isOpen={dialog}
				onClose={closeMarketDialog}
			>
				<MarketForm market={market} onSubmit={onSubmit} />
			</Modal>
		</div>
	)
}

export default AdminMarkets
