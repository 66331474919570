import { Option } from "../../../components/Form/Select/Select"
import Status from "../../../enums/Status"
import { ITableColumn } from "../../../utils/Constants"

export const PAGE: number = 0
export const SIZE: number = 10

export const columns: ITableColumn[] = [
	{
		Header: "Référence",
		accessor: "id",
	},
	{
		Header: "Titre",
		accessor: "title",
	},
	{
		Header: "Etat",
		accessor: "status",
	},
	{
		Header: "Type",
		accessor: "type.label",
	},
	{
		Header: "Auteur",
		accessor: "user.firstName",
	},
	{
		Header: "Marché",
		accessor: "askedInMarket",
	},
	{
		Header: "Créé le",
		accessor: "createdAt",
	},
	{
		Header: "Actions",
		accessor: "actions",
	},
]

export const statusOptions: Option[] = [
	{
		text: "Activé",
		value: Status.ACTIVATED,
	},
	{
		text: "Désactivé",
		value: Status.DEACTIVATED,
	},
	{
		text: "Archivé",
		value: Status.ARCHIVED,
	},
]
