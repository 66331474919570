import Select from "react-select"
import CssVariables from "../../../assets/scss/base.module.scss"

function ReactSelect(props: any) {
	const styles = {
		control: (base: any) => ({
			...base,
			transition: "all 0.3s",
			"&:hover": {
				borderColor: CssVariables.colorPrimary,
			}, // border style on hover
			border: `1px solid ${CssVariables.colorGray}`, // default border color
			boxShadow: "none", // no box-shadow
			background: CssVariables.colorBackground,
			cursor: "pointer",
		}),
		multiValue: (base: any) => ({
			...base,
			backgroundColor: CssVariables.colorSecondary,
		}),
	}
	return <Select autoComplete="off" styles={styles} {...props} />
}

export default ReactSelect
