import { Navigate, useLocation } from "react-router-dom"
import { ReactElement, useEffect, useState } from "react"
import { isAuth, hasRoles } from "../utils/Rights"
import selfService from "../api/services/SelfService"

interface ProtectedRouteProps {
	children: ReactElement<any, any>
	roles?: string[]
}

function ProtectedRoute({
	children,
	roles,
}: ProtectedRouteProps): ReactElement<any, any> | null {
	const [userRoles, setUserRoles] = useState<null | string[]>(null)
	const location = useLocation()

	useEffect(() => {
		selfService.getUserRoles().then(res => setUserRoles(res))
	}, [])

	if (!isAuth()) {
		return <Navigate to="/login" state={{ from: location }} />
	}

	if (userRoles != null) {
		if (!hasRoles(roles || [], userRoles)) {
			return <Navigate to="/403" />
		}
		return children
	}
	return null
}

export default ProtectedRoute
