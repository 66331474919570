interface ButtonProps {
	type: string
	children: any
	className?: string
	onClick?: () => void
	disabled?: boolean
	style?: any
}

function Button({
	type = "submit",
	children,
	className,
	disabled = false,
	style = {},
	onClick,
}: ButtonProps) {
	return (
		<button
			onClick={onClick}
			type={type === "submit" ? "submit" : "button"}
			className={`button ${className}`}
			data-testid="button"
			style={style}
			disabled={disabled}
		>
			{children}
		</button>
	)
}

export default Button
