import Post from "../../../../api/models/Post"
import Modal from "../../../../components/Dialog/Modal"
import TableInstance from "../../../../components/Table/Instance"
import Status from "../../../../enums/Status"
import useMultipleStatuses from "../../../../hooks/useMultipleStatuses"
import usePaginationSearchSortOnTable from "../../../../hooks/usePaginationSearchSortOnTable"
import MultipleStatusSelect from "../../Posts/MultipleStatusSelect"
import UpdateStatusForm from "../../Posts/UpdateStatusForm"
import { columns } from "./Constants"
import useMarketPostsAdmin from "./hooks/useMarketPostsAdmin"

function MarketsPostsAdminPage() {
	const {
		query,
		page,
		size,
		onChangeSize,
		onSearch,
		onChangePage,
		sortBy,
		onChangeSortBy,
	} = usePaginationSearchSortOnTable()

	const { statuses, onChangeStatuses } = useMultipleStatuses([
		Status.TO_VALIDATE,
		Status.ACTIVATED,
		Status.DEACTIVATED,
	])

	const {
		rows,
		selectedPost,
		setSelectedPost,
		market,
		updateStatus,

		posts,
	} = useMarketPostsAdmin(query, size, page, sortBy, statuses)
	return (
		<div className="page admin-posts">
			<h1 className="text-center pb-3 pt-3">
				Les annonces du marché : {market.data?.name}
			</h1>

			<Modal
				isOpen={selectedPost.id > 0}
				onClose={() => setSelectedPost(new Post())}
				title="Modifier le statut"
			>
				<UpdateStatusForm
					post={selectedPost}
					setPost={setSelectedPost}
					updateStatus={updateStatus.mutate}
				/>
			</Modal>

			{rows ? (
				<TableInstance
					Filter={
						<MultipleStatusSelect
							statuses={statuses}
							OnChangeStatuses={onChangeStatuses}
						/>
					}
					cols={columns}
					rows={rows}
					manualPagination
					page={page}
					size={size}
					rowData={posts.data}
					setPage={onChangePage}
					setSize={onChangeSize}
					setSortBy={onChangeSortBy}
					query={{ value: query, setValue: onSearch }}
				/>
			) : (
				""
			)}
		</div>
	)
}

export default MarketsPostsAdminPage
