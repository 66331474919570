import LocationField from "../../../components/Form/Autocomplete/LocationField"
import ExchangeField from "../../../components/Form/Select/ExchangeField"
import { ITabProps } from "./Constants"

function PostLocationForm({ form, exchangePlaceOptions }: ITabProps) {
	function display(exchangePlaces: number[] | null): boolean {
		return exchangePlaces === undefined || exchangePlaces?.length === 0
	}

	return (
		<div className="my-5 mx-10">
			<ExchangeField
				control={form.control}
				options={exchangePlaceOptions}
			/>
			{display(form.watch("exchangePlaces")) && (
				<LocationField
					register={form.register}
					error={form.formState.errors.address}
					setValue={form.setValue}
					required
				/>
			)}
		</div>
	)
}

export default PostLocationForm
