import { useQuery } from "react-query"
import { Link } from "react-router-dom"
import News from "../../api/models/News"
import newsService from "../../api/services/NewsService"
import { PRIMARY_ACTIONS, newsId } from "./Constants"

function Home() {
	const news = useQuery<News>("news", () => newsService.getById(newsId))

	return (
		<div className="page home">
			<div className="pt-5 pb-5 text-center w-3/4 mx-auto">
				<h1>Bienvenue sur Effetdon, site basé uniquement sur le don</h1>
				<p className="pt-1">
					Conçu par l'Association Les Chrysalides pour devenir un
					réseau éco-responsable et solidaire. Donner une seconde vie
					à vos objets, accessoires, outils et éviter le gaspillage{" "}
				</p>
			</div>
			<div className="home-actions">
				{PRIMARY_ACTIONS.map(act => (
					<Link key={act.url} to={act.url} className="home-action">
						<span>{act.title}</span>

						<p dangerouslySetInnerHTML={{ __html: act.text }} />
						<img src={act.imgUrl} alt={act.title} />
					</Link>
				))}
			</div>

			{news.data && (
				<>
					<h2 className="text-center pb-5 pt-5">
						Actualités Les Chrysalides
					</h2>
					<div className="home-news">
						<p
							dangerouslySetInnerHTML={{
								__html: news.data.html || "",
							}}
						/>
					</div>
				</>
			)}
		</div>
	)
}

export default Home
