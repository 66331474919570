import { UserCircleIcon } from "@heroicons/react/outline"
import StartChatUser from "../../../api/models/StartChatUser"
import Card from "../../../components/Card/Card"
import ShowUserNotation from "../../../components/UserNotation/ShowUserNotation"

interface UserHeaderInfoProps {
	startChatUserInfoData: StartChatUser
}

export default function UserHeaderInfo({
	startChatUserInfoData,
}: UserHeaderInfoProps) {
	return (
		<Card className="p-3 mb-2 ">
			<div className="flex  items-center mb-2">
				<UserCircleIcon className="w-10 mr-1" />
				{startChatUserInfoData && (
					<>
						<h3>
							{`${startChatUserInfoData.firstName} ${startChatUserInfoData.lastName}`}
						</h3>

						{startChatUserInfoData.note &&
							startChatUserInfoData.noteCount && (
								<ShowUserNotation
									className="ml-2"
									noteCount={startChatUserInfoData.noteCount}
									note={startChatUserInfoData.note}
								/>
							)}
					</>
				)}
			</div>
			<span className="text-info  pl-2">
				{`${startChatUserInfoData?.numberOfActivePost} annonce(s) en
    ligne`}
			</span>
		</Card>
	)
}
