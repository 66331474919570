import {
	ChatAlt2Icon,
	DownloadIcon,
	PencilIcon,
	TrashIcon,
} from "@heroicons/react/outline"
import dayjs from "dayjs"
import { useContext, useState } from "react"
import { useMutation, useQuery } from "react-query"
import { useNavigate } from "react-router-dom"
import PatchUser from "../../../../api/models/PatchUser"
import User from "../../../../api/models/User"
import userService from "../../../../api/services/UserService"
import UserContext from "../../../../contexts/UserContext"
import { showConfirm, showConfirmAction } from "../../../../utils/Popup"

interface UseUsersAdminReturn {
	openUserDialog: (item?: User) => void
	users: any
	rows: any[]
	selectedUser: User
	isUserDialogOpen: boolean
	closeUserDialog: () => void
	saveItem: (id: number, toUpdate: PatchUser) => void
}

export default function useUsersAdmin(
	query: string,
	size: number,
	page: number,
	sortBy: string
): UseUsersAdminReturn {
	const navigate = useNavigate()
	const { user } = useContext(UserContext)
	const [isUserDialogOpen, setIsUserDialogOpen] = useState<boolean>(false)

	const [rows, setRows] = useState<any[]>([])
	const [selectedUser, setSelectedUser] = useState<User>(new User())

	const users: any = useQuery(
		["users", query, page, size, sortBy],
		() =>
			userService.getAllPaginated({
				query: query || undefined,
				page,
				size,
				sort: sortBy || undefined,
			}),
		{
			retry: false,
			keepPreviousData: false,
			onSuccess: (data: any) => {
				setRows(
					data.content.map((x: User) => ({
						...x,
						createdAt: dayjs(x.createdAt).format(
							"DD MMMM YYYY HH:mm"
						),
						action: (
							<div className="cell-action">
								<span title="Exporter">
									<DownloadIcon
										className="w-6 transition duration-200 text-primary mr-2 cursor-pointer hover:scale-125"
										onClick={() => exportItem(x)}
									/>
								</span>
								<span title="Editer">
									<PencilIcon
										className="w-6 transition duration-200 text-primary mr-2 cursor-pointer hover:scale-125"
										onClick={() => {
											openUserDialog(x)
										}}
									/>
								</span>
								{user.id !== x.id && (
									<span title="Envoyer un message">
										<ChatAlt2Icon
											className="w-6 transition duration-200 text-secondary mr-2 cursor-pointer hover:scale-125"
											onClick={() =>
												// 0 means no post linked
												navigate(
													`/start-chat/${x.id}/post/0`
												)
											}
										/>
									</span>
								)}
								<span title="Supprimer">
									<TrashIcon
										className="w-6 transition duration-200 text-redCustom  mr-2 cursor-pointer hover:scale-125"
										onClick={() => {
											showConfirmRemoval(x)
										}}
									/>
								</span>
							</div>
						),
					}))
				)
			},
		}
	)

	const patchUser = useMutation(
		({ id, toUpdate }: { id: number; toUpdate: PatchUser }) =>
			userService.patch(toUpdate, id),
		{
			onSuccess: () => {
				showConfirm("L'utilisateur a bien été modifié.")
				users.refetch()
			},
			onSettled: () => {
				closeUserDialog()
			},
		}
	)

	const deleteUser = useMutation(
		(toDelete: User) => userService.delete(toDelete.id),
		{
			onSuccess: () => {
				showConfirm("L'utilisateur a bien été supprimé.")
				users.refetch()
			},
		}
	)

	const openUserDialog = (item: User = new User()) => {
		setSelectedUser(item)
		setIsUserDialogOpen(true)
	}

	const closeUserDialog = () => {
		setIsUserDialogOpen(false)
		setSelectedUser(new User())
	}

	const showConfirmRemoval = (item: User) => {
		showConfirmAction(() => {
			removeItem(item)
		})
	}

	const exportItem = (item: User) => {
		const data = `data:application/json;charset=utf-8,${encodeURIComponent(
			JSON.stringify(item)
		)}`
		const link = document.createElement("a")
		link.setAttribute("href", data)
		link.setAttribute("download", `export_${item.email}_${Date.now()}.json`)
		link.click()
		link.remove()
	}

	const saveItem = (id: number, toUpdate: PatchUser) => {
		patchUser.mutate({ id, toUpdate })
	}

	const removeItem = (item: User) => {
		deleteUser.mutate(item)
	}
	return {
		openUserDialog,
		users,
		rows,
		selectedUser,
		isUserDialogOpen,
		closeUserDialog,
		saveItem,
	}
}
