import { useContext, useEffect } from "react"
import { UseQueryResult, useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import StartChatUser from "../../../../api/models/StartChatUser"
import userService from "../../../../api/services/UserService"
import UserContext from "../../../../contexts/UserContext"
import { showConfirm } from "../../../../utils/Popup"

interface UseStartChatQueryReturn {
	startChatUserInfo: UseQueryResult<StartChatUser, unknown>
}

export default function useStartChatQuery(): UseStartChatQueryReturn {
	const { user } = useContext(UserContext)

	const { recipientId } = useParams()
	const navigate = useNavigate()

	useEffect(() => {
		if (recipientId === user.id.toString()) {
			showConfirm("Pourquoi tu veux t'auto-contacter?", "error", 2500)
			navigate("/")
		}
	}, [])

	const startChatUserInfo = useQuery<StartChatUser>(
		"startChatUser",
		() => userService.getUserStartingChatInfo(recipientId ?? ""),
		{ enabled: recipientId !== undefined }
	)

	return { startChatUserInfo }
}
