import { useContext, useEffect } from "react"
import UserContext from "../contexts/UserContext"

function useUserIsConnected(action: any) {
	const { user } = useContext(UserContext)

	useEffect(() => {
		// if user is connected
		if (user) {
			action && action()
		}
	}, [user])
}

export default useUserIsConnected
