import { useState } from "react"
import { UseFormReturn, useForm } from "react-hook-form"
import { useQuery } from "react-query"
import ReactSelectOption from "../../../../api/models/ReactSelectOption"
import SimpleUser from "../../../../api/models/SimpleUser"
import StatusModel from "../../../../api/models/StatusModel"
import selfService from "../../../../api/services/SelfService"

interface UseStatusFormReturn {
	onSubmit: (data: any) => void
	form: UseFormReturn<StatusModel>
	getUserOptions: () => ReactSelectOption[]
	users: SimpleUser[]
	setRating: (rating: number) => void
}

export default function useStatusForm(
	saveNotation: (data: StatusModel) => void,
	selectedPostId: number
): UseStatusFormReturn {
	const [users, setUsers] = useState<SimpleUser[]>([])
	const form = useForm<StatusModel>()
	useQuery<SimpleUser[]>(
		"rooms",
		() => selfService.getPotentialRecipients(selectedPostId),
		{
			onSuccess: (data: SimpleUser[]) => {
				setUsers(data)
			},
			enabled: selectedPostId !== 0,
		}
	)
	const getUserOptions = (): ReactSelectOption[] => {
		const userOptions = users.map(
			x =>
				new ReactSelectOption(
					x.id.toString(),
					`${x.firstName} ${x.lastName}`
				)
		)
		return [
			new ReactSelectOption("0", "Echange non effectué"),
			...userOptions,
		]
	}

	const setRating = (rating: number) => {
		form.setValue("note", rating)
	}

	const onSubmit = (data: any) => {
		saveNotation({
			...data,
			user: users.find(x => x.id === +form.watch("userId")),
		})
	}

	return { onSubmit, setRating, getUserOptions, users, form }
}
