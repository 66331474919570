import { useEffect } from "react"
import { useMutation } from "react-query"
import { useNavigate, useParams } from "react-router-dom"

import authService from "../../../api/services/AuthService"
import { showConfirm } from "../../../utils/Popup"

function ConfirmMail() {
	const navigate = useNavigate()

	const { token } = useParams()

	const postConfirm = useMutation(
		() => authService.confirmRegistration(token?.toString()),
		{
			onSuccess: () => {
				navigate("/login")
				showConfirm(
					"Votre compte a été activé, vous pouvez désormais vous connecter."
				)
			},
		}
	)

	useEffect(() => {
		postConfirm.mutate()
	}, [])

	return <div />
}

export default ConfirmMail
