import { ChevronDownIcon } from "@heroicons/react/outline"
import { useState } from "react"
import { Collapse } from "react-collapse"
import { useForm } from "react-hook-form"
import { useMutation, useQueryClient } from "react-query"

import Category from "../../../api/models/Category"
import SubCategory from "../../../api/models/SubCategory"
import CategoryService from "../../../api/services/CategoryService"
import Button from "../../../components/Button/Button"
import Card from "../../../components/Card/Card"
import Input from "../../../components/Form/Input/Input"
import { showConfirm, showConfirmAction } from "../../../utils/Popup"
import FormAction from "../../../components/Form/Admin/FormAction"
import SubCategoryForm from "./SubCategoryForm"

interface CategoryFormProps {
	category: Category
	index: number
	setAddCategory: (value: boolean) => void
	className?: string
}

function CategoryForm({
	category,
	index,
	setAddCategory,
	className,
}: CategoryFormProps) {
	const {
		handleSubmit,
		register,
		formState: { errors },
	} = useForm<Category>()

	const [openCategory, setOpenCategory] = useState<boolean>(false)
	const [editCategory, setEditCategory] = useState<boolean>(index === 0)
	const [addSubCategory, setAddSubCategory] = useState<boolean>(index === 0)
	const queryClient = useQueryClient()

	const deleteCategory = useMutation(
		(id: number) => CategoryService.delete(id),
		{
			onSuccess: (data, variables) => {
				queryClient.setQueryData(["categories"], (old: any) => {
					const lOld = [...old]
					lOld.forEach((cat: Category, i: number) => {
						if (cat.id === variables) {
							lOld.splice(i, 1)
						}
					})
					return lOld
				})
				showConfirm("La catégorie a été supprimée", "success")
			},

			onError: () => {
				showConfirm("Erreur lors de la suppression", "error")
			},
		}
	)

	const updateCategory = useMutation(
		(categoryToUpdate: Category) =>
			CategoryService.put(categoryToUpdate, categoryToUpdate.id),
		{
			onSuccess: (data, variables) => {
				queryClient.setQueryData(["categories"], (old: any) => {
					const lOld = [...old]
					lOld.forEach((c: Category, i: number) => {
						if (c.id === variables.id) {
							lOld[i].name = variables.name
						}
					})
					return lOld
				})
				showConfirm("La catégorie a été mise à jour", "success")
			},

			onError: () => {
				showConfirm("Erreur lors de la mise à jour", "error")
			},
		}
	)

	const createCategory = useMutation(
		(categoryToCreate: Category) => CategoryService.post(categoryToCreate),
		{
			onSuccess: data => {
				queryClient.setQueryData(["categories"], (old: any) => {
					const lOld = [...old]
					lOld.push(data)
					return lOld
				})
				showConfirm("La catégorie a été créée", "success")
			},

			onError: () => {
				showConfirm("Erreur lors de la création", "error")
			},
		}
	)

	const onSubmit = (data: Category) => {
		if (category.id) {
			data.id = category.id
			updateCategory.mutate(data)
			setEditCategory(false)
		} else {
			createCategory.mutate(data)
			setAddCategory(false)
		}
	}

	return (
		<Card className={`w-5/6 m-auto mt-3 ${className ?? ""}`}>
			<form className="category-form" onSubmit={handleSubmit(onSubmit)}>
				<h3 className="">
					<b>{index}.</b>
				</h3>
				{editCategory ? (
					<div className="w-5/6 ml-1">
						<Input
							name="name"
							type="text"
							defaultValue={category.name}
							register={register}
							required
							error={errors.name}
						/>
					</div>
				) : (
					<h3 className="w-5/6 truncate ">{category.name}</h3>
				)}
				<FormAction
					setEdit={setEditCategory}
					setAdd={setAddCategory}
					isEditing={editCategory}
					canBeDeleted={
						category.numbersOfPosts <= 0 &&
						category.subCategories.length <= 0
					}
					index={index}
					onDelete={() =>
						showConfirmAction(() =>
							deleteCategory.mutate(category.id)
						)
					}
				/>
				<ChevronDownIcon
					onClick={() => setOpenCategory(!openCategory)}
					className={`w-5 h5   cursor-pointer ${
						openCategory ? "rotate-180" : ""
					} `}
				/>
			</form>
			<Collapse isOpened={openCategory}>
				{category?.subCategories?.map((a, i) => (
					<SubCategoryForm
						categoryId={category.id}
						subCategory={a}
						key={a.id}
						index={i + 1}
						setAddSubCategory={setAddCategory}
					/>
				))}
				{category?.subCategories?.length === 0 && (
					<h4 className="text-center text-info p-2">
						Pas encore de sous-catégorie, veuillez en rajouter une !
					</h4>
				)}

				{/* Add a dynamic subCategory Form */}
				{addSubCategory && (
					<SubCategoryForm
						className="!border-primary"
						subCategory={new SubCategory()}
						// index === 0, means that we add a subCategory at the end of the list
						index={0}
						setAddSubCategory={setAddSubCategory}
						categoryId={category.id}
					/>
				)}
				<div className="pb-2 pt-2">
					<Button
						type="button"
						className="bg-secondary text-black w-3/4 m-auto block"
						onClick={() => setAddSubCategory(true)}
						disabled={addSubCategory}
					>
						Ajouter une sous-catégorie
					</Button>
				</div>
			</Collapse>
		</Card>
	)
}

export default CategoryForm
