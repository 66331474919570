import { SpeakerphoneIcon, XIcon } from "@heroicons/react/outline"
import { StarIcon } from "@heroicons/react/solid"
import dayjs from "dayjs"
import { UseFormReturn } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import ChatMessage from "../../../api/models/ChatMessage"
import ChatRoom from "../../../api/models/ChatRoom"
import Page from "../../../api/models/Page"
import Button from "../../../components/Button/Button"
import ChatMessageForm from "./ChatMessageForm"

interface MessageChatProps {
	messagePage?: Page<ChatMessage>[]
	onScroll: (e: any) => void
	messageForm: UseFormReturn<ChatMessage, any>
	onMessageSubmit: (chatMessage: ChatMessage) => void
	selectedChatRoom: ChatRoom
	ReportModalForm: JSX.Element
	openReportModal: () => void
	isAdmin: boolean
}
function MessageChat({
	selectedChatRoom,
	openReportModal,
	messagePage,
	onScroll,
	messageForm,
	onMessageSubmit,
	ReportModalForm,
	isAdmin,
}: MessageChatProps) {
	const navigate = useNavigate()

	return (
		<section className="h-full overflow-hidden w-full z-10 bg-white tablet:w-9/12">
			<header className="chat-message-header">
				<div className="w-5/6 overflow-hidden whitespace-nowrap pr-1">
					<div
						className="overflow-hidden text-ellipsis text-base font-bold cursor-pointer text-secondary bold hover:underline"
						onClick={() =>
							navigate(`/post/view/${selectedChatRoom.post?.id}`)
						}
					>
						{selectedChatRoom.post?.title}
					</div>

					<h5>
						{selectedChatRoom.recipient.firstName}{" "}
						{selectedChatRoom.recipient.lastName}
					</h5>

					{selectedChatRoom.recipient.note &&
						selectedChatRoom.recipient.noteCount && (
							<div className="ml-2 flex flex-row">
								<StarIcon className="w-6 fill-yellowStar" />
								<p className="font-bold">
									{Math.round(
										selectedChatRoom.recipient.note * 10
									) / 10}
									/5 ({selectedChatRoom.recipient.noteCount}{" "}
									avis)
								</p>
							</div>
						)}
				</div>
				{!isAdmin && (
					<Button
						type="button"
						className="w-1/6 my-auto chat-message-report bg-redCustom font-bold ml-auto flex flex-row justify-center"
						onClick={() => openReportModal()}
					>
						<>
							<SpeakerphoneIcon className="w-6 mr-2" />
							Signaler
						</>
					</Button>
				)}
				<XIcon
					className="w-6 transition duration-200 cursor-pointer hover:scale-125 ml-1 block tablet:hidden"
					onClick={() => navigate("/messages")}
				/>
				{ReportModalForm}
			</header>

			<div onScroll={onScroll} className="chat-message-content">
				{messagePage?.map((page: Page<ChatMessage>) =>
					page?.content?.map(message => (
						<div
							className={` flex flex-col max-w-[80%] ${
								message.recipientId !==
								selectedChatRoom.recipient.id
									? ""
									: "ml-auto "
							}`}
							key={message.id}
						>
							<span
								className={`w-fit rounded-lg p-2 break-all text-white ${
									message.recipientId !==
									selectedChatRoom.recipient.id
										? "bg-secondary mr-auto"
										: "bg-primary  ml-auto"
								}`}
							>
								{message.message}
							</span>
							<span
								className={`text-grayDark text-xs pt-1 pb-2 pr-1	${
									message.recipientId !==
									selectedChatRoom.recipient.id
										? "mr-auto"
										: "ml-auto"
								}`}
							>
								{dayjs(message.createdAt).format(
									"DD MMMM YYYY HH:mm"
								)}
							</span>
						</div>
					))
				)}
			</div>
			<ChatMessageForm
				handleSubmit={onMessageSubmit}
				form={messageForm}
			/>
		</section>
	)
}

export default MessageChat
