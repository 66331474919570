import { useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation, useQueryClient } from "react-query"
import Category from "../../../api/models/Category"
import SubCategory from "../../../api/models/SubCategory"
import SubCategoryService from "../../../api/services/SubCategoryService"
import Card from "../../../components/Card/Card"
import Input from "../../../components/Form/Input/Input"
import { showConfirm, showConfirmAction } from "../../../utils/Popup"
import FormAction from "../../../components/Form/Admin/FormAction"

interface SubCategoryFormProps {
	subCategory: SubCategory
	index: number
	categoryId: number
	setAddSubCategory: (value: boolean) => void
	className?: string
}

function SubCategoryForm({
	subCategory,
	index,
	setAddSubCategory,
	className = "",
	categoryId,
}: SubCategoryFormProps) {
	const {
		handleSubmit,
		register,
		formState: { errors },
	} = useForm<SubCategory>()

	const [editSubCategory, setEditSubCategory] = useState<boolean>(index === 0)
	const queryClient = useQueryClient()

	const deleteSubCategory = useMutation(
		(id: number) => SubCategoryService.delete(id),
		{
			onSuccess: (data, variables) => {
				queryClient.setQueryData(["categories"], (old: any) => {
					const lOld = [...old]
					lOld.forEach((category: Category) => {
						if (category.id === categoryId) {
							category.subCategories.forEach(
								(a: SubCategory, i: number) => {
									if (a.id === variables) {
										category.subCategories.splice(i, 1)
									}
								}
							)
						}
					})
					return lOld
				})
				showConfirm("La sous-catégorie a été supprimée", "success")
			},

			onError: () => {
				showConfirm("Erreur lors de la suppression", "error")
			},
		}
	)

	const updateSubCategory = useMutation(
		(subCategoryToUpdate: SubCategory) =>
			SubCategoryService.put(subCategoryToUpdate, subCategoryToUpdate.id),
		{
			onSuccess: (data, variables) => {
				queryClient.setQueryData(["categories"], (old: any) => {
					const lOld = [...old]
					lOld.forEach((category: Category) => {
						if (category.id === categoryId) {
							category.subCategories.forEach(
								(a: SubCategory, i: number) => {
									if (a.id === variables.id) {
										category.subCategories[i] = variables
									}
								}
							)
						}
					})
					return lOld
				})
				showConfirm("La sous-catégorie a été mise à jour", "success")
			},

			onError: () => {
				showConfirm("Erreur lors de la mise à jour", "error")
			},
		}
	)

	const createSubCategory = useMutation(
		(subCategoryToCreate: SubCategory) =>
			SubCategoryService.post(subCategoryToCreate),
		{
			onSuccess: data => {
				queryClient.setQueryData(["categories"], (old: any) => {
					const lOld = [...old]
					lOld.forEach((category: Category) => {
						if (category.id === categoryId) {
							category.subCategories.push(data)
						}
					})
					return lOld
				})
				showConfirm("La sous-catégorie a été créée", "success")
			},

			onError: () => {
				showConfirm("Erreur lors de la création", "error")
			},
		}
	)

	const onSubmit = (data: SubCategory) => {
		data.categoryId = categoryId
		if (subCategory.id) {
			data.id = subCategory.id
			updateSubCategory.mutate(data)
			setEditSubCategory(false)
		} else {
			createSubCategory.mutate(data)
			setAddSubCategory(false)
		}
	}
	return (
		<Card
			className={` mb-2 flex justify-between ml-auto mr-auto w-3/4 ${
				className ?? ""
			}`}
		>
			<form
				className="sub-category-form"
				onSubmit={handleSubmit(onSubmit)}
			>
				<b className="mr-2">➢ </b>
				{editSubCategory ? (
					<div className="w-9/12 ml-1">
						<Input
							name="name"
							type="text"
							defaultValue={subCategory.name}
							register={register}
							required
							error={errors.name}
						/>
					</div>
				) : (
					<span className="w-9/12 truncate text-lg">
						{subCategory?.name}
					</span>
				)}

				<FormAction
					setEdit={setEditSubCategory}
					setAdd={setAddSubCategory}
					isEditing={editSubCategory}
					canBeDeleted={subCategory.numbersOfPosts <= 0}
					index={index}
					onDelete={() =>
						showConfirmAction(() =>
							deleteSubCategory.mutate(subCategory.id)
						)
					}
				/>
			</form>
		</Card>
	)
}

export default SubCategoryForm
