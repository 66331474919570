import { UserIcon } from "@heroicons/react/solid"
import { Controller, useForm } from "react-hook-form"
import ReactSelectOption from "../../../api/models/ReactSelectOption"
import User from "../../../api/models/User"
import Button from "../../../components/Button/Button"
import LocationField from "../../../components/Form/Autocomplete/LocationField"
import Input from "../../../components/Form/Input/Input"
import ReactSelect from "../../../components/Form/Select/ReactSelect"
import { GENDER } from "../../../utils/Constants"

interface ProfileFormProps {
	user: User
	onProfilSubmit: (user: User) => void
}

export default function ProfileForm({
	user,
	onProfilSubmit,
}: ProfileFormProps) {
	const userForm = useForm<User>({ defaultValues: user })
	return (
		<>
			<h2 className="profile-section flex flex-row items-center">
				<UserIcon className="w-8 h-8 mr-2" /> Mes informations
			</h2>
			<form
				className="profile-card"
				onSubmit={userForm.handleSubmit(onProfilSubmit)}
			>
				<div className="form-group-three">
					<div>
						<label
							className="text-sm font-medium
"
						>
							Civilité
						</label>
						<Controller
							name="gender"
							control={userForm.control}
							render={({
								field: { value, onChange, onBlur },
							}: any) => (
								<ReactSelect
									isSearchable
									options={GENDER}
									placeholder="Choisissez..."
									onBlur={onBlur}
									value={GENDER.find(
										(c: ReactSelectOption) =>
											c.value === value
									)}
									onChange={(val: ReactSelectOption) =>
										onChange(val.value)
									}
								/>
							)}
						/>
					</div>

					<Input
						label="Prénom"
						name="firstName"
						placeholder="Votre prénom"
						register={userForm.register}
						type="text"
						required
						error={userForm.formState.errors.firstName}
					/>

					<Input
						label="Nom de famille"
						name="lastName"
						placeholder="Votre nom de famille"
						register={userForm.register}
						type="text"
						required
						error={userForm.formState.errors.lastName}
					/>
				</div>

				<div className="form-group-three">
					<Input
						label="Adresse e-mail"
						name="email"
						placeholder="Votre adresse e-mail"
						register={userForm.register}
						type="email"
						required
						error={userForm.formState.errors.email}
					/>

					<Input
						label="N° de téléphone"
						name="phone"
						placeholder="Votre n° de téléphone"
						register={userForm.register}
						type="tel"
						required
						error={userForm.formState.errors.phone}
					/>

					<div className="relative">
						<LocationField
							register={userForm.register}
							error={userForm.formState.errors.address}
							setValue={userForm.setValue}
							required
						/>
					</div>
				</div>

				<Button type="submit" className="bg-primary w-full mt-5 mb-2">
					Sauvegarder mes informations
				</Button>
			</form>
		</>
	)
}
