import ReactToPost from "./ReactToPost"
import StartChatForm from "./StartChatForm"
import UserHeaderInfo from "./UserHeaderInfo"
import useStartChatForm from "./hooks/useStartChatForm"
import useStartChatQuery from "./hooks/useStartChatQuery"

function StartChat() {
	const { startChatUserInfo } = useStartChatQuery()
	const { onMessageSubmit, messageForm } = useStartChatForm(
		startChatUserInfo.data?.firstName
	)

	return (
		<section className="page start-chat-page !py-5">
			<div className="flex flex-col w-full mr-0 m-auto h-[514px] laptop:mr-4">
				{startChatUserInfo.isSuccess && (
					<>
						<UserHeaderInfo
							startChatUserInfoData={startChatUserInfo.data}
						/>
						<StartChatForm
							messageForm={messageForm}
							startChatUserInfoData={startChatUserInfo.data}
							onMessageSubmit={onMessageSubmit}
						/>
					</>
				)}
			</div>
			<ReactToPost />
		</section>
	)
}

export default StartChat
