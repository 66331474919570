import RoleModel from "./RoleModel"

class SimpleUser {
	id: number = 0

	firstName: string = ""

	lastName: string = ""

	gender: string = ""

	note?: number

	noteCount?: number

	roles!: RoleModel[]
}

export default SimpleUser
