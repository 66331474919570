import { NavLink } from "react-router-dom"

interface LinkProps {
	url: string
	text: string
	Icon: any
	onClick?: any
	className?: string
}

function Link({ url, text, Icon, onClick, className }: LinkProps) {
	return (
		<NavLink
			to={url}
			onClick={onClick}
			className={className ?? ""}
			data-testid="link"
		>
			{Icon ? (
				<>
					<Icon className="w-5 h-5 mr-1" />
					<span>{text}</span>
				</>
			) : (
				<span>{text}</span>
			)}
		</NavLink>
	)
}

export default Link
