import { ArchiveIcon, PencilIcon, TrashIcon } from "@heroicons/react/outline"
import dayjs from "dayjs"
import { useState } from "react"
import { UseQueryResult, useMutation, useQuery } from "react-query"
import { useNavigate } from "react-router-dom"
import Market from "../../../../api/models/Market"
import Page from "../../../../api/models/Page"
import marketService from "../../../../api/services/MarketService"
import { showConfirm, showConfirmAction } from "../../../../utils/Popup"

interface UseMarketsAdminReturn {
	rows: any[]
	markets: UseQueryResult<Page<Market>>
	openMarketDialog: (data?: Market) => void
	market: Market
	closeMarketDialog: () => void
	dialog: boolean
	onSubmit: (item: Market) => void
}

function useMarketsAdmin(
	query: string,
	size: number,
	page: number,
	sortBy: string
): UseMarketsAdminReturn {
	const navigate = useNavigate()

	const [dialog, setDialog] = useState<boolean>(false)
	const [market, setMarket] = useState<Market>(new Market())

	const [rows, setRows] = useState<any[]>([])

	const markets = useQuery<Page<Market>>(
		["markets", page, size, query, sortBy],
		() =>
			marketService.getAllPaginated({
				page,
				size,
				query: query || undefined,
				sort: sortBy || undefined,
			}),
		{
			onSuccess: (data: Page<Market>) => {
				setRows(
					data.content.map((m: Market) => ({
						...m,
						startedAt: dayjs(m.startedAt).format(
							"DD/MM/YYYY HH:mm"
						),
						endedAt: dayjs(m.endedAt).format("DD/MM/YYYY HH:mm"),
						action: (
							<div className="cell-action">
								<span title="Editer">
									<PencilIcon
										className="w-6 transition duration-200 text-primary mr-2 cursor-pointer hover:scale-125"
										onClick={() => {
											openMarketDialog(m)
										}}
									/>
								</span>

								<span title="Consulter les annonces liés">
									<ArchiveIcon
										className={`w-6 transition duration-200 text-secondary mr-2 cursor-pointer hover:scale-125 ${
											m.numberOfPosts <= 0
												? "disabled"
												: ""
										}`}
										onClick={
											m.numberOfPosts === 0
												? undefined
												: () => showMarketPosts(m)
										}
									/>
								</span>

								<span title="Supprimer">
									<TrashIcon
										className={`w-6 transition duration-200 text-redCustom  mr-2 cursor-pointer hover:scale-125 ${
											m.numberOfPosts <= 0
												? ""
												: "disabled"
										}`}
										onClick={
											m.numberOfPosts === 0
												? () => showConfirmRemoval(m)
												: undefined
										}
									/>
								</span>
							</div>
						),
					}))
				)
			},
		}
	)

	const createMarket = useMutation(
		(data: Market) => marketService.post(data),
		{
			onSuccess: (_data, variables) => {
				showConfirm(`La marché ${variables.name} a bien été créé.`)
				closeMarketDialog()
				markets.refetch()
			},
		}
	)

	const updateMarket = useMutation(
		(data: Market) => marketService.put(data, data.id),
		{
			onSuccess: (_data, variables) => {
				showConfirm(
					`La marché ${variables.name} a bien été sauvegardé.`
				)
				closeMarketDialog()
				markets.refetch()
			},
		}
	)

	const deleteMarket = useMutation(
		(data: Market) => marketService.delete(data.id),
		{
			onSuccess: (_data, variables) => {
				showConfirm(`La marché ${variables.name} a bien été supprimé.`)
				markets.refetch()
			},
		}
	)

	const openMarketDialog = (data: Market = new Market()) => {
		setDialog(true)
		setMarket(data)
	}

	const showMarketPosts = (data: Market) => {
		navigate(`/admins/markets/${data.id}/posts`)
	}

	const closeMarketDialog = () => {
		setDialog(false)
		setMarket(new Market())
	}

	const saveItem = (data: Market) => {
		if (data.id) {
			updateMarket.mutate(data)
		} else {
			createMarket.mutate(data)
		}
	}

	const onSubmit = (data: Market) => {
		if (data.latitude && data.longitude) {
			saveItem(data)
		} else {
			showConfirm("Vous devez sélectionner une adresse.", "error")
		}
	}

	const showConfirmRemoval = (data: Market) => {
		showConfirmAction(() => {
			deleteMarket.mutate(data)
		})
	}

	return {
		rows,
		markets,
		openMarketDialog,
		market,
		closeMarketDialog,
		onSubmit,
		dialog,
	}
}

export default useMarketsAdmin
