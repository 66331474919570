import { useParams } from "react-router-dom"
import Card from "../../../components/Card/Card"
import ReportForm from "../../../components/Dialog/Form/ReportForm"
import Modal from "../../../components/Dialog/Modal"
import useGetUserInfo from "../../../hooks/useGetUserInfo"
import useReportForm from "../../../hooks/useReportForm"
import ChatRoomList from "./ChatRoomList"
import MessageChat from "./MessageChat"
import useChatMessage from "./hooks/useChatMessage"
import useChatRoom from "./hooks/useChatRoom"
import NoContactsInfo from "./NoContactsInfo"
import NoContactSelectionInfo from "./NoContactSelectionInfo"
import { hasRoles } from "../../../utils/Rights"
import Role from "../../../enums/Role"

function Message() {
	const { chatRoomId } = useParams()
	const { chatRoomsApi, selectedChatRoom, onChatRoomClick } = useChatRoom(
		chatRoomId || ""
	)
	const {
		onMessageSubmit,
		messageForm,
		handleMessageScroll,
		chatMessageApi,
	} = useChatMessage(selectedChatRoom)

	const { user } = useGetUserInfo()
	const {
		postReport,
		report,
		setReport,
		openReportModal,
		reporting,
		closeReportModal,
	} = useReportForm(selectedChatRoom?.post, user.data)
	const isAdmin = hasRoles(
		[Role.ADMIN],
		selectedChatRoom !== null
			? selectedChatRoom.recipient.roles?.map(x => x.role)
			: []
	)

	return (
		<section className="page overflow-hidden !py-5">
			<Card
				className={`chat-container ${
					isAdmin ? "!border-2 !border-redAdmin rounded" : ""
				}`}
			>
				{chatRoomsApi.isSuccess && chatRoomsApi.data.length > 0 ? (
					<>
						<ChatRoomList
							chatRoomId={chatRoomId ? +chatRoomId : undefined}
							chatRoomList={chatRoomsApi.data ?? []}
							onChatRoomClick={onChatRoomClick}
						/>
						{selectedChatRoom ? (
							<MessageChat
								isAdmin={isAdmin}
								openReportModal={openReportModal}
								ReportModalForm={
									<Modal
										title="Signaler un utilisateur"
										isOpen={reporting}
										onClose={() => closeReportModal()}
									>
										<ReportForm
											report={report}
											setReport={setReport}
											onSave={postReport.mutate}
										/>
									</Modal>
								}
								selectedChatRoom={selectedChatRoom}
								onScroll={e => handleMessageScroll(e)}
								messagePage={chatMessageApi.data?.pages}
								messageForm={messageForm}
								onMessageSubmit={onMessageSubmit}
							/>
						) : (
							<NoContactSelectionInfo />
						)}
					</>
				) : (
					<NoContactsInfo />
				)}
			</Card>
		</section>
	)
}

export default Message
