import React from "react"

interface InputProps {
	label?: string
	name: string
	type: string
	register: any
	required: boolean
	placeholder?: string
	defaultValue?: string
	error?: any
	className?: string
	disabled?: boolean
	children?: JSX.Element
}

function Input({
	label,
	name,
	type = "text",
	register,
	required = false,
	placeholder = "",
	error,
	defaultValue,
	className,
	disabled = false,
	children,
}: InputProps) {
	return (
		<div className="input-group relative">
			<label
				htmlFor={name}
				data-testid="label"
				className="text-sm font-medium"
			>
				{error?.type !== "required" ? (
					label
				) : (
					<span className="input-error">
						{label ?? "Ce champ"} est requis
					</span>
				)}
			</label>
			<input
				{...register(name, { required })}
				type={type}
				name={name}
				data-testid="input"
				defaultValue={defaultValue}
				id={name}
				placeholder={placeholder}
				autoComplete={name}
				disabled={disabled || false}
				className={`input-control ${className ?? ""} ${
					error ? "!border-redCustom" : ""
				}`}
			/>
			{children}
		</div>
	)
}

export default Input
