import { useContext, useEffect, useState } from "react"
import { useMutation, useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import dayjs from "dayjs"
import {
	ChevronLeftIcon,
	ChevronRightIcon,
	BellIcon,
} from "@heroicons/react/solid"
import Post from "../../../api/models/Post"
import postService from "../../../api/services/PostService"
import DefaultImage from "../../../assets/images/Images-rafiki.svg"
import Card from "../../../components/Card/Card"
import Select, { Option } from "../../../components/Form/Select/Select"
import { POST_TYPES } from "../../../utils/Constants"
import formatPostFilters from "../../../utils/formatPostFilters"
import { PAGE, SIZE } from "./Constants"
import Filters from "./Filters"
import SearchFilters from "./SearchFilters"
import Button from "../../../components/Button/Button"
import Modal from "../../../components/Dialog/Modal"
import AlertForm from "./AlertForm"
import Alert from "../../../api/models/Alert"
import SimpleUser from "../../../api/models/SimpleUser"
import UserContext from "../../../contexts/UserContext"
import User from "../../../api/models/User"
import selfService from "../../../api/services/SelfService"
import alertService from "../../../api/services/AlertService"
import { showConfirm } from "../../../utils/Popup"

function SearchPage() {
	const navigate = useNavigate()
	const params = useParams()
	const { user } = useContext(UserContext)
	const [alertDialog, setAlertDialog] = useState<boolean>(false)
	const [filters, setFilters] = useState<Filters>(new Filters())
	const [page, setPage] = useState<number>(PAGE)
	const selectedType = POST_TYPES[
		POST_TYPES.findIndex(x => x.name === params.type)
	] ?? {
		name: "annonces",
		id: 0,
		label: "Annonces",
	}
	const orders: Option[] = [
		{ text: "Plus récent au plus ancien", value: "desc" },
		{ text: "Plus ancien au plus récent", value: "asc" },
	]
	const posts: any = useQuery(
		["posts", params.type, filters, page],
		() =>
			postService.getAllPaginated({
				...formatPostFilters(filters, selectedType, {
					page,
					size: SIZE,
				}),
			}),
		{
			keepPreviousData: false,
			enabled: !!filters.address,
		}
	)

	const selfUser = useQuery<User>(
		"user",
		() => selfService.getCurrentUser(),
		{
			retry: false,
		}
	)

	const createAlert = useMutation(
		(alert: Alert) => alertService.post(alert),
		{
			onSuccess: (data: Alert) => {
				showConfirm(`Votre alerte ${data.name} a bien été activé.`)
				setAlertDialog(false)
			},
		}
	)

	useEffect(() => {
		setPage(PAGE)
	}, [params])

	const searchPosts = (data: Filters) => {
		if (!(data.address && (!data.latitude || !data.longitude))) {
			setFilters(data)
		} else {
			showConfirm("Vous devez sélectionner une adresse.", "error")
		}
	}

	const handleDateChange = (evt: any) => {
		setFilters({
			...filters,
			date: evt.target.value,
		})
	}

	const goToPost = (post: Post) => {
		navigate(`/post/view/${post.id}`)
	}

	const saveAlert = (name: string) => {
		createAlert.mutate({
			id: 0,
			name,
			user: selfUser.data || new SimpleUser(),
			...filters,
			query: filters.query || undefined,
			type: {
				id: selectedType.id,
				label: selectedType.label,
			},
		})
	}

	return (
		<div className="page search-posts">
			<h1 className="text-center pb-3 pt-3">
				{selectedType.id
					? `Annonces de ${selectedType.label.toLowerCase()}`
					: selectedType.label}
			</h1>
			<SearchFilters filters={filters} onSearch={searchPosts} />
			{posts.data ? (
				<div className="my-2 sort-fields">
					<h3 className="sort-fields-title">
						Trier les résultats par :
					</h3>
					<div className="sort-fields-content">
						<Select
							name="date"
							error={null}
							label="Date"
							register={() => 0}
							options={orders}
							onChange={handleDateChange}
							value={filters.date}
						/>
					</div>
				</div>
			) : (
				""
			)}
			{user !== null ? (
				<div className="create-alert my-2">
					<Button
						type="button"
						className="bg-primary w-full"
						onClick={() => setAlertDialog(true)}
					>
						<span className="flex flex-row justify-center">
							<BellIcon className="w-6 h-6 mr-2" /> Créer une
							alerte
						</span>
					</Button>
					<Modal
						title="Création d'une alerte"
						isOpen={alertDialog}
						onClose={() => setAlertDialog(false)}
					>
						<AlertForm saveAlert={saveAlert} />
					</Modal>
				</div>
			) : (
				""
			)}
			<div className="my-4 posts-list">
				{posts.data?.totalElements > 0 ? (
					<>
						{posts.data?.content?.map((p: Post) => (
							<Card
								className="post my-6"
								key={`post-${p.id}`}
								onClick={() => goToPost(p)}
							>
								<div className="post-picture">
									<img
										src={
											p.photos.length > 0
												? p.photos[0].image
												: DefaultImage
										}
										alt={p.title}
									/>
								</div>
								<div className="post-info">
									<h3 className="text-primaryDark">
										{p.title}
									</h3>
									<div className="post-meta">
										<div className="flex pt-1">
											<p className="post-meta__primary mr-3">
												{p.category.name}
											</p>
											<p className="post-meta__secondary">
												{dayjs(p.createdAt).format(
													"Créé le DD MMMM YYYY"
												)}
											</p>
										</div>
									</div>
									<div className="mt-auto">
										<p className="text-grayDark text-sm mr-4">
											{p.address}
										</p>
										<p className="text-grayDark text-sm">
											Référence de l'annonce : {p.id}
										</p>
									</div>
								</div>
							</Card>
						))}
						<div className="flex justify-between items-center tablet:justify-center">
							<ChevronLeftIcon
								type="button"
								className={`text-primary w-16 h-16 ${
									page === 0 ? "disabled" : ""
								}`}
								onClick={() =>
									page === 0
										? undefined
										: setPage(old => Math.max(old - 1, 0))
								}
							/>
							<h3 className="mx-4">
								{posts.data.pageable.offset + 1}–
								{posts.data.pageable.offset +
									posts.data.numberOfElements}{" "}
								sur {posts.data.totalElements}
							</h3>
							<ChevronRightIcon
								type="button"
								className={`text-primary w-16 h-16 ${
									page === posts.data.totalPages - 1
										? "disabled"
										: ""
								}`}
								onClick={
									page === posts.data.totalPages - 1
										? undefined
										: () =>
												setPage(old =>
													Math.min(
														old + 1,
														posts.data.totalPages -
															1
													)
												)
								}
							/>
						</div>
					</>
				) : (
					<h2 className="text-center my-4" key="posts-no-content">
						Aucune annonce à afficher.
					</h2>
				)}
			</div>
		</div>
	)
}

export default SearchPage
