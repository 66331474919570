import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { showConfirm } from "../../../../utils/Popup"

function useQuizIsAnswer() {
	const navigate = useNavigate()
	useEffect(() => {
		const quizStatus = localStorage.getItem("quiz_status")
		if (!quizStatus || quizStatus !== "success") {
			navigate("/rules")
			showConfirm(
				"Petit malin, fais d'abord le quiz avant de t'inscrire :)",
				"error",
				2500
			)
		}
	}, [])
}

export default useQuizIsAnswer
