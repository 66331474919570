import ReactSelectOption from "../../../api/models/ReactSelectOption"
import Role from "../../../enums/Role"
import { ITableColumn } from "../../../utils/Constants"

export const SIZE: number = 10
export const PAGE: number = 0

export const columns: ITableColumn[] = [
	{
		Header: "Civilité",
		accessor: "gender",
	},
	{
		Header: "Nom",
		accessor: "lastName",
	},
	{
		Header: "Prénom",
		accessor: "firstName",
	},
	{
		Header: "E-mail",
		accessor: "email",
	},
	{
		Header: "Téléphone",
		accessor: "phone",
	},
	{
		Header: "Créé le",
		accessor: "createdAt",
	},
	{
		Header: "Actions",
		accessor: "action",
	},
]

export const roleOptions: ReactSelectOption[] = [
	{ label: "Utilisateur", value: Role.USER },
	{ label: "Administrateur", value: Role.ADMIN },
]
