import { LatLngExpression } from "leaflet"
import Post from "../../../../api/models/Post"

export default function getGeolocationPositionOfPost(
	post: Post
): LatLngExpression[] {
	const positionArrayToReturn: LatLngExpression[] = []
	if (post.exchangePlaces && post.exchangePlaces.length > 0) {
		post.exchangePlaces.forEach(place => {
			positionArrayToReturn.push([
				Number(place.latitude),
				Number(place.longitude),
			])
		})
	} else {
		positionArrayToReturn.push([
			Number(post.latitude),
			Number(post.longitude),
		])
	}

	return positionArrayToReturn
}
