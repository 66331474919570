import { UseFormReturn } from "react-hook-form"
import getFileBase64 from "../../utils/getFileBase64"

interface UsePhotosInputType {
	onUploadPhoto(evt: any): void
	onDeletePhoto(index: number): void
}

function usePhotosInput(form: UseFormReturn): UsePhotosInputType {
	const onUploadPhoto = (evt: any) => {
		if (evt.target.files && evt.target.files[0]) {
			getFileBase64(evt.target.files[0], form, true)
			form.resetField("newPhoto")
		}
	}

	const onDeletePhoto = (index: number) => {
		form.getValues("photos").splice(index, 1)
		form.resetField("newPhoto")
	}

	return {
		onUploadPhoto,
		onDeletePhoto,
	}
}

export default usePhotosInput
