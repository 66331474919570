import { useState } from "react"
import ReactSelectOption from "../api/models/ReactSelectOption"
import Status from "../enums/Status"

interface UseMultipleStatusesReturn {
	statuses: Status[]
	onChangeStatuses: (selectedOptions: ReactSelectOption[]) => void
}

function useMultipleStatuses(
	defaultStatuses: Status[]
): UseMultipleStatusesReturn {
	const [statuses, setStatuses] = useState<Status[]>(defaultStatuses)

	const onChangeStatuses = (selectedOptions: ReactSelectOption[]) => {
		const newStatuses: Status[] = selectedOptions.map(
			selectedOption => selectedOption.value as Status
		)

		setStatuses(newStatuses)
	}

	return {
		statuses,
		onChangeStatuses,
	}
}

export default useMultipleStatuses
