import Button from "../../../components/Button/Button"
import Modal from "../../../components/Dialog/Modal"
import CGUText from "../../../components/Text/CGUText"

interface CGUModalType {
	isOpenModalCGU: boolean
	acceptCGU(): void
	closeCGUModal(): void
	allowAcceptCGU(e: any): void
	canAcceptCGU: boolean
}
function CGUModal({
	isOpenModalCGU,
	acceptCGU,
	allowAcceptCGU,
	closeCGUModal,
	canAcceptCGU,
}: CGUModalType) {
	return (
		<Modal
			isOpen={isOpenModalCGU}
			title={"Condition générale d'utilisation"}
			className="h-5/6"
		>
			<>
				<CGUText onScroll={allowAcceptCGU} />
				<div className="flex justify-end mt-1">
					<Button
						className="button bg-secondary"
						type="button"
						onClick={closeCGUModal}
					>
						Refuser
					</Button>
					<Button
						disabled={!canAcceptCGU}
						className="button bg-primary ml-1"
						type="button"
						onClick={() => acceptCGU()}
					>
						Accepter
					</Button>
				</div>
			</>
		</Modal>
	)
}

export default CGUModal
