import {
	CheckCircleIcon,
	EyeIcon,
	PencilIcon,
	XCircleIcon,
} from "@heroicons/react/outline"
import dayjs from "dayjs"
import { useContext, useState } from "react"
import {
	UseMutationResult,
	UseQueryResult,
	useMutation,
	useQuery,
} from "react-query"
import { useNavigate } from "react-router-dom"

import Post from "../../../../api/models/PostWithoutUser"
import postService from "../../../../api/services/PostService"
import UserContext from "../../../../contexts/UserContext"
import Status, { parseStatus } from "../../../../enums/Status"
import { showConfirm, showConfirmAction } from "../../../../utils/Popup"

import StatusModel from "../../../../api/models/StatusModel"

import userService from "../../../../api/services/UserService"

interface UseSelfPostReturn {
	rows: any[]
	posts: UseQueryResult<any, unknown>
	patchStatus: UseMutationResult<any, unknown, StatusModel, unknown>
	createPost: () => void
	toggleStatus: (data: Post) => void
	closeStatusModal: () => void
	selectedPost: Post
	saveNotation: (data: StatusModel) => void
}

export default function useSelfPost(
	query: string,
	size: number,
	page: number,
	sortBy: string,
	statuses: Status[]
): UseSelfPostReturn {
	const navigate = useNavigate()
	const { user } = useContext(UserContext)
	const [rows, setRows] = useState<any[]>([])

	const [selectedPost, setSelectedPost] = useState<Post>(new Post())

	const posts = useQuery<any>(
		["posts", page, size, query, user?.id, statuses, sortBy],
		() =>
			postService.getAllPaginated({
				page,
				size,
				userId: user.id,
				query: query || undefined,
				sort: sortBy || undefined,
				askedInMarket: false,
				statuses: statuses.length > 0 ? statuses.join(",") : undefined,
			}),
		{
			retry: false,
			keepPreviousData: false,
			enabled: user !== null,
			onSuccess: (data: any) => {
				setRows(
					data.content.map((x: Post) => ({
						...x,
						postType: x.type?.label,
						state: parseStatus(x.status),
						createdAt: dayjs(x.createdAt).format(
							"DD MMMM YYYY HH:mm"
						),
						actions:
							x.status !== Status.ARCHIVED ? (
								<div className="cell-action">
									<span title="Consulter">
										<EyeIcon
											className="w-6 transition duration-200 text-secondary mr-2 cursor-pointer hover:scale-125"
											onClick={() =>
												window.open(
													`/post/view/${x.id}`,
													"_blank"
												)
											}
										/>
									</span>
									{!x.alreadyNoted ? (
										<>
											{x.status === Status.ACTIVATED ? (
												<span title="Désactiver">
													<XCircleIcon
														className="w-6 transition duration-200 text-warning mr-2 cursor-pointer hover:scale-125"
														onClick={() =>
															toggleStatus(x)
														}
													/>
												</span>
											) : (
												<span title="Activer">
													<CheckCircleIcon
														className={`w-6 transition duration-200 cursor-pointer text-green mr-2 hover:scale-125 ${
															x.status ===
															Status.TO_VALIDATE
																? "disabled"
																: ""
														}`}
														onClick={
															x.status ===
															Status.TO_VALIDATE
																? undefined
																: () =>
																		toggleStatus(
																			x
																		)
														}
													/>
												</span>
											)}
											<span title="Editer">
												<PencilIcon
													className="w-6 transition duration-200 cursor-pointer text-primary mr-2 hover:scale-125"
													onClick={() =>
														navigate(
															`/post/edit/${x.id}`
														)
													}
												/>
											</span>
										</>
									) : (
										""
									)}
								</div>
							) : undefined,
					}))
				)
			},
		}
	)

	const patchStatus = useMutation(
		(data: StatusModel) =>
			postService.toggleStatus({
				id: data.postId,
				status: data.status,
				askedInMarket: data.askedInMarket,
			}),
		{
			onSuccess: (data, variables) => {
				posts.refetch()
				if (variables.user && variables.note) {
					patchNotation.mutate(variables)
				}
				showConfirm(
					selectedPost.status === Status.ACTIVATED
						? "Votre annonce a été désactivé."
						: "Votre annonce a été réactivé."
				)
				closeStatusModal()
			},
		}
	)

	const patchNotation = useMutation((data: StatusModel) =>
		userService.updateUserNotation(Number(data.userId), {
			...data,
			postId: selectedPost.id,
		})
	)

	const saveNotation = (data: StatusModel) => {
		patchStatus.mutate({
			...data,
			status:
				data.userId && data.note ? Status.ARCHIVED : Status.DEACTIVATED,
			postId: selectedPost.id,
			askedInMarket: selectedPost.askedInMarket,
			market: selectedPost.market,
		})
	}

	const closeStatusModal = () => {
		setSelectedPost(new Post())
	}

	const createPost = () => {
		navigate("/post/new")
	}

	const toggleStatus = (data: Post) => {
		if (data.status === Status.ACTIVATED) {
			setSelectedPost(data)
		} else {
			showConfirmAction(
				() => {
					patchStatus.mutate({
						note: 0,
						status: Status.ACTIVATED,
						postId: data.id,
						userId: "",
						askedInMarket: data.askedInMarket,
					})
				},
				"Une fois activé, l'annonce sera visible par tous les utilisateurs.",
				"Activer"
			)
		}
	}

	return {
		rows,
		posts,
		toggleStatus,
		createPost,
		patchStatus,
		closeStatusModal,
		selectedPost,
		saveNotation,
	}
}
