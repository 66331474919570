import { useNavigate } from "react-router-dom"
import Button from "../../../../components/Button/Button"
import Quiz from "../../../../assets/images/Quiz-rafiki.svg"

function About() {
	const navigate = useNavigate()

	const goQuiz = () => {
		navigate("/quiz")
	}

	return (
		<div className="page rules">
			<h1 className="rules-title">Conditions d'utilisation</h1>
			<div className="rules-card max-w-5xl">
				<h2 className="text-center mb-4">
					Bienvenue sur le site Les Chrysalides
				</h2>
				<img
					src={Quiz}
					alt="Quiz"
					className="w-36 float-right -mt-6 hidden tablet:block"
				/>

				<p className="my-2 text-justify">
					Ce site a pour but de vous permettre d'échanger des objets
					et des services gratuitement, favorisant ainsi le lien
					humain.
				</p>

				<p className="my-2 text-justify">
					Avant d'accéder au formulaire d'inscription, nous vous
					prions de bien vouloir faire un petit quiz pour vous aider à
					mieux connaître l'association.
				</p>

				<Button
					type="button"
					onClick={goQuiz}
					className="bg-primary mt-4 mx-auto block"
				>
					Commencer le quiz
				</Button>
			</div>
		</div>
	)
}

export default About
