import ExchangePlace from "../../../../api/models/ExchangePlace"
import Post from "../../../../api/models/Post"
import PostType from "../../../../api/models/PostType"
import ReactSelectOption from "../../../../api/models/ReactSelectOption"
import User from "../../../../api/models/User"
import { POST_TYPES } from "../../../../utils/Constants"
import { showConfirm } from "../../../../utils/Popup"
import {
	POST_INFO_FORM_REQUIRED_INPUTS,
	POST_LOCATION_FORM_REQUIRED_INPUTS,
	POST_TYPE_FORM_REQUIRED_INPUTS,
} from "../Constants"

export const isDonationType = (idDonationType: number): boolean => {
	const postType = POST_TYPES.find(t => t.id === idDonationType)
	if (postType?.label === "Dons") {
		return true
	}
	return false
}

export const currentValidationForm = (formStep: number): Array<string> => {
	switch (formStep) {
		case 0:
			return POST_TYPE_FORM_REQUIRED_INPUTS

		case 1:
			return POST_INFO_FORM_REQUIRED_INPUTS

		case 2:
			return POST_LOCATION_FORM_REQUIRED_INPUTS

		default:
			return []
	}
}

export const formatFormPostToPost = (
	data: any,
	connectedUser: User,
	exchangePlaces: ExchangePlace[]
): Post => {
	// If data there is exchangePlaces we save the first one in the post for the distance filter
	if (data.exchangePlaces?.length > 0) {
		const firstExchangePlace = exchangePlaces.find(
			x => x.id === data.exchangePlaces[0]
		)
		data.address = firstExchangePlace?.address
		data.latitude = firstExchangePlace?.latitude
		data.longitude = firstExchangePlace?.longitude

		data.exchangePlaces = data.exchangePlaces.map((epId: number) => ({
			id: epId,
		}))
	}

	if (!isDonationType(Number(data.type))) {
		data.askedInMarket = false
	}

	data.type = new PostType(data.type, "")
	data.userId = connectedUser.id

	return data
}

export const checkIfAddressIsSelected = (data: any): boolean => {
	if (!(data.address && (!data.latitude || !data.longitude))) {
		return true
	}
	showConfirm("Vous devez sélectionner une adresse.", "error")
	return false
}

export const exchangePlacesToReactSelectionOptions = (
	exchangePlaces: ExchangePlace[]
): ReactSelectOption[] =>
	exchangePlaces.map(
		r => new ReactSelectOption(r.id, `${r.name} (${r.address})`)
	)
