import { useEffect, useState } from "react"
import UserContext, { defaultState } from "./UserContext"

interface UserProviderProps {
	children: any
}

function UserProvider({ children }: UserProviderProps) {
	const [user, setUser] = useState(defaultState.user)

	useEffect(() => {
		const storageItem = localStorage.getItem("user")
		let lUser
		if (storageItem != null) lUser = JSON.parse(storageItem)

		if (lUser) {
			setUser(lUser)
		}
	}, [])

	return (
		<UserContext.Provider value={{ user, setUser }}>
			{children}
		</UserContext.Provider>
	)
}

export default UserProvider
