import { useContext } from "react"
import {
	FieldError,
	UseFormHandleSubmit,
	UseFormRegister,
	useForm,
} from "react-hook-form"
import { useMutation } from "react-query"
import { useNavigate } from "react-router-dom"
import AuthResponse from "../../../../api/models/AuthResponse"
import Credentials from "../../../../api/models/Credentials"
import authService from "../../../../api/services/AuthService"
import UserContext from "../../../../contexts/UserContext"
import { showConfirm } from "../../../../utils/Popup"

interface UseLoginFormType {
	responseFacebook(data: any): void
	onSubmit(data: Credentials): void
	register: UseFormRegister<Credentials>
	handleSubmit: UseFormHandleSubmit<Credentials>
	errors: {
		email?: FieldError | undefined
		password?: FieldError | undefined
		facebookAccessToken?: FieldError | undefined
	}
}
function useLoginForm(): UseLoginFormType {
	const navigate = useNavigate()

	const { setUser } = useContext(UserContext)

	const {
		handleSubmit,
		register,
		formState: { errors },
	} = useForm<Credentials>()

	const postLogin = useMutation(
		(credentials: Credentials) => authService.authenticate(credentials),
		{
			onSuccess: (data: AuthResponse) => {
				localStorage.setItem("user", JSON.stringify(data))
				setUser(data)
				navigate("/")
				showConfirm("Vous êtes à présent connecté sur la plateforme.")
			},
		}
	)

	const onSubmit = (data: Credentials) => {
		postLogin.mutate(data)
	}

	const responseFacebook = (data: any) => {
		if (data.accessToken) {
			postLogin.mutate({
				facebookAccessToken: data.accessToken,
				email: "",
				password: "",
			})
		} else {
			showConfirm("L'inscription avec Facebook a échoué.", "warning")
		}
	}
	return { responseFacebook, onSubmit, handleSubmit, register, errors }
}

export default useLoginForm
