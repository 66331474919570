import { useEffect } from "react"
import { useForm, UseFormReturn } from "react-hook-form"
import ExchangePlace from "../../../../../api/models/ExchangePlace"

interface UsePostFormType {
	form: UseFormReturn
}
function usePostForm(preloadedData: any): UsePostFormType {
	const form = useForm({ defaultValues: preloadedData })

	useEffect(() => {
		if (preloadedData !== null) {
			form.setValue("categoryId", String(preloadedData.category.id))
			form.setValue("subCategoryId", String(preloadedData.subCategory.id))
			form.setValue(
				"exchangePlaces",
				preloadedData.exchangePlaces?.map((ep: ExchangePlace) => ep.id)
			)
			form.setValue("type", String(preloadedData.type?.id))
		}
	}, [])

	return { form }
}
export default usePostForm
