import { UploadIcon } from "@heroicons/react/outline"

interface InputProps {
	label?: string
	name: string
	register: any
	required: boolean
	error?: any
	className?: string
	disabled?: boolean
	multiple?: boolean
	accept?: string
	onChange: any
}

function File({
	label,
	name,
	register,
	required = false,
	error,
	className,
	disabled = false,
	multiple = false,
	accept = "image/*",
	onChange,
}: InputProps) {
	return (
		<div className="input-group">
			<label data-testid="label" className="text-sm font-medium">
				{error?.type !== "required" ? (
					label
				) : (
					<span className="input-error">
						{label ?? "Ce champ"} est requis
					</span>
				)}
			</label>
			<label
				htmlFor={name}
				className={`input-upload ${className ?? ""} ${
					error ? "!border-redCustom" : ""
				}`}
			>
				<input
					hidden
					{...register(name, { required })}
					type="file"
					name={name}
					id={name}
					disabled={disabled || false}
					multiple={multiple || false}
					accept={accept}
					onChange={onChange}
					data-testid="input"
				/>
				<UploadIcon className="w-5 h-5 mr-1" />
				Choisir un fichier
			</label>
		</div>
	)
}

export default File
