import { useMutation, UseMutationResult, useQueryClient } from "react-query"
import News from "../../../../api/models/News"
import newsService from "../../../../api/services/NewsService"
import { showConfirm } from "../../../../utils/Popup"

interface UsePatchNewsType {
	patchNews: UseMutationResult<News, unknown, News>
}

function usePatchNews(): UsePatchNewsType {
	const queryClient = useQueryClient()

	const patchNews = useMutation(
		(item: News) => newsService.patch(item, item.id),
		{
			onSuccess: () => {
				showConfirm("La nouvelle actualité a été publiée.")
				queryClient.invalidateQueries({ queryKey: ["news"] })
			},
		}
	)

	return { patchNews }
}

export default usePatchNews
