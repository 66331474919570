import { ChevronDownIcon } from "@heroicons/react/outline"
import { useState } from "react"
import { Collapse } from "react-collapse"
import { useForm } from "react-hook-form"
import { useMutation, useQueryClient } from "react-query"
import GtuAnswer from "../../../api/models/GtuAnswer"

import GtuQuestion from "../../../api/models/GtuQuestion"
import gtuQuestionService from "../../../api/services/GtuQuestionService"
import Button from "../../../components/Button/Button"
import Card from "../../../components/Card/Card"
import Input from "../../../components/Form/Input/Input"
import { showConfirm, showConfirmAction } from "../../../utils/Popup"
import AnswerForm from "./AnswerForm"
import FormAction from "../../../components/Form/Admin/FormAction"

interface GtuQuestionFormProps {
	gtuQuestion: GtuQuestion
	index: number
	setAddQuestion: (value: boolean) => void
	className?: string
}

function QuestionForm({
	gtuQuestion,
	index,
	setAddQuestion,
	className,
}: GtuQuestionFormProps) {
	const {
		handleSubmit,
		register,
		formState: { errors },
	} = useForm<GtuQuestion>()

	const [openQuestion, setOpenQuestion] = useState<boolean>(false)
	const [editQuestion, setEditQuestion] = useState<boolean>(index === 0)
	const [addAnswer, setAddAnswer] = useState<boolean>(index === 0)
	const queryClient = useQueryClient()

	const deleteQuestion = useMutation(
		(id: number) => gtuQuestionService.delete(id),
		{
			onSuccess: (data, variables) => {
				queryClient.setQueryData(["gtuQuestions"], (old: any) => {
					const lOld = [...old]
					lOld.forEach((question: GtuQuestion, i: number) => {
						if (question.id === variables) {
							lOld.splice(i, 1)
						}
					})
					return lOld
				})
				showConfirm("La question a été supprimée", "success")
			},

			onError: () => {
				showConfirm("Erreur lors de la suppression", "error")
			},
		}
	)

	const updateQuestion = useMutation(
		(question: GtuQuestion) =>
			gtuQuestionService.put(question, question.id),
		{
			onSuccess: (data, variables) => {
				queryClient.setQueryData(["gtuQuestions"], (old: any) => {
					const lOld = [...old]
					lOld.forEach((question: GtuQuestion, i: number) => {
						if (question.id === variables.id) {
							lOld[i] = variables
						}
					})
					return lOld
				})
				showConfirm("La question a été mise à jour", "success")
			},

			onError: () => {
				showConfirm("Erreur lors de la mise à jour", "error")
			},
		}
	)

	const createQuestion = useMutation(
		(question: GtuQuestion) => gtuQuestionService.post(question),
		{
			onSuccess: data => {
				queryClient.setQueryData(["gtuQuestions"], (old: any) => {
					const lOld = [...old]
					lOld.push(data)
					return lOld
				})
				showConfirm("La question a été créée", "success")
			},

			onError: () => {
				showConfirm("Erreur lors de la création", "error")
			},
		}
	)

	const onSubmit = (data: GtuQuestion) => {
		if (gtuQuestion.id) {
			data.id = gtuQuestion.id
			updateQuestion.mutate(data)
			setEditQuestion(false)
		} else {
			createQuestion.mutate(data)
			setAddQuestion(false)
		}
	}

	return (
		<Card className={`w-5/6 m-auto mt-3 ${className ?? ""}`}>
			<form
				className="gtu-question-form"
				onSubmit={handleSubmit(onSubmit)}
			>
				<h3 className="">
					<b>{index}.</b>
				</h3>
				{editQuestion ? (
					<div className="w-5/6 ml-1">
						<Input
							name="text"
							type="text"
							defaultValue={gtuQuestion.text}
							register={register}
							required
							error={errors.text}
						/>
					</div>
				) : (
					<h3 className="w-5/6 truncate ">{gtuQuestion.text}</h3>
				)}
				<FormAction
					setEdit={setEditQuestion}
					setAdd={setAddQuestion}
					isEditing={editQuestion}
					index={index}
					onDelete={() =>
						showConfirmAction(() =>
							deleteQuestion.mutate(gtuQuestion.id)
						)
					}
				/>
				<ChevronDownIcon
					onClick={() => setOpenQuestion(!openQuestion)}
					className={`w-5 h5   cursor-pointer ${
						openQuestion ? "rotate-180" : ""
					} `}
				/>
			</form>
			<Collapse isOpened={openQuestion}>
				{gtuQuestion?.answers?.map((a, i) => (
					<AnswerForm
						questionId={gtuQuestion.id}
						answer={a}
						key={a.id}
						index={i + 1}
						setAddAnswer={setAddQuestion}
					/>
				))}
				{gtuQuestion?.answers?.length === 0 && (
					<h4 className="text-center text-info p-2">
						Pas encore de réponses, veuillez en rajouter une !
					</h4>
				)}

				{/* Add a dynamic answer Form */}
				{addAnswer && (
					<AnswerForm
						className="!border-primary"
						answer={new GtuAnswer()}
						// index === 0, means that we add a answer at the end of the list
						index={0}
						setAddAnswer={setAddAnswer}
						questionId={gtuQuestion.id}
					/>
				)}
				<div className="pb-2 pt-2">
					<Button
						type="button"
						className="bg-secondary text-black w-3/4 m-auto block"
						onClick={() => setAddAnswer(true)}
						disabled={addAnswer}
					>
						Ajouter une réponse
					</Button>
				</div>
			</Collapse>
		</Card>
	)
}

export default QuestionForm
