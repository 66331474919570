import { useState } from "react"
import { useForm } from "react-hook-form"
import Button from "../../components/Button/Button"
import CustomEditor from "../../components/Form/Editor/CustomEditor"
import Input from "../../components/Form/Input/Input"
import ContactModel from "./ContactModel"

interface ContactFormProps {
	model: ContactModel
	sendEmail: any
}

function ContactForm({ model, sendEmail }: ContactFormProps) {
	const [message, setMessage] = useState<string>("")

	const form = useForm<ContactModel>({
		defaultValues: model,
	})

	const onSubmit = (data: ContactModel) => {
		data.message = message
		sendEmail(data)
	}

	return (
		<form onSubmit={form.handleSubmit(onSubmit)}>
			<Input
				name="name"
				register={form.register}
				required
				type="text"
				error={form.formState.errors.name}
				label="Entrez votre nom"
			/>

			<Input
				name="email"
				register={form.register}
				required
				type="text"
				error={form.formState.errors.email}
				label="Entrer votre adresse e-mail"
			/>

			<div className="mt-4">
				<CustomEditor value={message} setValue={setMessage} />
			</div>

			<Button type="submit" className="bg-primary mt-4 w-full">
				Envoyer le message
			</Button>
		</form>
	)
}

export default ContactForm
