import dayjs from "dayjs"
import { useForm } from "react-hook-form"
import Market from "../../../api/models/Market"
import Button from "../../../components/Button/Button"
import LocationField from "../../../components/Form/Autocomplete/LocationField"
import Input from "../../../components/Form/Input/Input"
import CategoryField from "../../../components/Form/Select/CategoryField"

interface MarketFormProps {
	market: Market

	onSubmit: (data: Market) => void
}

function MarketForm({ market, onSubmit }: MarketFormProps) {
	const form = useForm<Market>({
		defaultValues: market,
	})

	return (
		<form onSubmit={form.handleSubmit(onSubmit)}>
			<Input
				name="name"
				register={form.register}
				type="text"
				required
				error={form.formState.errors.name}
				label="Libellé"
			/>

			<LocationField
				register={form.register}
				error={form.formState.errors.address}
				setValue={form.setValue}
				required
			/>

			<Input
				name="startedAt"
				register={form.register}
				type="datetime-local"
				required
				error={form.formState.errors.startedAt}
				label="Date de début de collecte"
				defaultValue={dayjs(market.startedAt).format(
					"YYYY-MM-DD[T]HH:mm"
				)}
			/>

			<Input
				name="endedAt"
				register={form.register}
				type="datetime-local"
				required
				error={form.formState.errors.endedAt}
				label="Date de fin de collecte"
				defaultValue={dayjs(market.endedAt).format(
					"YYYY-MM-DD[T]HH:mm"
				)}
			/>

			<CategoryField
				register={form.register}
				setValue={form.setValue}
				getValues={form.getValues}
				category={{
					error: form.formState.errors.categoryId,
					value: String(market.category?.id),
					label: "Catégorie",
				}}
				subCategory={{
					error: form.formState.errors.subCategoryId,
					value: String(market.subCategory?.id),
					label: "Sous-catégorie",
				}}
				required={false}
			/>

			<Button type="submit" className="bg-primary w-full mt-4">
				{market.id ? "Sauvegarder" : "Créer"}
			</Button>
		</form>
	)
}

export default MarketForm
