import Location from "../models/Location"
import GeneralService from "./GeneralService"

export class GeoService extends GeneralService<Location | any> {
	constructor() {
		super("locations")
		this.spinnerIsActivated = false
	}

	findLocationsByTerm(term: string): Promise<Location[]> {
		return this.get(`/search/${term}`)
	}
}

const geoService = new GeoService()

export default geoService
