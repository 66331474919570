import {
	Client,
	IStompSocket,
	messageCallbackType,
	StompHeaders,
} from "@stomp/stompjs"
import { useContext, useEffect, useRef, useState } from "react"
import SockJS from "sockjs-client"
import UserContext from "../UserContext"
import { StompSessionProviderProps } from "./interfaces/StompSessionProviderProps"
import { StompSessionSubscription } from "./interfaces/StompSessionSubscription"
import StompContext from "./StompContext"

/**
 * The StompSessionProvider manages the STOMP connection
 * All Hooks and HOCs in this library require an ancestor of this type.
 * The URL to connect to can be specified via the url prop.
 * Depending on the Schema of the URL either Sockjs or a raw Websocket is used.
 * You can override this behavior with the brokerURL or webSocketFactory props, which will then be forwarded to @stomp/stompjs
 * Custom @stomp/stompjs options can be used as props.
 * Please consult the @stomp/stompjs documentation for more information.
 */
function StompSessionProvider(props: StompSessionProviderProps) {
	const { user } = useContext(UserContext)

	let { url, ...stompOptions } = props
	const { children, stompClientOptions } = props

	// Support old API
	if (stompClientOptions) stompOptions = stompClientOptions

	const [client, setClient] = useState<Client | undefined>(undefined)
	const subscriptionRequests = useRef(new Map())

	useEffect(() => {
		const _client = new Client(stompOptions)
		// Add jwtoken in queryParam
		if (user != null) {
			url = `${url}?token=Bearer ${user.jwtToken}`

			if (!stompOptions.brokerURL && !stompOptions.webSocketFactory) {
				_client.webSocketFactory = function create() {
					const parsedUrl = new URL(url, window?.location?.href)
					if (
						parsedUrl.protocol === "http:" ||
						parsedUrl.protocol === "https:"
					) {
						return new SockJS(url) as IStompSocket
					}
					if (
						parsedUrl.protocol === "ws:" ||
						parsedUrl.protocol === "wss:"
					) {
						return new WebSocket(url) as IStompSocket
					}
					throw new Error("Protocol not supported")
				}
			}

			_client.onConnect = function connect(frame) {
				if (stompOptions.onConnect) stompOptions.onConnect(frame)

				subscriptionRequests.current.forEach(value => {
					value.subscription = _client.subscribe(
						value.destination,
						value.callback,
						value.headers
					)
				})

				setClient(_client)
			}

			_client.onWebSocketClose = function close(event) {
				if (stompOptions.onWebSocketClose)
					stompOptions.onWebSocketClose(event)

				setClient(undefined)
			}

			if (!stompOptions.onStompError) {
				_client.onStompError = function error(frame) {
					throw frame
				}
			}

			_client.activate()
		}
		return () => {
			_client.deactivate()
		}
	}, [user, url, ...Object.values(stompOptions)])

	const subscribe = (
		destination: string,
		callback: messageCallbackType,
		headers: StompHeaders = {}
	) => {
		const subscriptionId = crypto
			.getRandomValues(new Uint32Array(10))
			.toString()
		const subscriptionRequest: StompSessionSubscription = {
			destination,
			callback,
			headers,
		}

		subscriptionRequests.current.set(subscriptionId, subscriptionRequest)

		if (client && client.connected) {
			subscriptionRequest.subscription = client.subscribe(
				destination,
				callback,
				headers
			)
		}

		return () => {
			const subscriptionData =
				subscriptionRequests.current.get(subscriptionId)

			if (subscriptionData.subscription) {
				subscriptionData.subscription.unsubscribe()
			}

			subscriptionRequests.current.delete(subscriptionId)
		}
	}

	return (
		<StompContext.Provider
			value={{
				client,
				subscribe,
			}}
		>
			{children}
		</StompContext.Provider>
	)
}

export default StompSessionProvider
