import Report from "../models/Report"
import GeneralService from "./GeneralService"

class ReportService extends GeneralService<Report> {
	deactivate(reportId: number) {
		return this.post(null, `/deactivate/${reportId}`)
	}
}

const reportService = new ReportService("reports")

export default reportService
