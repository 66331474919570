import React from "react"
import { useNavigate } from "react-router-dom"
import Report from "../../../api/models/Report"
import Card from "../../../components/Card/Card"
import StarChat from "../../../assets/images/StartChat-rafiki.svg"

interface ContactProps {
	report: Report
}

function Contact({ report }: ContactProps) {
	const navigate = useNavigate()

	const startChatWithUser = (id: number) => {
		navigate(`/start-chat/${id}/post/${report.post?.id}`)
	}

	return (
		<div className="modal-contact">
			<p className="reason">
				<b>Raison :</b> {report.reason.label}
			</p>

			<div className="contacts">
				{report.post ? (
					report.post.user ? (
						<Card
							className="contact-card"
							onClick={() =>
								startChatWithUser(Number(report.post?.user?.id))
							}
						>
							<img src={StarChat} alt="Start chat with user" />
							<p className="user">
								<b>Utilisateur :</b>{" "}
								{report.post.user.firstName}{" "}
								{report.post.user.lastName}
							</p>
						</Card>
					) : (
						""
					)
				) : (
					<Card
						className="contact-card"
						onClick={() =>
							startChatWithUser(Number(report.user?.id))
						}
					>
						<img
							src={StarChat}
							alt="Start chat with user linked to post"
						/>
						<p className="user">
							<b>Utilisateur :</b> {report.user?.firstName}{" "}
							{report.user?.lastName}
						</p>
					</Card>
				)}
				<Card
					className="contact-card"
					onClick={() => startChatWithUser(report.author.id)}
				>
					<img src={StarChat} alt="Start chat with author" />
					<p className="author">
						<b>Auteur :</b> {report.author.firstName}{" "}
						{report.author.lastName}
					</p>
				</Card>
			</div>
		</div>
	)
}

export default Contact
