import ReactSelectOption from "../api/models/ReactSelectOption"

// eslint-disable-next-line no-shadow
enum Status {
	TO_VALIDATE = "TO_VALIDATE",
	ACTIVATED = "ACTIVATED",
	DEACTIVATED = "DEACTIVATED",
	ARCHIVED = "ARCHIVED",
}

// eslint-disable-next-line consistent-return
export const parseStatus = (value: Status): string => {
	// eslint-disable-next-line default-case
	switch (value) {
		case Status.TO_VALIDATE:
			return "Non validé"
		case Status.ACTIVATED:
			return "Activé"
		case Status.DEACTIVATED:
			return "Désactivé"
		case Status.ARCHIVED:
			return "Archivé"
	}
}

export const STATUSES_OPTIONS: Array<ReactSelectOption> = [
	{
		label: "Non validé",
		value: Status.TO_VALIDATE,
	},
	{
		label: "Activé",
		value: Status.ACTIVATED,
	},
	{
		label: "Désactivé",
		value: Status.DEACTIVATED,
	},
	{
		label: "Archivé",
		value: Status.ARCHIVED,
	},
]

export default Status
