import { PencilIcon, TrashIcon } from "@heroicons/react/outline"
import { useState } from "react"
import { UseQueryResult, useMutation, useQuery } from "react-query"
import Page from "../../../../api/models/Page"
import Reason from "../../../../api/models/Reason"
import reasonService from "../../../../api/services/ReasonService"
import { showConfirm, showConfirmAction } from "../../../../utils/Popup"

interface UseReasonsAdminReturn {
	rows: any[]
	reasons: UseQueryResult<Page<Reason>>
	openReasonDialog: () => void
	reason: Reason
	dialog: boolean
	closeDialog: () => void
	onSubmit: (data: { label: string }) => void
}

function useReasonsAdmin(
	query: string,
	size: number,
	page: number,
	sortBy: string
): UseReasonsAdminReturn {
	const [rows, setRows] = useState<any[]>([])
	const [dialog, setDialog] = useState<boolean>(false)
	const [reason, setReason] = useState<Reason>(new Reason())

	const reasons = useQuery<Page<Reason>>(
		["reasons", page, size, query, sortBy],
		() =>
			reasonService.getAllPaginated({
				page,
				size,
				query: query || undefined,
				sort: sortBy || undefined,
			}),
		{
			retry: false,
			keepPreviousData: false,
			onSuccess: (data: Page<Reason>) => {
				setRows(
					data.content.map((r: Reason) => ({
						...r,
						actions: (
							<div className="cell-action">
								<span title="Editer">
									<PencilIcon
										className="w-6 transition duration-200 text-primary mr-2 cursor-pointer hover:scale-125"
										onClick={() => openFormDialog(r)}
									/>
								</span>
								<span title="Supprimer">
									<TrashIcon
										className={`w-6 transition duration-200 text-redCustom  mr-2 cursor-pointer hover:scale-125 ${
											r.numbersOfReports <= 0
												? ""
												: "disabled"
										}`}
										onClick={
											r.numbersOfReports === 0
												? () => confirmDeletion(r)
												: undefined
										}
									/>
								</span>
							</div>
						),
					}))
				)
			},
		}
	)

	const createReason = useMutation(
		(data: Reason) => reasonService.post(data),
		{
			onSuccess: () => {
				showConfirm("La raison a bien été créée.")
				closeDialog()
				reasons.refetch()
			},
		}
	)

	const updateReason = useMutation(
		(data: Reason) => reasonService.put(data, data.id),
		{
			onSuccess: () => {
				showConfirm("La raison a bien été sauvegardée.")
				closeDialog()
				reasons.refetch()
			},
		}
	)

	const deleteReason = useMutation(
		(data: Reason) => reasonService.delete(data.id),
		{
			onSuccess: () => {
				showConfirm("La raison a bien été supprimée.")
				reasons.refetch()
			},
		}
	)

	const closeDialog = () => {
		setDialog(false)
		setReason(new Reason())
	}

	const openFormDialog = (r: Reason) => {
		setReason({
			...r,
		})
		openReasonDialog()
	}

	const openReasonDialog = () => {
		setDialog(true)
	}

	const confirmDeletion = (data: Reason) => {
		showConfirmAction(() => {
			deleteReason.mutate(data)
		})
	}

	const onSave = (data: Reason) => {
		if (reason.id) {
			updateReason.mutate(data)
		} else {
			createReason.mutate(data)
		}
	}

	const onSubmit = (data: { label: string }) => {
		onSave({
			...reason,
			label: data.label,
		})
	}
	return {
		rows,
		reasons,
		openReasonDialog,
		reason,
		dialog,
		closeDialog,
		onSubmit,
	}
}

export default useReasonsAdmin
