import Category from "./Category"
import Location from "./Location"
import SubCategory from "./SubCategory"

class Market extends Location {
	id: number = 0

	name: string = ""

	startedAt: Date = new Date()

	endedAt: Date = new Date()

	category?: Category

	categoryId: string = ""

	subCategory?: SubCategory

	subCategoryId: string = ""

	numberOfPosts: number = 0
}

export default Market
