import {
	ArrowSmDownIcon,
	ArrowSmUpIcon,
	SwitchVerticalIcon,
} from "@heroicons/react/outline"
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid"
import { ReactNode } from "react"
import { Cell, HeaderGroup, Row } from "react-table"
import Page from "../../api/models/Page"
import Button from "../Button/Button"
import Card from "../Card/Card"
import Select, { Option } from "../Form/Select/Select"
import GlobalFilter from "./GlobalFilter"

interface TableLayoutProps<T> {
	getTableProps: any
	getTableBodyProps: any
	headerGroups: HeaderGroup<object>[]
	rows: Row<any>[]
	prepareRow: (row: Row<object>) => void
	openFormDialog?: any
	query?: any
	pagination?: boolean
	page?: number
	size?: number
	setPage?: (newPage: any) => any
	setSize?: (newSize: any) => any
	rowData?: Page<T>
	Filter?: ReactNode
}

function TableLayout<T>({
	getTableProps,
	getTableBodyProps,
	headerGroups,
	rows,
	prepareRow,
	openFormDialog,
	query = {},
	pagination = false,
	page = 0,
	size = 0,
	setPage = () => 0,
	setSize = () => 0,
	rowData = new Page<T>(),
	Filter,
}: TableLayoutProps<T>) {
	const sizes: number[] = [5, 10, 15, 20]
	const sizeOptions: Option[] = [
		{ text: "5", value: "0" },
		{ text: "10", value: "1" },
		{ text: "15", value: "2" },
		{ text: "20", value: "3" },
	]

	return (
		<Card className="section data px-4 py-4">
			<div className="flex flex-col">
				{Filter}
				<div className="global-filter">
					<GlobalFilter
						globalFilter={query.value}
						setGlobalFilter={query.setValue}
					/>
					{openFormDialog && (
						<Button
							type="button"
							className="bg-primary ml-4"
							onClick={() => openFormDialog()}
						>
							Ajouter
						</Button>
					)}
				</div>
			</div>
			{rows.length > 0 ? (
				<table {...getTableProps()} className="table">
					<thead className="table-header">
						{headerGroups.map(headerGroup => (
							<tr
								{...headerGroup.getHeaderGroupProps()}
								className="table-header-row"
							>
								{headerGroup.headers.map((column: any) => (
									<th
										{...column.getHeaderProps(
											column.render("Header") !==
												"Actions"
												? column.getSortByToggleProps()
												: undefined
										)}
										className="table-header-cell"
									>
										<p className="cell-text">
											{column.render("Header")}
										</p>
										{column.render("Header") !==
										"Actions" ? (
											<span className="cell-sort">
												{column.isSorted ? (
													column.isSortedDesc ? (
														<ArrowSmDownIcon className="w-4 h-4 ml-2" />
													) : (
														<ArrowSmUpIcon className="w-4 h-4 ml-2" />
													)
												) : (
													<SwitchVerticalIcon className="w-4 h-4 ml-2" />
												)}
											</span>
										) : (
											""
										)}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()} className="table-body">
						{rows.map(row => {
							prepareRow(row)
							return (
								<tr
									{...row.getRowProps()}
									className={`table-body-row bg-opacity-50 ${
										row.original.highlighting
											? "bg-yellowStar hover:bg-opacity-70"
											: "hover:bg-gray"
									}`}
								>
									{row.cells.map((cell: Cell) => (
										<td
											{...cell.getCellProps()}
											className="table-body-cell"
										>
											{cell.value
												? cell.render("Cell")
												: "N/A"}
										</td>
									))}
								</tr>
							)
						})}
					</tbody>
				</table>
			) : (
				<h3 className="text-center table">Aucune entrée trouvée.</h3>
			)}

			{pagination ? (
				<div className="pagination flex flex-row items-center justify-end">
					<h4 className="mr-4 font-normal">Lignes par page :</h4>
					<Select
						name="size"
						error={null}
						required
						register={() => 0}
						label=""
						className="mx-4"
						inputClassName="py-1 text-sm"
						options={sizeOptions}
						value={String(sizes.indexOf(size))}
						onChange={(evt: any) =>
							setSize(sizes[Number(evt.target.value)])
						}
					/>
					{rowData.totalElements > 0 ? (
						<h4 className="mx-4 font-normal">
							{rowData.pageable.offset + 1}–
							{rowData.pageable.offset + rowData.numberOfElements}{" "}
							sur {rowData.totalElements}
						</h4>
					) : (
						""
					)}
					<ChevronLeftIcon
						type="button"
						className={`w-6 h-6 mx-2 text-xs ${
							page === 0 ? "disabled" : undefined
						}`}
						onClick={
							page === 0
								? undefined
								: () =>
										setPage((old: number) =>
											Math.max(old - 1, 0)
										)
						}
					/>
					<ChevronRightIcon
						type="button"
						className={`w-6 h-6 ml-2 text-xs ${
							page === (rowData.totalPages || 1) - 1
								? "disabled"
								: undefined
						}`}
						onClick={
							page === (rowData.totalPages || 1) - 1
								? undefined
								: () =>
										setPage((old: number) =>
											Math.min(
												old + 1,
												(rowData.totalPages || 1) - 1
											)
										)
						}
					/>
				</div>
			) : (
				""
			)}
		</Card>
	)
}

export default TableLayout
