import { useState } from "react"
import { useQuery } from "react-query"
import GtuQuestion from "../../../api/models/GtuQuestion"
import gtuQuestionService from "../../../api/services/GtuQuestionService"
import Button from "../../../components/Button/Button"
import QuestionForm from "./QuestionForm"

function QuestionPage() {
	const apiGtuQuestions = useQuery<GtuQuestion[]>("gtuQuestions", () =>
		gtuQuestionService.getAllWithFilters()
	)

	const [addQuestion, setAddQuestion] = useState(false)
	return (
		<div className="page">
			<h1 className="text-center pb-3 pt-3">
				Les questions générales d'utilisation
			</h1>
			{apiGtuQuestions?.data?.map((gtuQuestion, index) => (
				<QuestionForm
					key={gtuQuestion.id}
					gtuQuestion={gtuQuestion}
					index={index + 1}
					setAddQuestion={setAddQuestion}
				/>
			))}

			{/* Add a dynamic question Form */}
			{addQuestion && (
				<QuestionForm
					className="!border-primary"
					gtuQuestion={new GtuQuestion()}
					// index === 0, means that we add a question at the end of the list
					index={0}
					setAddQuestion={setAddQuestion}
				/>
			)}
			<div className="pt-2 pb-2">
				<Button
					type="button"
					className="bg-primary w-3/4 m-auto block"
					onClick={() => setAddQuestion(true)}
					disabled={addQuestion}
				>
					Ajouter une question
				</Button>
			</div>
		</div>
	)
}

export default QuestionPage
