import ReactSelectOption from "../../../api/models/ReactSelectOption"

export const SIZE: number = 10
export const PAGE: number = 0
export const DEFAULT_ADDRESS: any = {
	address: "Avenue de la Libération 59140 Dunkerque",
	latitude: "51.03878",
	longitude: "2.388957",
	distance: "5",
}

export const distances: ReactSelectOption[] = [
	{ label: "Sélectionner une distance", value: "" },
	{ label: "5 km", value: "5" },
	{ label: "10 km", value: "10" },
	{ label: "20 km", value: "20" },
	{ label: "30 km", value: "30" },
	{ label: "Plus de 30km", value: "120" },
]
