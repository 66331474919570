import { Dialog } from "@headlessui/react"
import { XIcon } from "@heroicons/react/outline"

interface ModalProps {
	isOpen: boolean
	onClose?: any
	title: string
	children: JSX.Element
	className?: string
}

function Modal({ isOpen, onClose, title, children, className }: ModalProps) {
	return (
		<Dialog
			open={isOpen}
			onClose={onClose || (() => {})}
			className="relative dialog"
		>
			<div className="fixed inset-0 bg-black/60" />
			<div className="fixed inset-0 flex items-center justify-center p-4">
				<Dialog.Panel
					className={`w-full max-w-xl rounded bg-white p-4 relative ${className}`}
				>
					<Dialog.Title className="text-xl tablet:text-2xl">
						{title}
					</Dialog.Title>
					{onClose && (
						<XIcon
							className="w-6 transition duration-200 cursor-pointer hover:scale-125 absolute right-2 top-2"
							onClick={onClose}
						/>
					)}
					{children}
				</Dialog.Panel>
			</div>
		</Dialog>
	)
}

export default Modal
