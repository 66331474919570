import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import "dayjs/locale/fr"

import Footer from "./components/Menu/Footer/Footer"
import Header from "./components/Menu/Header/Header"
import UserProvider from "./contexts/UserProvider"
import routes from "./routes"
import StompSessionProvider from "./contexts/stomp/StompSessionProvided"

dayjs.locale("fr")
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault("Europe/Paris")

const queryClient: QueryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retry: false,
		},
	},
})

function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<UserProvider>
				<StompSessionProvider
					url={`${process.env.REACT_APP_API_URL}/ws`}
				>
					<div className="overlay" id="overlay">
						<div id="overlay-spin" className="spin overlay-spin" />
					</div>
					<div className="app">
						<Header />
						<main className="app-content">{routes}</main>
						<Footer />
					</div>
				</StompSessionProvider>
			</UserProvider>
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	)
}

export default App
