import { ArchiveIcon, ChatAlt2Icon, EyeIcon } from "@heroicons/react/outline"
import dayjs from "dayjs"
import { useState } from "react"
import { useMutation, useQuery, UseQueryResult } from "react-query"
import Page from "../../../../api/models/Page"
import Report from "../../../../api/models/Report"
import reportService from "../../../../api/services/ReportService"
import { showConfirm, showConfirmAction } from "../../../../utils/Popup"

interface UseReportsQueryReturn {
	rows: any[]
	report: Report
	reportsQuery: UseQueryResult<Page<Report>>
	setReport: (report: Report) => void
}

function useReportsQuery(
	query: string,
	size: number,
	page: number,
	sortBy: string
): UseReportsQueryReturn {
	const [rows, setRows] = useState<any[]>([])
	const [report, setReport] = useState<Report>(new Report())

	const patchNews = useMutation(
		(reportId: number) => reportService.deactivate(reportId),
		{
			onSuccess: () => {
				reportsQuery.refetch()
				showConfirm("Le signalement a été archivé.")
			},
		}
	)

	const reportsQuery = useQuery<Page<Report>>(
		["reports", query, size, page, sortBy],
		() =>
			reportService.getAllPaginated({
				page,
				size,
				query: query || undefined,
				sort: sortBy || undefined,
			}),
		{
			retry: false,
			keepPreviousData: false,
			onSuccess: (data: Page<Report>) => {
				setRows(
					data.content.map((r: Report) => ({
						...r,
						createdAt: dayjs(r.createdAt).format(
							"DD MMMM YYYY HH:mm"
						),
						actions: (
							<div className="cell-action">
								{r.post && (
									<span title="Voir l'annonce">
										<EyeIcon
											className="w-6 transition duration-200 text-secondary mr-2 cursor-pointer hover:scale-125"
											onClick={() =>
												window.open(
													`/post/view/${r.post?.id}`,
													"_blank"
												)
											}
										/>
									</span>
								)}

								<span title="Prendre contact">
									<ChatAlt2Icon
										className="w-6 transition duration-200 text-primary mr-2 cursor-pointer hover:scale-125"
										onClick={() => setReport(r)}
									/>
								</span>
								<span title="Archiver le signalement">
									<ArchiveIcon
										className="w-6 transition duration-200 text-redAdmin mr-2 cursor-pointer hover:scale-125"
										onClick={() =>
											showConfirmAction(
												() => patchNews.mutate(r.id),
												"En validant la demande d'archivage, vous ne verrez plus ce signalement.",
												"Oui, archiver !"
											)
										}
									/>
								</span>
							</div>
						),
					}))
				)
			},
		}
	)

	return { report, reportsQuery, rows, setReport }
}

export default useReportsQuery
