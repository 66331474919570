import { Route, Routes } from "react-router-dom"
import ConfirmMail from "../pages/Auth/ConfirmMail"
import ForbiddenAccess from "../pages/Errors/403"
import PageNotFound from "../pages/Errors/404"
import Home from "../pages/Home"
import Login from "../pages/Auth/Login"
import Register from "../pages/Auth/Register"
import ForgotPassword from "../pages/Auth/ForgotPassword"
import ResetPassword from "../pages/Auth/ResetPassword"
import QuestionPage from "../pages/Admin/GtuQuestion"
import SelfProfile from "../pages/Self/Profile"
import ProtectedRoute from "./ProtectedRoute"
import Role from "../enums/Role"
import CategoryPage from "../pages/Admin/Categories"
import About from "../pages/Auth/Rules/About"
import Quiz from "../pages/Auth/Rules/Quiz"
import ExchangePlaces from "../pages/Admin/ExchangePlaces"
import Create from "../pages/Post/Form/Create"
import Edit from "../pages/Post/Form/Edit"
import SearchPage from "../pages/Post/Search"
import ViewPage from "../pages/Post/View"
import UserPage from "../pages/Admin/Users"
import NewsPage from "../pages/Admin/News"
import SelfPosts from "../pages/Self/Posts"
import StartChat from "../pages/Chat/StartChat"
import Message from "../pages/Chat/Message"
import ContactPage from "../pages/Contact"
import NotationPage from "../pages/User/Notation"
import AdminPosts from "../pages/Admin/Posts"
import ReportsPage from "../pages/Admin/Reports"
import ReasonsPage from "../pages/Admin/Reasons"
import AlertsPage from "../pages/Self/Alerts"
import AdminMarkets from "../pages/Admin/Markets"
import MarketsPostsAdminPage from "../pages/Admin/Markets/Posts"

const routes = (
	<Routes>
		{/* Page: Application home */}
		<Route path="/" element={<Home />} />

		{/* Page: Auth routes */}
		<Route path="/login" element={<Login />} />
		<Route path="/register" element={<Register />} />
		<Route path="/confirm-mail/:token" element={<ConfirmMail />} />
		<Route path="/reset-password/:token" element={<ResetPassword />} />
		<Route path="/forgot-password" element={<ForgotPassword />} />

		{/* Page: Sign-up Quiz */}
		<Route path="/rules" element={<About />} />
		<Route path="/quiz" element={<Quiz />} />

		{/* Page: Search posts */}
		<Route path="/post/search" element={<SearchPage />} />
		<Route path="/post/search/:type" element={<SearchPage />} />

		{/* Page: View post */}
		<Route path="/post/view/:id" element={<ViewPage />} />

		{/* Page: Profile */}
		<Route
			path="/self/profile"
			element={
				<ProtectedRoute>
					<SelfProfile />
				</ProtectedRoute>
			}
		/>
		<Route
			path="/self/posts"
			element={
				<ProtectedRoute roles={[Role.USER, Role.ADMIN]}>
					<SelfPosts />
				</ProtectedRoute>
			}
		/>
		<Route
			path="/self/alerts"
			element={
				<ProtectedRoute roles={[Role.USER, Role.ADMIN]}>
					<AlertsPage />
				</ProtectedRoute>
			}
		/>

		{/* Page: Contact admin */}
		<Route
			path="/contact"
			element={
				<ProtectedRoute roles={[Role.USER]}>
					<ContactPage />
				</ProtectedRoute>
			}
		/>

		{/* Page: Evaluate user */}
		<Route
			path="/user/:id/evaluate/:postId"
			element={
				<ProtectedRoute roles={[Role.USER, Role.ADMIN]}>
					<NotationPage />
				</ProtectedRoute>
			}
		/>

		{/* Page: Create post */}
		<Route
			path="/post/new"
			element={
				<ProtectedRoute>
					<Create />
				</ProtectedRoute>
			}
		/>

		{/* Page: Edit post */}
		<Route
			path="/post/edit/:id"
			element={
				<ProtectedRoute roles={[Role.USER, Role.ADMIN]}>
					<Edit />
				</ProtectedRoute>
			}
		/>

		<Route
			path="/start-chat/:recipientId/post/:postId"
			element={
				<ProtectedRoute>
					<StartChat />
				</ProtectedRoute>
			}
		/>
		<Route
			path="/messages/:chatRoomId"
			element={
				<ProtectedRoute>
					<Message />
				</ProtectedRoute>
			}
		/>
		<Route
			path="/messages"
			element={
				<ProtectedRoute>
					<Message />
				</ProtectedRoute>
			}
		/>
		{/* Page: Not Authorized / 403 error */}
		<Route path="/403" element={<ForbiddenAccess />} />

		{/* Page: Not Found / 404 error */}
		<Route path="*" element={<PageNotFound />} />

		{/* Page: Admin routes */}
		<Route
			path="/admins/places"
			element={
				<ProtectedRoute roles={[Role.ADMIN]}>
					<ExchangePlaces />
				</ProtectedRoute>
			}
		/>
		<Route
			path="/admins/gtu-questions"
			element={
				<ProtectedRoute roles={[Role.ADMIN]}>
					<QuestionPage />
				</ProtectedRoute>
			}
		/>
		<Route
			path="/admins/categories"
			element={
				<ProtectedRoute roles={[Role.ADMIN]}>
					<CategoryPage />
				</ProtectedRoute>
			}
		/>
		<Route
			path="/admins/markets"
			element={
				<ProtectedRoute roles={[Role.ADMIN]}>
					<AdminMarkets />
				</ProtectedRoute>
			}
		/>
		<Route
			path="/admins/markets/:id/posts"
			element={
				<ProtectedRoute roles={[Role.ADMIN]}>
					<MarketsPostsAdminPage />
				</ProtectedRoute>
			}
		/>
		<Route
			path="/admins/posts"
			element={
				<ProtectedRoute roles={[Role.ADMIN]}>
					<AdminPosts />
				</ProtectedRoute>
			}
		/>
		<Route
			path="/admins/news"
			element={
				<ProtectedRoute roles={[Role.ADMIN]}>
					<NewsPage />
				</ProtectedRoute>
			}
		/>
		<Route
			path="/admins/reasons"
			element={
				<ProtectedRoute roles={[Role.ADMIN]}>
					<ReasonsPage />
				</ProtectedRoute>
			}
		/>
		<Route
			path="/admins/reports"
			element={
				<ProtectedRoute roles={[Role.ADMIN]}>
					<ReportsPage />
				</ProtectedRoute>
			}
		/>
		<Route
			path="/admins/users"
			element={
				<ProtectedRoute roles={[Role.ADMIN]}>
					<UserPage />
				</ProtectedRoute>
			}
		/>
	</Routes>
)

export default routes
