import { ITableColumn } from "../../../utils/Constants"

export const SIZE: number = 10
export const PAGE: number = 0

export const columns: ITableColumn[] = [
	{
		Header: "ID",
		accessor: "id",
	},
	{
		Header: "Libellé",
		accessor: "name",
	},
	{
		Header: "Adresse",
		accessor: "address",
	},
	{
		Header: "Actions",
		accessor: "action",
	},
]
