import { useQuery, useQueryClient, UseQueryResult } from "react-query"
import User from "../api/models/User"
import selfService from "../api/services/SelfService"

interface UseGetUserInfoType {
	user: UseQueryResult<User>
}

function useGetUserInfo(): UseGetUserInfoType {
	const queryClient = useQueryClient()

	const user = useQuery<User>("user", () => selfService.getCurrentUser(), {
		onError: () => {
			queryClient.setQueryData(["user"], () => null)
		},
	})

	return { user }
}

export default useGetUserInfo
