import Modal from "../../../components/Dialog/Modal"
import TableInstance from "../../../components/Table/Instance"
import Status from "../../../enums/Status"
import useMultipleStatuses from "../../../hooks/useMultipleStatuses"
import usePaginationSearchSortOnTable from "../../../hooks/usePaginationSearchSortOnTable"
import MultipleStatusSelect from "../../Admin/Posts/MultipleStatusSelect"
import { columns } from "./Constants"
import StatusForm from "./StatusForm"
import useSelfPost from "./hooks/useSelfPost"
import useStatusForm from "./hooks/useStatusForm"

function SelfPosts() {
	const {
		query,
		page,
		size,
		onChangeSize,
		onSearch,
		onChangePage,
		sortBy,
		onChangeSortBy,
	} = usePaginationSearchSortOnTable()

	const { statuses, onChangeStatuses } = useMultipleStatuses([
		Status.TO_VALIDATE,
		Status.ACTIVATED,
		Status.DEACTIVATED,
	])

	const {
		rows,

		posts,
		createPost,
		closeStatusModal,
		selectedPost,
		saveNotation,
	} = useSelfPost(query, size, page, sortBy, statuses)

	const { onSubmit, form, getUserOptions, users, setRating } = useStatusForm(
		saveNotation,
		selectedPost.id
	)
	return (
		<div className="page self-posts">
			<h1 className="text-center pb-3 pt-3">Mes annonces</h1>
			{rows ? (
				<TableInstance
					Filter={
						<MultipleStatusSelect
							statuses={statuses}
							OnChangeStatuses={onChangeStatuses}
						/>
					}
					cols={columns}
					rows={rows}
					openFormDialog={createPost}
					query={{ value: query, setValue: onSearch }}
					manualPagination
					page={page}
					size={size}
					setPage={onChangePage}
					setSize={onChangeSize}
					rowData={posts.data}
					setSortBy={onChangeSortBy}
				/>
			) : (
				""
			)}

			<Modal
				title="Désactiver une annonce"
				isOpen={!!selectedPost.id}
				onClose={closeStatusModal}
			>
				<StatusForm
					onSubmit={onSubmit}
					form={form}
					options={getUserOptions()}
					users={users}
					setRating={setRating}
				/>
			</Modal>
		</div>
	)
}

export default SelfPosts
