import ReactSelectOption from "../../../api/models/ReactSelectOption"
import ReactSelect from "../../../components/Form/Select/ReactSelect"
import Status, { STATUSES_OPTIONS } from "../../../enums/Status"

interface MultipleCheckboxProps {
	statuses: Status[]
	OnChangeStatuses: (newStatuses: ReactSelectOption[]) => void
}

function MultipleStatusSelect({
	OnChangeStatuses,
	statuses,
}: MultipleCheckboxProps) {
	return (
		<ReactSelect
			className="mb-3"
			options={STATUSES_OPTIONS}
			isMulti
			isClearable
			isSearchable
			placeholder="Sélectionner le statut"
			onChange={(selectedOptions: ReactSelectOption[]) =>
				OnChangeStatuses(selectedOptions)
			}
			value={STATUSES_OPTIONS?.filter(option =>
				statuses.includes(option.value as Status)
			)}
		/>
	)
}

export default MultipleStatusSelect
