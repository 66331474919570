import GtuQuestion from "../models/GtuQuestion"
import GeneralService from "./GeneralService"

export class GtuQuestionService extends GeneralService<GtuQuestion | any> {
	findAllQuestions(): Promise<GtuQuestion[]> {
		return this.get("/public")
	}

	checkById(id: number): Promise<number[]> {
		return this.post({}, `/check/${id}`)
	}
}

const gtuQuestionService = new GtuQuestionService("gtu-questions")

export default gtuQuestionService
