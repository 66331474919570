import ChatMessage from "../models/ChatMessage"
import NumberOfUnreadMessage from "../models/NumberOfUnreadMessage"
import GeneralService from "./GeneralService"

export class ChatMessageService extends GeneralService<ChatMessage | any> {
	constructor() {
		super("chat-messages")
		this.spinnerIsActivated = false
	}

	getUnReadMessagesCount(): Promise<NumberOfUnreadMessage> {
		return this.get("/unread-messages-count")
	}
}

const chatRoomService = new ChatMessageService()

export default chatRoomService
