class GtuAnswer {
	id: number = 0

	text: string = ""

	isCorrect?: boolean = false

	questionId: number = 0
}

export default GtuAnswer
