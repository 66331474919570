import { MenuIcon, UserCircleIcon } from "@heroicons/react/outline"
import { useContext, useRef, useState } from "react"
import {
	Link as RouterLink,
	NavLink,
	useMatch,
	useNavigate,
} from "react-router-dom"
import chatMessageService from "../../../api/services/ChatMessageService"
import Logo from "../../../assets/images/Chrysalides.png"

import UserContext from "../../../contexts/UserContext"
import useOutsideClick from "../../../hooks/OutsideClick"
import useSubscription from "../../../hooks/UseSubscription"
import useUserIsConnected from "../../../hooks/UseUserIsConnected"
import { NAV_LINK_ARRAY } from "../../../utils/Constants"
import { showConfirm } from "../../../utils/Popup"
import Button from "../../Button/Button"
import HasRight from "../../Right/HasRight"
import UserPopover from "../../UserPopover/UserPopover"
import NavigationLink from "../Link/NavigationLink"
import Sidebar from "../Sidebar/Sidebar"

interface HeaderProps {
	inNavigator?: boolean
}

function Header({ inNavigator = true }: HeaderProps) {
	const navigate = useNavigate()
	const [openSidebar, setOpenSidebar] = useState(false)

	const [showUserPopover, setShowUserPopover] = useState<boolean>(false)
	const [unReadMessagesCount, setUnReadMessagesCount] = useState<number>(0)
	const { user } = useContext(UserContext)

	const refSidebar: any = useRef()
	const refPopover: any = useRef()

	useOutsideClick(refPopover, () => setShowUserPopover(false))
	useOutsideClick(refSidebar, () => setOpenSidebar(false))
	const onMessageReceived = () => {
		chatMessageService
			.getUnReadMessagesCount()
			.then(res => setUnReadMessagesCount(res.numberOfUnreadMessage))
	}
	// If the url match it's mean that we don't subscribe to the event
	const match = useMatch({ path: "/messages", end: false })
	useUserIsConnected(
		!match
			? () =>
					chatMessageService
						.getUnReadMessagesCount()
						.then(res =>
							setUnReadMessagesCount(res.numberOfUnreadMessage)
						)
			: null
	)

	useSubscription(
		!match ? [`/user/${user?.id}/queue/messages`] : [],
		onMessageReceived
	)

	const getNavLinks = () => {
		const navLinks = NAV_LINK_ARRAY.map(l => (
			<HasRight key={l.url} roles={l.roles}>
				<NavigationLink
					url={l.url}
					Icon={l.Icon}
					text={l.text}
					setOpenSidebar={setOpenSidebar}
					subMenu={l.subMenu}
				/>
			</HasRight>
		))
		return navLinks
	}

	return (
		<>
			<Sidebar
				openMenu={openSidebar}
				links={getNavLinks()}
				refSidebar={refSidebar}
				inNavigator={inNavigator}
				unReadMessagesCount={unReadMessagesCount}
				setOpenSidebar={setOpenSidebar}
			/>
			<header className="header" data-testid="header">
				<RouterLink to="/">
					<img
						src={Logo}
						alt="Logo Les Chrysalides"
						className="header-logo"
					/>
				</RouterLink>
				<div className="header-links">
					{getNavLinks()}

					<Button
						type="button"
						className="bg-primary ml-10"
						onClick={() => {
							if (user === null) {
								showConfirm(
									"Veuillez vous connecter pour pouvoir créer une annonce.",
									"info"
								)
							}
							navigate(user ? "/post/new" : "/login")
						}}
					>
						Créer une annonce
					</Button>
					<p />
					{user ? (
						<div
							ref={refPopover}
							className="header-user chat-message-link"
							onClick={() => setShowUserPopover(!showUserPopover)}
						>
							{user.firstName.substring(0, 1) +
								user.lastName.substring(0, 1)}
							{showUserPopover && (
								<UserPopover
									unReadMessagesCount={unReadMessagesCount}
									setUnReadMessagesCount={
										setUnReadMessagesCount
									}
								/>
							)}
							{unReadMessagesCount > 0 && (
								<span className="chat-message-link__notification">
									{unReadMessagesCount}
								</span>
							)}
						</div>
					) : (
						<NavLink to="/login">
							<UserCircleIcon className="w-6 h-6 mr-1" /> Se
							connecter
						</NavLink>
					)}
				</div>

				<button
					type="button"
					className="header-click-menu"
					data-testid="menu"
					title="Open menu on mobile"
					onClick={() => setOpenSidebar(!openSidebar)}
				>
					<MenuIcon className="h-8 w-8 my-2 mx-2" />
				</button>
			</header>
		</>
	)
}

export default Header
