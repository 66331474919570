import React from "react"
import { useForm } from "react-hook-form"
import Button from "../../../components/Button/Button"
import Input from "../../../components/Form/Input/Input"

interface AlertFormProps {
	saveAlert: (name: string) => any
}

function AlertForm({ saveAlert }: AlertFormProps) {
	const form = useForm<{ name: string }>()

	const onSubmit = (data: { name: string }) => {
		saveAlert(data.name)
	}

	return (
		<form onSubmit={form.handleSubmit(onSubmit)}>
			<Input
				name="name"
				register={form.register}
				required
				type="text"
				error={form.formState.errors.name}
				label="Nom de l'alerte"
			/>

			<Button type="submit" className="bg-primary w-full mt-4">
				Enregistrer
			</Button>
		</form>
	)
}

export default AlertForm
