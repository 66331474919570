import { ReactElement, useEffect, useState } from "react"
import selfService from "../../api/services/SelfService"
import { hasRoles, isAuth } from "../../utils/Rights"

/**
 * @param roles  accepted roles
 * @param children  elements display if the roles are correct
 */
interface HasRightProps {
	children: ReactElement<any, any>
	roles?: string[]
}

function HasRight({ children, roles }: HasRightProps) {
	if ((roles || []).length === 0) {
		return children
	}
	if (isAuth()) {
		const [usersRoles, setUsersRoles] = useState<string[] | null>(null)

		useEffect(() => {
			selfService.getUserRoles().then(res => setUsersRoles(res))
		}, [])

		if (usersRoles && hasRoles(roles || [], usersRoles)) {
			return children
		}
	}
	return null
}

export default HasRight
