import { TrashIcon } from "@heroicons/react/outline"
import { UseQueryResult, useMutation, useQuery } from "react-query"
import { useState } from "react"
import Alert from "../../../../api/models/Alert"
import Page from "../../../../api/models/Page"
import alertService from "../../../../api/services/AlertService"
import { showConfirm, showConfirmAction } from "../../../../utils/Popup"

interface UseAlertsAdminReturn {
	rows: any[]
	alerts: UseQueryResult<Page<Alert>, unknown>
}

export default function useAlertsAdmin(
	query: string,
	size: number,
	page: number,
	sortBy: string
): UseAlertsAdminReturn {
	const [rows, setRows] = useState<any[]>([])

	const alerts = useQuery<Page<Alert>>(
		["alerts", page, size, query, sortBy],
		() =>
			alertService.getAllPaginated({
				page,
				size,
				query: query || undefined,
				sort: sortBy || undefined,
			}),
		{
			onSuccess: (data: Page<Alert>) => {
				setRows(
					data.content.map((a: Alert) => ({
						...a,
						distanceKM: `${a.distance} km`,
						action: (
							<div className="cell-action">
								<span title="Supprimer">
									<TrashIcon
										className="w-6 transition duration-200 text-redCustom  mr-2 cursor-pointer hover:scale-125"
										onClick={() => {
											showConfirmRemoval(a)
										}}
									/>
								</span>
							</div>
						),
					}))
				)
			},
		}
	)

	const deleteAlert = useMutation(
		(alert: Alert) => alertService.delete(alert.id),
		{
			onSuccess: (_data, variables) => {
				alerts.refetch()
				showConfirm(
					`Votre alerte ${variables.name} a bien été désactivé.`
				)
			},
		}
	)

	const showConfirmRemoval = (alert: Alert) => {
		showConfirmAction(() => {
			deleteAlert.mutate(alert)
		})
	}
	return { rows, alerts }
}
