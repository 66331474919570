import { ITableColumn } from "../../../utils/Constants"

export const PAGE: number = 0
export const SIZE: number = 10

export const columns: ITableColumn[] = [
	{
		accessor: "name",
		Header: "Libellé",
	},
	{
		accessor: "type.label",
		Header: "Type",
	},
	{
		accessor: "query",
		Header: "Mots-clés",
	},
	{
		accessor: "address",
		Header: "Adresse",
	},
	{
		accessor: "distanceKM",
		Header: "Distance",
	},
	{
		accessor: "category.name",
		Header: "Catégorie",
	},
	{
		accessor: "subCategory.name",
		Header: "Sous-Catégorie",
	},
	{
		accessor: "action",
		Header: "Action",
	},
]
