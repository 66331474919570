import GtuAnswer from "./GtuAnswer"

class GtuQuestion {
	id!: number

	text!: string

	answers!: Array<GtuAnswer>
}

export default GtuQuestion
