import { useState } from "react"
import { useMutation, useQuery } from "react-query"
import { useNavigate } from "react-router-dom"
import User from "../../api/models/User"
import mailService from "../../api/services/MailService"
import selfService from "../../api/services/SelfService"
import Card from "../../components/Card/Card"
import { showConfirm } from "../../utils/Popup"
import ContactForm from "./ContactForm"
import ContactModel from "./ContactModel"

function ContactPage() {
	const navigate = useNavigate()
	const [model, setModel] = useState<ContactModel>(new ContactModel())
	useQuery("user", () => selfService.getCurrentUser(), {
		onSuccess: (data: User) => {
			setModel({
				name: `${data.firstName} ${data.lastName}`,
				email: data.email,
				message: "",
			})
		},
	})

	const email = useMutation(
		(data: ContactModel) => mailService.post(data, "/send"),
		{
			onSuccess: () => {
				navigate("/")
				showConfirm(
					"Votre message a été envoyé, vous aurez très bientôt une réponse."
				)
			},
			onError: () => {
				showConfirm(
					"Votre message n'a pas pu être envoyé, merci de réessayer plus tard.",
					"error"
				)
			},
		}
	)

	const sendEmail = (data: any) => {
		if (data.name && data.email && data.message) {
			email.mutate(data)
		} else {
			showConfirm("Un message vide ne peut pas être envoyé.", "error")
		}
	}

	return (
		<div className="page contact">
			<h1 className="py-2 text-center">Contactez-nous</h1>
			<h3 className="py-1 text-center">
				Un problème, une question, une remarque ? Remplissez le
				formulaire ci-dessous.
			</h3>
			{model.name && model.email ? (
				<Card className="p-4 my-5 ">
					<ContactForm model={model} sendEmail={sendEmail} />
				</Card>
			) : (
				""
			)}
		</div>
	)
}

export default ContactPage
