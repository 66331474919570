import { useState } from "react"
import Button from "../../Button/Button"
import Modal from "../../Dialog/Modal"
import CGUText from "../../Text/CGUText"

function Footer() {
	const [isOpenModalCGU, setIsOpenModalCGU] = useState<boolean>(false)
	return (
		<footer className="footer" data-testid="footer">
			<p>
				Notre page Facebook :{" "}
				<a
					href="https://www.facebook.com/Les-Chrysalides-Projet-lEffet-Papillon-102462388198058/"
					target="_blank"
					rel="noopener noreferrer"
				>
					Les Chrysalides
				</a>
			</p>
			<p>
				Voir les <a onClick={() => setIsOpenModalCGU(true)}>CGU</a>
			</p>
			<Modal
				isOpen={isOpenModalCGU}
				onClose={() => setIsOpenModalCGU(false)}
				title={"Condition générale d'utilisation"}
				className="h-5/6"
			>
				<>
					<CGUText />
					<Button
						className="button bg-primary mt-1 ml-auto block"
						type="button"
						onClick={() => setIsOpenModalCGU(false)}
					>
						Fermer
					</Button>
				</>
			</Modal>
		</footer>
	)
}

export default Footer
