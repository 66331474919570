import React from "react"
import { useForm } from "react-hook-form"
import Post from "../../../api/models/Post"
import Button from "../../../components/Button/Button"
import Select from "../../../components/Form/Select/Select"
import Status from "../../../enums/Status"
import { statusOptions } from "./Constants"
import ToggleStatusModel from "../../../api/models/ToggleStatusModel"

interface UpdateStatusFormProps {
	post: Post
	setPost: (newPost: Post) => any
	updateStatus: (data: ToggleStatusModel) => any
}

function UpdateStatusForm({
	post,
	setPost,
	updateStatus,
}: UpdateStatusFormProps) {
	const form = useForm<{ status: Status }>({
		defaultValues: {
			status: post.status,
		},
	})

	return (
		<form
			onSubmit={form.handleSubmit((data: { status: Status }) =>
				updateStatus({
					status: data.status,
					id: post.id,
					askedInMarket: post.askedInMarket,
					market: post.market,
				})
			)}
			className="update-status-form"
		>
			<Select
				name="status"
				register={form.register}
				error={form.formState.errors.status}
				label="Nouveau statut"
				required
				options={statusOptions}
				value={post.status}
				onChange={(evt: { target: { value: Status } }) =>
					setPost({
						...post,
						status: evt.target.value,
					})
				}
			/>

			<Button type="submit" className="bg-primary w-full my-4">
				Soumettre
			</Button>
		</form>
	)
}

export default UpdateStatusForm
