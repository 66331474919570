import { SpeakerphoneIcon } from "@heroicons/react/outline"
import { StarIcon } from "@heroicons/react/solid"
import dayjs from "dayjs"
import { ReactNode } from "react"
import { useNavigate } from "react-router-dom"
import Post from "../../../api/models/Post"
import User from "../../../api/models/User"
import Button from "../../../components/Button/Button"
import Card from "../../../components/Card/Card"
import Status from "../../../enums/Status"
import { showConfirm } from "../../../utils/Popup"
import Map from "./Map"
import getGeolocationPositionOfPost from "./utils/getGeolocationPositionOfPost"
import Carousel from "../../../components/Carousel/Carousel"

interface SinglePostProps {
	post: Post
	user: User | undefined
	ReportModal: ReactNode
	openReportModal(): void
}

function SinglePost({
	post,
	user,
	openReportModal,
	ReportModal,
}: SinglePostProps) {
	const navigate = useNavigate()
	return (
		<Card className="p-6 my-4">
			<div className="post-medias">
				<div className="post-picture">
					<Carousel photos={post.photos} />
				</div>
				<div className="post-aside">
					<div className="flex flex-row w-full my-auto items-center sm:my-2">
						<h1 className="break-words basis-5/6">{post.title}</h1>
						{user &&
						post.user &&
						post.status !== Status.ARCHIVED &&
						post.user.id !== user.id ? (
							<Button
								type="button"
								className="bg-redCustom font-bold basis-1/6 flex flex-row justify-center"
								onClick={openReportModal}
							>
								<>
									<SpeakerphoneIcon className="w-6 mr-2" />
									Signaler
								</>
							</Button>
						) : (
							post.status === Status.ARCHIVED && (
								<span className="text-redCustom font-bold text-xl">
									Annonce archivée
								</span>
							)
						)}
					</div>
					<div className="post-meta">
						<p>
							<b>Référence de l'annonce :</b> {post.id}
						</p>
						<p>
							<b>Lieu : </b>
							{post.exchangePlaces &&
							post.exchangePlaces?.length > 0
								? post.exchangePlaces?.map(exchange => (
										<span
											className="exchange-item"
											key={exchange.id}
										>
											{`${exchange.name} (${exchange.address})`}
										</span>
								  ))
								: post.address}
						</p>
						<p>
							<b>Catégorie :</b> {post.category.name} (
							{post.subCategory.name})
						</p>

						<p>
							<b>Où récupérer l'objet :</b>{" "}
							{post.exchangePlaces &&
							post.exchangePlaces.length > 0
								? post.exchangePlaces?.map(exchange => (
										<span
											className="exchange-item"
											key={exchange.id}
										>
											{exchange.name}
										</span>
								  ))
								: "Contacter le donneur"}
						</p>
						<p>
							<b>Créé le :</b>{" "}
							{dayjs(post.createdAt).format("DD MMMM YYYY")}
						</p>
					</div>
					<p className="post-desc">{post.description}</p>
					{post.user ? (
						<Button
							type="button"
							className="bg-primary flex flex-row justify-center"
							onClick={() => {
								if (user === null || !user?.id) {
									showConfirm(
										"Veuillez vous connecter pour contacter cet utilisateur.",
										"info"
									)
								}
								navigate(
									user
										? `/start-chat/${post.user?.id}/post/${post.id}`
										: "/login"
								)
							}}
						>
							<p className="font-bold">
								Contacter {post.user.firstName}{" "}
								{post.user.lastName}
							</p>
							{post.user.note && post.user.noteCount ? (
								<div className="ml-2 flex flex-row">
									<StarIcon className="w-6 fill-yellowStar" />
									<p className="font-bold">
										{Math.round(post.user.note * 10) / 10}/5
										({post.user.noteCount} avis)
									</p>
								</div>
							) : (
								""
							)}
						</Button>
					) : (
						""
					)}

					{ReportModal}
				</div>
			</div>
			<div className="post-map">
				<Map position={getGeolocationPositionOfPost(post)} />
			</div>
		</Card>
	)
}

export default SinglePost
