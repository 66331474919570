import Gift from "../../assets/images/Gift-rafiki.svg"
import Help from "../../assets/images/Help-rafiki.svg"

export interface IPrimaryButtons {
	url: string
	text: string
	title: string
	imgUrl: any
}

export const PRIMARY_ACTIONS: IPrimaryButtons[] = [
	{
		url: "/post/search/gifts",
		title: "Consulter les dons ➢",
		text: "Chaque membre devient acteur, et ce pour le bon d&eacute;roulement du r&eacute;seau et une agr&eacute;able harmonie.<p>&Ecirc;tre un membre de effetdon, c&rsquo;est :<ul><li>➡️ Faire partie de la communaut&eacute; des Papillons.</li><li>➡️ Respecter avec confiance et bienveillance.</li><li>➡️ S&rsquo;int&eacute;resser au monde qui nous entoure.</li></ul>",
		imgUrl: Gift,
	},
	{
		url: "/post/search/requests",
		title: "Consulter les demandes ➢",
		text: "Emprunter ou récupérer au lieu d’acheter permet de faire des économies, il s’agit également de susciter de belles rencontres riches et passionnantes. En route, pour vivre et consommer différemment.",
		imgUrl: Help,
	},
]

export const newsId: number = 1
