import { useContext, useState } from "react"
import User from "../../../../../api/models/User"
import UserContext from "../../../../../contexts/UserContext"

interface UseGenericFormType {
	selectedIndex: number
	setSelectedIndex(selectedIndex: number): void
	connectedUser: User
}
function useGenericForm(): UseGenericFormType {
	const [selectedIndex, setSelectedIndex] = useState<number>(0)

	const { user } = useContext(UserContext)
	const connectedUser = user

	return { setSelectedIndex, selectedIndex, connectedUser }
}
export default useGenericForm
