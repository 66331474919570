import { useEffect, useState } from "react"
import { UseQueryResult, useQuery, useQueryClient } from "react-query"
import { useNavigate } from "react-router-dom"
import ChatRoom from "../../../../api/models/ChatRoom"
import selfService from "../../../../api/services/SelfService"

interface UseChatRoomReturn {
	chatRoomsApi: UseQueryResult<ChatRoom[], unknown>
	selectedChatRoom: ChatRoom | null
	onChatRoomClick: (chatRoom: ChatRoom) => void
}

export default function useChatRoom(chatRoomId: string): UseChatRoomReturn {
	const navigate = useNavigate()
	const queryClient = useQueryClient()

	const [selectedChatRoom, setSelectedChatRoom] = useState<ChatRoom | null>(
		null
	)

	const chatRoomsApi = useQuery<Array<ChatRoom>>("chat-rooms", () =>
		selfService.getUserChatRooms()
	)

	useEffect(() => {
		if (chatRoomsApi.isSuccess && chatRoomId) {
			queryClient.setQueryData(["chat-rooms"], (old: any) => {
				const lOld = [...old]
				lOld.forEach((chatRoom: ChatRoom) => {
					if (chatRoom.id === +chatRoomId) {
						chatRoom.unReadMessageCount = 0
						setSelectedChatRoom(chatRoom)
					}
				})
				return lOld
			})
		} else {
			setSelectedChatRoom(null)
		}
	}, [chatRoomId, chatRoomsApi.isSuccess])

	const onChatRoomClick = (chatRoom: ChatRoom) => {
		navigate(`/messages/${chatRoom.id}`)
	}

	return { chatRoomsApi, selectedChatRoom, onChatRoomClick }
}
