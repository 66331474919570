import { useQuery, UseQueryResult } from "react-query"
import ExchangePlace from "../../../../../api/models/ExchangePlace"
import exchangePlaceService from "../../../../../api/services/ExchangePlaceService"

interface UseGetExchangePlaceQueryReturn {
	places: UseQueryResult<ExchangePlace[]>
}
function useGetExchangePlaceQuery(): UseGetExchangePlaceQueryReturn {
	const places = useQuery<ExchangePlace[]>("places", () =>
		exchangePlaceService.getAllWithFilters(undefined, "/unpaged")
	)

	return { places }
}

export default useGetExchangePlaceQuery
