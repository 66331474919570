import Compressor from "compressorjs"
import { UseFormReturn } from "react-hook-form"
import { postImageMaxFilesize } from "../../../../utils/Constants"
import { showConfirm } from "../../../../utils/Popup"

const getFileBase64 = async (
	file: File,
	form: UseFormReturn<any>,
	inInPost: boolean = false
): Promise<number> => {
	if (!file.type.match(/image\/[a-z]+/d)) {
		showConfirm("Forme de fichier incorrect", "error")
		return 1
	}

	if (form.getValues("photos")?.length === 3) {
		showConfirm("Impossible de télécharger plus de 3 photos.", "error")
		return 1
	}

	if (octetToMo(file.size) > postImageMaxFilesize) {
		const compressedFile = await compressFile(file)
		if (octetToMo(compressedFile.size) > postImageMaxFilesize) {
			showConfirm(
				`Fichier trop lourd (max. ${postImageMaxFilesize} Mo). Le ficher de base pèse ${octetToMo(
					file.size
				)} Mo et ${octetToMo(
					compressedFile.size
				)} Mo après compression.`,
				"error",
				5000
			)
			return 1
		}
		file = compressedFile
	}

	const reader = new FileReader()
	reader.onload = (ev: any) => {
		if (inInPost) {
			const photos = form.getValues("photos") ?? []
			photos.push({
				id: null,
				image: ev.target.result,
				postId: form.getValues("id") ?? null,
			})
			form.setValue("photos", photos)
		} else {
			form.setValue("image", ev.target.result)
		}
	}
	reader.readAsDataURL(file)
	return 0
}

function octetToMo(sizeInOctet: number): number {
	return Math.round((sizeInOctet / 1024 / 1024) * 100) / 100
}

function compressFile(file: File) {
	return new Promise<File>((resolve, reject) => {
		// eslint-disable-next-line no-new
		new Compressor(file, {
			quality: 0.8,
			success(result) {
				resolve(result as File)
			},
			error(e) {
				reject(e)
			},
		})
	})
}
export default getFileBase64
