import {
	CheckIcon,
	PencilIcon,
	TrashIcon,
	XIcon,
} from "@heroicons/react/outline"

interface FormActionProps {
	setEdit: (value: boolean) => void
	setAdd: (value: boolean) => void
	isEditing: boolean
	index: number
	onDelete: any
	canBeDeleted?: boolean
}

function FormAction({
	setEdit,
	setAdd,
	isEditing,
	index,
	onDelete,
	canBeDeleted = true,
}: FormActionProps) {
	return (
		<div className="flex ml-auto mr-3" data-testid="action">
			{isEditing ? (
				<button type="submit" title="Confirmer">
					<CheckIcon
						className="w-6  transition duration-200 text-green mr-2 cursor-pointer hover:scale-125"
						data-testid="confirm"
					/>
				</button>
			) : (
				<span title="Editer">
					<PencilIcon
						className="w-5 transition duration-200 text-primary mr-2 cursor-pointer hover:scale-125"
						data-testid="edit"
						onClick={() => setEdit(true)}
					/>
				</span>
			)}
			{isEditing ? (
				<span title="Annuler">
					<XIcon
						className="w-6  transition duration-200 cursor-pointer text-primaryLight hover:scale-125"
						data-testid="cancel"
						onClick={() =>
							index === 0 ? setAdd(false) : setEdit(false)
						}
					/>
				</span>
			) : (
				<span title="Supprimer">
					<TrashIcon
						className={`w-5 transition duration-200 text-redCustom  mr-2 cursor-pointer hover:scale-125 ${
							canBeDeleted ? "" : "disabled"
						}`}
						data-testid="delete"
						onClick={canBeDeleted ? onDelete : undefined}
					/>
				</span>
			)}
		</div>
	)
}

export default FormAction
