import { StarIcon } from "@heroicons/react/solid"
import React, { useState } from "react"

interface NotationFieldProps {
	setNote: any
}

function NotationField({ setNote }: NotationFieldProps) {
	const [rating, setRating] = useState<number>(0)
	const [hover, setHover] = useState<number>(0)

	const handleClick = (index: number) => {
		setRating(index)
		setNote(index)
	}

	return (
		<div className="flex flex-row justify-center" data-testid="notation">
			{[...Array(5)].map((star, index) => {
				index += 1
				return (
					<StarIcon
						key={index}
						className={`w-16 cursor-pointer ${
							index <= (hover || rating)
								? "fill-yellowStar"
								: "fill-gray"
						} drop-shadow-sm`}
						onClick={() => handleClick(index)}
						onMouseEnter={() => setHover(index)}
						onMouseLeave={() => setHover(rating)}
					/>
				)
			})}
		</div>
	)
}

export default NotationField
