import { UseFormReturn } from "react-hook-form"
import StartChatUser from "../../../api/models/StartChatUser"
import Button from "../../../components/Button/Button"
import Card from "../../../components/Card/Card"
import ChatMessage from "../../../api/models/ChatMessage"

interface StartChatFormProps {
	startChatUserInfoData: StartChatUser
	messageForm: UseFormReturn<ChatMessage>
	onMessageSubmit: (chatMessage: ChatMessage) => void
}

export default function StartChatForm({
	startChatUserInfoData,
	messageForm,
	onMessageSubmit,
}: StartChatFormProps) {
	return (
		<Card className="p-2">
			<form onSubmit={messageForm.handleSubmit(onMessageSubmit)}>
				{startChatUserInfoData && (
					<h3 className="py-2">
						Envoyer un message à
						{` ${startChatUserInfoData.firstName} ${startChatUserInfoData.lastName}`}
					</h3>
				)}

				<textarea
					className="w-full"
					placeholder="Bonjour, cette annonce est-elle toujours disponible?"
					{...messageForm.register("message", {
						required: true,
					})}
					required
				/>
				<Button type="submit" className="bg-primary w-full ">
					Envoyer
				</Button>
			</form>
		</Card>
	)
}
