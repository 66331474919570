import { ITableColumn } from "../../../utils/Constants"

export const SIZE: number = 10
export const PAGE: number = 0

export const columns: ITableColumn[] = [
	{
		accessor: "label",
		Header: "Intitulé",
	},
	{
		accessor: "actions",
		Header: "Actions",
	},
]
