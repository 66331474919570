import React from "react"

interface CheckBoxProps {
	label: string
	name?: string
	register?: any
	checked: boolean
	onChange: any
	className?: string
	labelClassName?: string
	disabled?: boolean
	required?: boolean
}

function Checkbox({
	label,
	name,
	register,
	disabled = false,
	checked = false,
	className,
	labelClassName,
	onChange,
	required = false,
}: CheckBoxProps) {
	return (
		<div className="flex">
			<input
				{...(register(name), { required })}
				type="checkbox"
				data-testid="checkbox"
				checked={checked}
				name={name}
				id={name}
				className={`${className ?? ""} checkbox text-primary`}
				onChange={onChange}
				disabled={disabled}
			/>
			<label
				htmlFor={name}
				data-testid="label"
				className={`pl-2 pr-2 text-sm font-medium ${
					disabled ? "text-gray" : ""
				} ${labelClassName ?? ""}`}
			>
				{label}
			</label>
		</div>
	)
}

export default Checkbox
