import {
	ArchiveIcon,
	CogIcon,
	FilterIcon,
	GiftIcon,
	InboxIcon,
	LocationMarkerIcon,
	MailIcon,
	NewspaperIcon,
	QuestionMarkCircleIcon,
	ShieldExclamationIcon,
	ShoppingBagIcon,
	SupportIcon,
	UsersIcon,
} from "@heroicons/react/outline"
import ReactSelectOption from "../api/models/ReactSelectOption"
import RoleModel from "../api/models/RoleModel"
import Gift from "../assets/images/Gift-rafiki.svg"
import Help from "../assets/images/Help-rafiki.svg"
import Role from "../enums/Role"

export interface INavLink {
	text: string
	Icon: any
	url: string
	roles?: string[]
	onClick?: any
	subMenu?: ISubMenuItem[]
}

export interface ISubMenuItem {
	text: string
	url: string
	Icon: any
}

export interface ITableColumn {
	Header: string
	accessor: string
}

export interface IPostType {
	id: number
	label: string
	name: string
	Image: any
}

export const NAV_LINK_ARRAY: INavLink[] = [
	{
		url: "/admin",
		text: "Administration",
		Icon: CogIcon,
		roles: [Role.ADMIN],
		subMenu: [
			{
				text: "Lieux d'échanges",
				url: "/admins/places",
				Icon: LocationMarkerIcon,
			},
			{
				text: "CGU",
				url: "/admins/gtu-questions",
				Icon: QuestionMarkCircleIcon,
			},
			{
				text: "Catégories",
				url: "/admins/categories",
				Icon: FilterIcon,
			},
			{
				text: "Marchés",
				url: "/admins/markets",
				Icon: ShoppingBagIcon,
			},
			{
				text: "Annonces",
				url: "/admins/posts",
				Icon: ArchiveIcon,
			},
			{
				text: "Raisons",
				url: "/admins/reasons",
				Icon: ShieldExclamationIcon,
			},
			{
				text: "Signalements",
				url: "/admins/reports",
				Icon: SupportIcon,
			},
			{
				text: "Actualités",
				url: "/admins/news",
				Icon: NewspaperIcon,
			},
			{
				text: "Utilisateurs",
				url: "/admins/users",
				Icon: UsersIcon,
			},
		],
	},
	{
		url: "/post/search/gifts",
		text: "Dons",

		Icon: GiftIcon,
	},
	{
		url: "/post/search/requests",
		text: "Demandes",

		Icon: InboxIcon,
	},
	{
		url: "/contact",
		text: "Contact",
		roles: [Role.USER],
		Icon: MailIcon,
	},
]

export const POST_TYPES: IPostType[] = [
	{
		id: 1,
		label: "Dons",
		name: "gifts",
		Image: Gift,
	},
	{
		id: 2,
		label: "Demandes",
		name: "requests",
		Image: Help,
	},
]

export const GENDER: ReactSelectOption[] = [
	{ label: "M.", value: "M." },
	{ label: "Mme.", value: "Mme." },
	{ label: "Autre", value: "Autre" },
]

export const postImageMaxFilesize: number = 5
