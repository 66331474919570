import { useState } from "react"
import { useMutation, UseMutationResult } from "react-query"
import Post from "../api/models/Post"
import Report from "../api/models/Report"
import User from "../api/models/User"
import reportService from "../api/services/ReportService"
import { showConfirm } from "../utils/Popup"
import ChatRoomPost from "../api/models/ChatRoomPost"

interface UseReportFormReturn {
	openReportModal(): void
	reporting: boolean
	closeReportModal(): void
	postReport: UseMutationResult<Report, unknown, void>
	setReport: (newReport: Report) => any
	report: Report
}

function useReportForm(
	post?: Post | ChatRoomPost,
	user?: User
): UseReportFormReturn {
	const [report, setReport] = useState<Report>(new Report())
	const [reporting, setReporting] = useState<boolean>(false)

	const postReport = useMutation(
		() =>
			reportService.post({
				...report,
				author: {
					id: user?.id || 0,
					gender: user?.gender || "",
					firstName: user?.firstName || "",
					lastName: user?.lastName || "",
					roles: user?.roles || [],
				},
				post,
			}),
		{
			onSuccess: () => {
				showConfirm("Votre signalement a bien été pris en compte.")
				setReporting(false)
			},
		}
	)

	const openReportModal = () => {
		setReporting(true)
	}

	const closeReportModal = () => {
		setReporting(false)
	}

	return {
		openReportModal,
		reporting,
		closeReportModal,
		postReport,
		setReport,
		report,
	}
}

export default useReportForm
