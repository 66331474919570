import { useContext } from "react"
import { UseFormReturn, useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import ChatMessage from "../../../../api/models/ChatMessage"
import UserContext from "../../../../contexts/UserContext"
import useStompClient from "../../../../hooks/UseStompClient"
import { showConfirm } from "../../../../utils/Popup"

interface UseStartChatFormReturn {
	messageForm: UseFormReturn<ChatMessage>
	onMessageSubmit: (chatMessage: ChatMessage) => void
}

export default function useStartChatForm(
	firstName: string | undefined
): UseStartChatFormReturn {
	const messageForm = useForm<ChatMessage>()
	// Current user
	const { user } = useContext(UserContext)

	const { recipientId, postId } = useParams()
	const navigate = useNavigate()
	const stompClient = useStompClient()

	const onMessageSubmit = (chatMessage: ChatMessage) => {
		if (chatMessage.message.trim() !== "") {
			chatMessage.senderId = user.id
			chatMessage.recipientId = recipientId ? +recipientId : 0
			chatMessage.postId = postId ? +postId : 0
			stompClient?.publish({
				destination: "/app/chat-message",
				body: JSON.stringify(chatMessage),
			})
			showConfirm(
				`Votre message a bien été envoyé ! ${firstName}, vous recontactera bientôt`
			)
			navigate("/")
		}
	}

	return { onMessageSubmit, messageForm }
}
