import { useContext, useRef } from "react"
import { UseFormReturn, useForm } from "react-hook-form"
import {
	UseInfiniteQueryResult,
	useInfiniteQuery,
	useQueryClient,
} from "react-query"
import ChatMessage from "../../../../api/models/ChatMessage"
import ChatNotification from "../../../../api/models/ChatNotification"
import ChatRoom from "../../../../api/models/ChatRoom"
import Page from "../../../../api/models/Page"
import chatMessageService from "../../../../api/services/ChatMessageService"
import chatRoomService from "../../../../api/services/ChatRoomService"
import UserContext from "../../../../contexts/UserContext"
import useStompClient from "../../../../hooks/UseStompClient"
import useSubscription from "../../../../hooks/UseSubscription"
import { PAGE, SIZE } from "../Constants"

interface useChatMessageReturn {
	messageForm: UseFormReturn<ChatMessage>
	onMessageSubmit: (chatMessage: ChatMessage) => void
	canFetch: React.MutableRefObject<boolean>
	handleMessageScroll(e: any): void
	chatMessageApi: UseInfiniteQueryResult<Page<ChatMessage>>
}

export default function useChatMessage(
	selectedChatRoom: ChatRoom | null
): useChatMessageReturn {
	const messageForm = useForm<ChatMessage>()

	const canFetch = useRef(true)

	const queryClient = useQueryClient()
	const stompClient = useStompClient()
	// Current user
	const { user } = useContext(UserContext)
	/**
	 * add the new messages to existing one (update the ui)
	 * @param chatMessage
	 */
	const addNewMessagesToList = (chatMessage: ChatMessage) => {
		messageForm.reset()
		queryClient.setQueryData(
			["messages", selectedChatRoom?.id],
			(old: any) => {
				const lOld = Object.assign(old)
				lOld?.pages[0]?.content.unshift(chatMessage)
				return lOld
			}
		)
	}

	const onMessageSubmit = (chatMessage: ChatMessage) => {
		if (chatMessage.message.trim() !== "" && selectedChatRoom !== null) {
			chatMessage.senderId = user.id
			chatMessage.recipientId = selectedChatRoom.recipient.id
			chatMessage.postId = selectedChatRoom.post?.id || 0
			stompClient?.publish({
				destination: "/app/chat-message",
				body: JSON.stringify(chatMessage),
			})
			addNewMessagesToList(chatMessage)
		}
	}

	const onMessageReceived = (message: any) => {
		const notification: ChatNotification = JSON.parse(message.body)
		if (notification.chatRoomId === selectedChatRoom?.id) {
			chatMessageService
				.getById(notification.messageId)
				.then(response => {
					addNewMessagesToList(response)
				})
		} else {
			queryClient.setQueryData(["chat-rooms"], (old: any) => {
				const lOld = [...old]
				lOld.forEach((chatRoom: ChatRoom) => {
					if (chatRoom.id === notification.chatRoomId) {
						chatRoom.unReadMessageCount =
							notification.unReadCountByChatRoom
					}
				})
				return lOld
			})
		}
	}

	const chatMessageApi = useInfiniteQuery<Page<ChatMessage>>(
		["messages", selectedChatRoom?.id],
		({ pageParam = PAGE }) =>
			chatRoomService.getChatMessages(
				{ page: pageParam, size: SIZE },
				selectedChatRoom?.id || 0
			),
		{
			getNextPageParam: data => {
				if (data.number < data.totalPages - 1) {
					return data.number + 1
				}
				return false
			},
			enabled: selectedChatRoom?.id !== undefined,
		}
	)

	function handleMessageScroll(e: any): void {
		const bottom =
			e.target.scrollHeight + e.target.scrollTop === e.target.clientHeight

		if (bottom && canFetch.current && chatMessageApi.hasNextPage) {
			canFetch.current = false
			chatMessageApi.fetchNextPage()
			// to avoid fetching to quickly
			setTimeout(() => (canFetch.current = true), 200)
		}
	}

	useSubscription(`/user/${user.id}/queue/messages`, onMessageReceived)

	return {
		onMessageSubmit,
		messageForm,
		canFetch,
		handleMessageScroll,
		chatMessageApi,
	}
}
