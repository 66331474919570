const MODULES = {
	toolbar: [
		[{ header: [1, 2, 3, 7] }, { font: [] }],
		["bold", "italic", "underline"],
		[{ align: [] }],
		[{ list: "ordered" }, { list: "bullet" }],
		["link", "image"],
		["clean"],
	],
}

const FORMATS = [
	"header",
	"font",
	"size",
	"bold",
	"italic",
	"underline",
	"align",
	"direction",
	"list",
	"bullet",
	"link",
	"image",
]

export { MODULES, FORMATS }
