import React, { createRef, useState } from "react"
import Photo from "../../../api/models/Photo"
import Button from "../../Button/Button"

function useCarousel(photos: Photo[]) {
	const [currentImage, setCurrentImage] = useState<number>(0)

	const refs = photos.reduce((acc: any, _val, i) => {
		acc[i] = createRef()
		return acc
	}, {})

	const scrollToImage = (i: number) => {
		setCurrentImage(i)
		refs[i].current?.scrollIntoView({
			behavior: "smooth",
			block: "nearest",
			inline: "start",
		})
	}

	const nextImage = () => {
		if (currentImage >= photos.length - 1) {
			scrollToImage(0)
		} else {
			scrollToImage(currentImage + 1)
		}
	}

	const previousImage = () => {
		if (currentImage === 0) {
			scrollToImage(photos.length - 1)
		} else {
			scrollToImage(currentImage - 1)
		}
	}

	const arrowStyle =
		"text-white text-2xl z-10 bg-secondary mt-2 h-10 w-10 rounded-full opacity-75 flex items-center justify-center"

	const sliderControl = (isLeft: boolean = false) => (
		<Button
			type="button"
			onClick={isLeft ? previousImage : nextImage}
			className={`${arrowStyle} ${isLeft ? "left-2" : "right-2"}`}
		>
			<span role="img" aria-label={`Arrow ${isLeft ? "left" : "right"}`}>
				{isLeft ? "◀" : "▶"}
			</span>
		</Button>
	)

	return { currentImage, sliderControl }
}

export default useCarousel
