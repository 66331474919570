import { useMutation, UseMutationResult, useQueryClient } from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import Post from "../../../../../api/models/Post"
import postService from "../../../../../api/services/PostService"
import { showConfirm } from "../../../../../utils/Popup"

interface UseUpdatePostQueryType {
	updatePostQuery: UseMutationResult<Post, unknown, Post>
}

function useUpdatePostQuery(): UseUpdatePostQueryType {
	// Get QueryClient from the context
	const queryClient = useQueryClient()

	const params = useParams()
	const navigate = useNavigate()
	const updatePostQuery = useMutation(
		(data: Post) => postService.put(data, Number(params.id)),
		{
			onSuccess: () => {
				showConfirm(
					"Les modifications apportées à votre annonce ont bien été sauvegardées.",
					"success",
					3000
				)
				queryClient.invalidateQueries({ queryKey: ["post", params.id] })
				navigate("/")
			},
		}
	)

	return { updatePostQuery }
}

export default useUpdatePostQuery
