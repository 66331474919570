import { PencilIcon, TrashIcon } from "@heroicons/react/outline"
import { useState } from "react"
import { UseQueryResult, useMutation, useQuery } from "react-query"
import ExchangePlace from "../../../../api/models/ExchangePlace"
import Page from "../../../../api/models/Page"
import exchangePlaceService from "../../../../api/services/ExchangePlaceService"
import { showConfirm, showConfirmAction } from "../../../../utils/Popup"

interface UseExchangePlacesAdminReturn {
	rows: any[]
	openPlaceDialog: (item?: ExchangePlace) => void
	exchangePlaces: UseQueryResult<Page<ExchangePlace>>
	place: ExchangePlace
	isPlaceDialogOpen: boolean
	closePlaceDialog: () => void
	onSubmit: (item: ExchangePlace) => void
}

function useExchangePlacesAdmin(
	query: string,
	size: number,
	page: number,
	sortBy: string
): UseExchangePlacesAdminReturn {
	const [rows, setRows] = useState<any[]>([])
	const [place, setPlace] = useState<ExchangePlace>(new ExchangePlace())
	const [isPlaceDialogOpen, setIsPlaceDialogOpen] = useState<boolean>(false)

	const exchangePlaces = useQuery<Page<ExchangePlace>>(
		["exchangesPlaces", page, size, query, sortBy],
		() =>
			exchangePlaceService.getAllPaginated({
				page,
				size,
				query: query || undefined,
				sort: sortBy || undefined,
			}),
		{
			retry: false,
			onSuccess: (data: Page<ExchangePlace>) => {
				setRows(
					data.content.map((x: ExchangePlace) => ({
						...x,
						action: (
							<div className="cell-action">
								<span title="Editer">
									<PencilIcon
										className="w-6 transition duration-200 text-primary mr-2 cursor-pointer hover:scale-125"
										onClick={() => {
											openPlaceDialog(x)
										}}
									/>
								</span>

								<span title="Supprimer">
									<TrashIcon
										className={`w-6 transition duration-200 text-redCustom  mr-2 cursor-pointer hover:scale-125 ${
											x.numbersOfPosts <= 0
												? ""
												: "disabled"
										}`}
										onClick={
											x.numbersOfPosts === 0
												? () => showConfirmRemoval(x)
												: undefined
										}
									/>
								</span>
							</div>
						),
					}))
				)
			},
		}
	)

	const postPlace = useMutation(
		(toCreate: ExchangePlace) => exchangePlaceService.post(toCreate),
		{
			onSuccess: () => {
				showConfirm("Le lieu d'échange a bien été créé.")
				closePlaceDialog()
				exchangePlaces.refetch()
			},
		}
	)

	const putPlace = useMutation(
		(toUpdate: ExchangePlace) =>
			exchangePlaceService.put(toUpdate, toUpdate.id),
		{
			onSuccess: () => {
				showConfirm("Le lieu d'échange a bien été sauvegardé.")
				closePlaceDialog()
				exchangePlaces.refetch()
			},
		}
	)

	const deletePlace = useMutation(
		(toDelete: ExchangePlace) => exchangePlaceService.delete(toDelete.id),
		{
			onSuccess: () => {
				showConfirm("Le lieu d'échange a bien été supprimé.")
				exchangePlaces.refetch()
			},
		}
	)

	const saveItem = (item: ExchangePlace) => {
		if (item.id) {
			putPlace.mutate(item)
		} else {
			postPlace.mutate(item)
		}
	}

	const onSubmit = (placeToSave: ExchangePlace) => {
		if (placeToSave.latitude && placeToSave.longitude) {
			saveItem(placeToSave)
		} else {
			showConfirm("Vous devez sélectionner une adresse.", "error")
		}
	}

	const removeItem = (item: ExchangePlace) => {
		deletePlace.mutate(item)
	}

	const openPlaceDialog = (item: ExchangePlace = new ExchangePlace()) => {
		setPlace(item)
		setIsPlaceDialogOpen(true)
	}

	const closePlaceDialog = () => {
		setIsPlaceDialogOpen(false)
		setPlace(new ExchangePlace())
	}

	const showConfirmRemoval = (item: ExchangePlace) => {
		showConfirmAction(() => {
			removeItem(item)
		})
	}

	return {
		rows,
		openPlaceDialog,
		exchangePlaces,
		place,
		isPlaceDialogOpen,
		closePlaceDialog,
		onSubmit,
	}
}

export default useExchangePlacesAdmin
