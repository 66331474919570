import { ITableColumn } from "../../../utils/Constants"

export const SIZE: number = 10
export const PAGE: number = 0

export const columns: ITableColumn[] = [
	{
		Header: "Annonce signalée",
		accessor: "post.id",
	},
	{
		Header: "Titre de l'annonce",
		accessor: "post.title",
	},
	{
		Header: "Utilisateur signalé",
		accessor: "user.firstName",
	},
	{
		Header: "Raison",
		accessor: "reason.label",
	},
	{
		Header: "Auteur",
		accessor: "author.firstName",
	},
	{
		Header: "Créé le",
		accessor: "createdAt",
	},
	{
		Header: "Actions",
		accessor: "actions",
	},
]
