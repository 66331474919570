import ChatRoom from "../models/ChatRoom"
import SimpleUser from "../models/SimpleUser"
import UpdatePassword from "../models/UpdatePassword"
import User from "../models/User"
import GeneralService from "./GeneralService"

export class SelfService extends GeneralService<User | any> {
	protected roles: string[] = []

	getCurrentUser(): Promise<User> {
		return this.get("")
	}

	getUserChatRooms(): Promise<Array<ChatRoom>> {
		return this.get("/chat-rooms")
	}

	getPotentialRecipients(postId: number): Promise<Array<SimpleUser>> {
		return this.get(`/posts/${postId}/chat-rooms/users`)
	}

	getUserRoles(): Promise<string[]> {
		if (this.roles.length > 0) {
			return new Promise(resolve => {
				resolve(this.roles)
			})
		}

		return this.get("/roles")
			.then(res => {
				this.roles = res
				return res
			})
			.catch(this.handleError)
	}

	patchProfile(user: User) {
		return this.http
			.patch(this.url, user)
			.then(this.handleResponse)
			.catch(this.handleError)
	}

	patchPassword(updatePassword: UpdatePassword) {
		return this.http
			.patch(`${this.url}/password`, updatePassword)
			.then(this.handleResponse)
			.catch(this.handleError)
	}

	setRoles(roles: string[]): void {
		this.roles = roles
	}
}

const selfService = new SelfService("self")

export default selfService
