import { Link } from "react-router-dom"
import Forbidden from "../../../assets/images/Forbidden-rafiki.svg"

function ForbiddenAccess() {
	return (
		<section className="page flex flex-col w-full text-center">
			<img
				className="mx-auto mt-auto mb-0 h-3/4"
				src={Forbidden}
				alt="Forbidden"
			/>
			<div className="mt-0 mx-auto mb-auto">
				<span className="text-redCustom">
					Vous ne pouvez pas accéder à cette page !
				</span>
				<br />
				<span>
					Reprenez votre activité en{" "}
					<Link className="text-secondary hover:underline" to="/">
						cliquant ici.
					</Link>
				</span>
			</div>
		</section>
	)
}

export default ForbiddenAccess
