import { useState } from "react"
import { PAGE, SIZE } from "../pages/Admin/Reports/Constants"

interface usePaginationAndSearchReturn {
	onSearch: (term: string) => void
	onChangeSize: (newSize: number) => void
	onChangePage: (pageNumber: number) => void
	onChangeSortBy: (sortby: string) => void
	sortBy: string
	query: string
	page: number
	size: number
}

function usePaginationSearchSortOnTable(): usePaginationAndSearchReturn {
	const [query, setQuery] = useState<string>("")
	const [page, setPage] = useState<number>(PAGE)
	const [size, setSize] = useState<number>(SIZE)
	const [sortBy, setSortBy] = useState<string>("")

	const onSearch = (term: string) => {
		setPage(PAGE)
		setQuery(term)
	}

	const onChangeSize = (newSize: number) => {
		setPage(PAGE)
		setSize(newSize)
	}
	const onChangePage = (pageNumber: number) => {
		setPage(pageNumber)
	}

	const onChangeSortBy = (sortByParam: string) => {
		setSortBy(sortByParam)
	}

	return {
		query,
		page,
		size,
		sortBy,
		onSearch,
		onChangeSize,
		onChangePage,
		onChangeSortBy,
	}
}

export default usePaginationSearchSortOnTable
