import React from "react"

interface TextAreaProps {
	label?: string
	name: string
	register: any
	required: boolean
	placeholder?: string
	defaultValue?: string
	error?: any
	className?: string
}

function TextArea({
	label,
	name,
	register,
	required = false,
	placeholder = "",
	error,
	defaultValue,
	className,
}: TextAreaProps) {
	return (
		<div className="input-group">
			<label
				htmlFor={name}
				data-testid="label"
				className="text-sm font-medium"
			>
				{error?.type !== "required" ? (
					label
				) : (
					<span className="input-error">
						{label ?? "Ce champ"} est requis
					</span>
				)}
			</label>
			<textarea
				{...register(name, { required })}
				name={name}
				defaultValue={defaultValue}
				id={name}
				placeholder={placeholder}
				data-testid="area"
				autoComplete={name}
				rows="3"
				className={`input-control ${className ?? ""} ${
					error ? "!border-redCustom" : ""
				}`}
			/>
		</div>
	)
}

export default TextArea
