import { StarIcon } from "@heroicons/react/outline"
import Card from "../../../components/Card/Card"
import ShowUserNotation from "../../../components/UserNotation/ShowUserNotation"
import User from "../../../api/models/User"

interface ProfileNoteProps {
	user: User
}

export default function ProfileNote({ user }: ProfileNoteProps) {
	return (
		<>
			<h2 className="profile-section flex flex-row items-center mt-6">
				<StarIcon className="w-8 h-8 mr-2" /> Ma note
			</h2>
			<Card className="p-3 mb-2 ">
				{user.note && user.noteCount ? (
					<>
						<ShowUserNotation
							className="pt-1"
							noteCount={user.noteCount}
							note={user.note}
						/>
						<p className="pt-2">
							Continuez à faire ou à récupérer des dons pour
							augmenter votre note !
						</p>
						<p className="pt-2">
							Plus votre note ⭐ sera haute, plus les autres
							utilisateurs vous feront confiance !
						</p>
					</>
				) : (
					<>
						<p className="pt-2">
							Vous n'avez pas encore d'avis. Faites ou récupérez
							des dons pour que des utilisateurs vous notent !
						</p>
						<p className="pt-2">
							Plus votre note ⭐ sera haute, plus les autres
							utilisateurs vous feront confiance !
						</p>
					</>
				)}
			</Card>
		</>
	)
}
