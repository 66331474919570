import Credentials from "../models/Credentials"
import User from "../models/User"
import AuthResponse from "../models/AuthResponse"
import GeneralService from "./GeneralService"

export class AuthService extends GeneralService<User | AuthResponse | any> {
	hasResetPasswordToken(token: any): Promise<number> {
		return this.get(`/${token}/reset-password`)
	}

	authenticate(credentials: Credentials): Promise<AuthResponse> {
		let data: any = {
			email: credentials.email,
			password: credentials.password,
		}
		if (credentials.facebookAccessToken) {
			data = {
				facebookAccessToken: credentials.facebookAccessToken,
			}
		}
		return this.post(data, "/authenticate")
	}

	confirmRegistration(token: any): Promise<any> {
		return this.post({ token }, "/confirm-registration")
	}

	register(user: User): Promise<any> {
		return this.post(user, "/register")
	}

	forgotPassword(email: string): Promise<any> {
		return this.post({ email }, "/forgot-password")
	}

	resetPassword(password: string, userId: number): Promise<any> {
		return this.post({ password, userId }, "/reset-password")
	}
}

const authService = new AuthService("authentication")

export default authService
