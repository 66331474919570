import Report from "../../../api/models/Report"
import Modal from "../../../components/Dialog/Modal"
import TableInstance from "../../../components/Table/Instance"
import usePaginationSearchSortOnTable from "../../../hooks/usePaginationSearchSortOnTable"
import { columns } from "./Constants"
import Contact from "./Contact"
import useReportsQuery from "./hooks/useReportsQuery"

function ReportsPage() {
	const {
		query,
		page,
		size,
		onChangeSize,
		onSearch,
		onChangePage,
		sortBy,
		onChangeSortBy,
	} = usePaginationSearchSortOnTable()
	const { reportsQuery, report, rows, setReport } = useReportsQuery(
		query,
		size,
		page,
		sortBy
	)

	return (
		<div className="page reports">
			<h1 className="text-center pb-3 pt-3">Les signalements</h1>

			<TableInstance
				cols={columns}
				rows={rows}
				query={{ value: query, setValue: onSearch }}
				setSortBy={onChangeSortBy}
				manualPagination
				page={page}
				size={size}
				setPage={onChangePage}
				setSize={onChangeSize}
				rowData={reportsQuery.data}
			/>

			<Modal
				title="Qui contacter ?"
				isOpen={report.id > 0}
				onClose={() => setReport(new Report())}
			>
				<Contact report={report} />
			</Modal>
		</div>
	)
}

export default ReportsPage
