import { useState } from "react"
import { useQuery } from "react-query"
import Category from "../../../api/models/Category"
import CategoryService from "../../../api/services/CategoryService"
import Button from "../../../components/Button/Button"
import CategoryForm from "./CategoryForm"

function CategoryPage() {
	const apiCategories = useQuery<Category[]>("categories", () =>
		CategoryService.getAllWithFilters()
	)

	const [addCategory, setAddCategory] = useState(false)
	return (
		<div className="page">
			<h1 className="text-center pb-3 pt-3">
				Les catégories et sous-catégories
			</h1>
			{apiCategories?.data?.map((category, index) => (
				<CategoryForm
					key={category.id}
					category={category}
					index={index + 1}
					setAddCategory={setAddCategory}
				/>
			))}

			{/* Add a dynamic category Form */}
			{addCategory && (
				<CategoryForm
					className="!border-primary"
					category={new Category()}
					// index === 0, means that we add a category at the end of the list
					index={0}
					setAddCategory={setAddCategory}
				/>
			)}
			<div className="pt-2 pb-2">
				<Button
					type="button"
					className="bg-primary w-3/4 m-auto block"
					onClick={() => setAddCategory(true)}
					disabled={addCategory}
				>
					Ajouter une catégorie
				</Button>
			</div>
		</div>
	)
}

export default CategoryPage
