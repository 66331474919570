import ChatRoomPost from "./ChatRoomPost"
import Post from "./Post"
import Reason from "./Reason"
import SimpleUser from "./SimpleUser"

class Report {
	id: number = 0

	user?: SimpleUser

	author: SimpleUser = new SimpleUser()

	post?: Post | ChatRoomPost

	reason: Reason = new Reason()

	createdAt: Date = new Date()

	updatedAt?: Date
}

export default Report
