import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { UseQueryResult, useQuery } from "react-query"
import Market from "../../../../api/models/Market"
import Page from "../../../../api/models/Page"
import ToggleStatusModel from "../../../../api/models/ToggleStatusModel"
import marketService from "../../../../api/services/MarketService"
import { Option } from "../../../../components/Form/Select/Select"
import Status from "../../../../enums/Status"

interface UseSelectMarketFormReturn {
	onSubmit: (data: ToggleStatusModel) => any
	marketId: string
	onChangeMarketSelection: (evt: any) => void
	getOptions: () => Option[]
	markets: UseQueryResult<Page<Market>>
}

function useSelectMarketForm(
	setSelectedStatus: React.Dispatch<React.SetStateAction<ToggleStatusModel>>,
	selectedStatus: ToggleStatusModel,
	updateStatus: (toggleStatus: ToggleStatusModel) => void
): UseSelectMarketFormReturn {
	const [marketId, setMarketId] = useState<string>("")

	useEffect(() => {
		if (markets.data) {
			const market = markets.data.content.find(
				m => m.id === Number(marketId)
			)
			setSelectedStatus({
				...selectedStatus,
				market,
				askedInMarket: !!marketId,
			})
		}
	}, [marketId])

	const markets = useQuery<Page<Market>>("markets", () =>
		marketService.getAllPaginated(
			{
				startedAt: dayjs().tz("Europe/Paris").toISOString(),
				endedAt: dayjs().tz("Europe/Paris").toISOString(),
			},
			"/unpaged"
		)
	)

	const getOptions = () => {
		const defaultOption: Option = {
			text: "Pas de marché sélectionné",
			value: "",
		}
		if (markets.data && markets.data.content) {
			return [
				defaultOption,
				...markets.data.content.map(m => ({
					text: m.name,
					value: String(m.id),
				})),
			]
		}
		return [defaultOption]
	}

	const onSubmit = () => {
		updateStatus({
			id: selectedStatus.id,
			askedInMarket: !!marketId,
			status: Status.ACTIVATED,
			market: selectedStatus.market,
		})
	}

	const onChangeMarketSelection = (evt: any) => {
		setMarketId(evt.target.value)
	}

	return {
		marketId,
		markets,
		onSubmit,
		onChangeMarketSelection,
		getOptions,
	}
}

export default useSelectMarketForm
