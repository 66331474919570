import { Control, Controller } from "react-hook-form"
import Button from "../../../components/Button/Button"
import Input from "../../../components/Form/Input/Input"

import ReactSelectOption from "../../../api/models/ReactSelectOption"
import User from "../../../api/models/User"
import LocationField from "../../../components/Form/Autocomplete/LocationField"
import Password from "../../../components/Form/Input/Password"
import ReactSelect from "../../../components/Form/Select/ReactSelect"
import { GENDER } from "../../../utils/Constants"

interface RegisterFormType {
	handleSubmit: any
	register: any
	errors: any
	onSubmit(data: User): void
	hidePassword: boolean
	onCheckboxChange(e: React.ChangeEvent<HTMLInputElement>): void
	title: string
	setValue(key: string, value: string): void
	CGUChecked: boolean
	control: Control<User, any>
}

function RegisterForm({
	handleSubmit,
	errors,
	register,
	onSubmit,
	hidePassword,
	onCheckboxChange,
	title,
	setValue,
	CGUChecked,
	control,
}: RegisterFormType) {
	return (
		<div className="register-method">
			<form
				className="form login-method-action"
				onSubmit={handleSubmit(onSubmit)}
			>
				<label
					className="text-sm font-medium
					"
				>
					Civilité
				</label>
				<Controller
					name="gender"
					control={control}
					render={({ field: { value, onChange, onBlur } }: any) => (
						<ReactSelect
							className="h-9"
							isSearchable
							options={GENDER}
							placeholder="Choisissez..."
							onBlur={onBlur}
							value={GENDER.find(
								(c: ReactSelectOption) => c.value === value
							)}
							onChange={(val: ReactSelectOption) =>
								onChange(val.value)
							}
						/>
					)}
				/>
				<Input
					label="Prénom"
					name="firstName"
					placeholder="Votre prénom"
					register={register}
					type="text"
					required
					error={errors.firstName}
				/>
				<Input
					label="Nom de famille"
					name="lastName"
					placeholder="Votre nom de famille"
					register={register}
					type="text"
					required
					error={errors.lastName}
				/>
				<Input
					label="Adresse e-mail"
					name="email"
					placeholder="Votre adresse e-mail"
					register={register}
					type="email"
					required
					error={errors.email}
				/>
				<LocationField
					register={register}
					error={errors.address}
					setValue={setValue}
					required
				/>
				<Input
					label="N° de téléphone"
					name="phone"
					placeholder="Votre n° de téléphone"
					register={register}
					type="tel"
					required
					error={errors.phone}
				/>
				{!hidePassword ? (
					<Password
						label="Mot de passe"
						name="password"
						placeholder="Votre mot de passe"
						register={register}
						required
						error={errors.password}
					/>
				) : (
					""
				)}
				<div className="pt-2">
					<input
						id="cgu"
						className="checkbox text-primary scale-150 ml-2"
						type="checkbox"
						onChange={onCheckboxChange}
						checked={CGUChecked}
						required
					/>
					<label
						htmlFor="cgu"
						className="pl-4 pr-2 text-sm font-medium cursor-pointer"
					>
						j'accepte les Conditions générales d'utilisation (CGU)
					</label>
				</div>
				<Button
					type="submit"
					className={`${
						hidePassword ? "bg-facebook" : "bg-primary"
					} w-full mt-5 mb-2`}
				>
					{title}
				</Button>
			</form>
		</div>
	)
}

export default RegisterForm
