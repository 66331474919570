import ReactFacebookLogin from "react-facebook-login/dist/facebook-login-render-props"
import Facebook from "../../../assets/images/Facebook.png"

const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID || ""

interface FacebookMethodType {
	facebookUsedClassName: string
	title: string
	responseFacebook(data: any): void
}

function FacebookMethod({
	facebookUsedClassName,
	title,
	responseFacebook,
}: FacebookMethodType) {
	return (
		<div className="register-methods">
			<h1 className="register-title">{title}</h1>
			<div className={facebookUsedClassName}>
				<div className="login-method-action">
					<ReactFacebookLogin
						appId={facebookAppId}
						callback={responseFacebook}
						fields="id,name,email,first_name,last_name"
						scope="public_profile,email"
						render={renderProps => (
							<button
								type="button"
								onClick={renderProps.onClick}
								className="button-facebook bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
							>
								<img src={Facebook} alt="FB" />
								<span>S'inscrire avec Facebook</span>
							</button>
						)}
					/>
				</div>
			</div>
		</div>
	)
}

export default FacebookMethod
