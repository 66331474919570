import ReactSelectOption from "../../../api/models/ReactSelectOption"
import Button from "../../../components/Button/Button"
import Card from "../../../components/Card/Card"
import { TABS_LIST_ITEMS } from "./Constants"
import usePostForm from "./hooks/basic/usePostForm"

interface PostFormType {
	preloadedData: any
	selectedIndex: number
	submitForm(formData: any): void
	setSelectedIndex(selectedIndex: number): void
	currentValidationForm(formStep: number): Array<string>
	exchangePlaceOptions: ReactSelectOption[]
}

function PostForm({
	preloadedData,
	selectedIndex,
	submitForm,
	setSelectedIndex,
	currentValidationForm,
	exchangePlaceOptions,
}: PostFormType) {
	const { form } = usePostForm(preloadedData)
	return (
		<form
			className="page add-post"
			onSubmit={form.handleSubmit(submitForm)}
		>
			<h1 className="text-center pb-3 pt-3">
				{preloadedData !== null
					? "Editer une annonce"
					: "Créer une annonce"}
			</h1>
			<div className="text-center mb-4 py-2 w-full bg-redCustom rounded shadow-sm">
				<h3 className="text-white">
					Attention, seuls les dons d'objets sont admissibles sur le
					site.
				</h3>
			</div>
			<Card>
				<ul className="w-full px-2 py-6 sm:px-0 flex flex-row">
					{TABS_LIST_ITEMS.map(t => (
						<li
							key={`tab-list-${t.position}`}
							className={` text-center w-full rounded-lg py-2.5 px-2 text-md font-bold leading-5 focus:outline-none ${
								t.position === selectedIndex
									? "bg-primary text-white shadow"
									: "text-primary"
							}  `}
						>
							{t.title}
						</li>
					))}
				</ul>
				<ul className="mt-2">
					{TABS_LIST_ITEMS.map(
						t =>
							t.position === selectedIndex && (
								<li
									key={`tag-panel-${t.position}`}
									className="p-3"
								>
									<t.Tab
										form={form}
										setSelectedIndex={setSelectedIndex}
										exchangePlaceOptions={
											exchangePlaceOptions
										}
									/>
								</li>
							)
					)}
				</ul>
				{selectedIndex !== 0 && (
					<div className="create-actions p-3">
						<Button
							type="button"
							className="bg-primary"
							disabled={selectedIndex === 0}
							onClick={() => setSelectedIndex(selectedIndex - 1)}
						>
							Précédent
						</Button>
						{selectedIndex !== 2 && (
							<Button
								type="button"
								className="bg-primary"
								onClick={async () => {
									const result = await form.trigger(
										currentValidationForm(selectedIndex)
									)
									if (result === true) {
										setSelectedIndex(selectedIndex + 1)
									}
								}}
							>
								Suivant
							</Button>
						)}
						{selectedIndex === 2 && (
							<Button type="submit" className="bg-primary">
								{preloadedData === null
									? "Publier l'annonce"
									: "Mettre à jour l'annonce"}
							</Button>
						)}
					</div>
				)}
			</Card>
		</form>
	)
}

export default PostForm
