import ToggleStatusModel from "../models/ToggleStatusModel"
import Post from "../models/Post"
import GeneralService from "./GeneralService"

class PostService extends GeneralService<Post> {
	toggleStatus(status: ToggleStatusModel) {
		return this.http
			.patch(`${this.url}/${status.id}/status`, status)
			.then(this.handleResponse)
			.catch(this.handleError)
	}
}

const postService = new PostService("posts")

export default postService
