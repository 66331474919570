import { useQuery, UseQueryResult } from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import Post from "../../../../api/models/Post"
import postService from "../../../../api/services/PostService"

interface UseGetPostQueryType {
	post: UseQueryResult<Post>
}

function useGetPostQuery(): UseGetPostQueryType {
	const navigate = useNavigate()
	const { id } = useParams()

	const post = useQuery<Post>("post", () => postService.getById(Number(id)), {
		retry: false,

		onError: () => {
			navigate("/")
		},
	})

	return { post }
}

export default useGetPostQuery
