import { Controller, useForm } from "react-hook-form"
import ReactSelect, { MultiValue } from "react-select"
import ReactSelectOption from "../../../api/models/ReactSelectOption"
import User from "../../../api/models/User"
import Button from "../../../components/Button/Button"
import Input from "../../../components/Form/Input/Input"
import { roleOptions } from "./Constants"
import RoleModel from "../../../api/models/RoleModel"

interface UserFormProps {
	user: User
	onSubmit: (data: User) => void
}

function UserForm({ user, onSubmit }: UserFormProps) {
	const form = useForm({
		defaultValues: user,
	})

	return (
		<form onSubmit={form.handleSubmit(data => onSubmit(data))}>
			<div className="mt-2 mb-5">
				<Input
					name="address"
					register={form.register}
					type="text"
					label="Adresse postale (en lecture seule)"
					error={form.formState.errors.address}
					required
					className="disabled"
					disabled
				/>
				<Controller
					name="roles"
					control={form.control}
					render={({ field: { value, onChange, onBlur } }: any) => (
						<ReactSelect
							className="my-3"
							options={roleOptions}
							isMulti
							isClearable
							isSearchable
							placeholder="Sélectionnez les rôles utilisateurs"
							onBlur={onBlur}
							onChange={(
								newValues: MultiValue<ReactSelectOption>
							) =>
								onChange(
									newValues.map(
										option =>
											new RoleModel(
												0,
												option.value.toString()
											)
									)
								)
							}
							value={roleOptions.filter(option =>
								value.some(
									(role: RoleModel) =>
										role.role === option.value
								)
							)}
						/>
					)}
				/>
				<div className="pt-2">
					<input
						id="activated"
						type="checkbox"
						{...form.register("activated")}
						className="checkbox text-primary scale-150 ml-2"
					/>
					<label
						htmlFor="activated"
						className="pl-4 pr-2 text-sm font-medium cursor-pointer"
					>
						Actif
					</label>
				</div>
			</div>

			<Button type="submit" className="bg-primary ">
				{user.id ? "Sauvegarder" : "Créer"}
			</Button>
		</form>
	)
}

export default UserForm
