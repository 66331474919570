import { useForm } from "react-hook-form"
import ExchangePlace from "../../../api/models/ExchangePlace"
import Button from "../../../components/Button/Button"
import LocationField from "../../../components/Form/Autocomplete/LocationField"
import Input from "../../../components/Form/Input/Input"

interface ExchangePlaceFormProps {
	exchangePlace: ExchangePlace
	onSubmit: (value: ExchangePlace) => void
}

function ExchangePlaceForm({
	exchangePlace,
	onSubmit,
}: ExchangePlaceFormProps) {
	const form = useForm<ExchangePlace>({ defaultValues: exchangePlace })
	return (
		<form onSubmit={form.handleSubmit(onSubmit)}>
			<div className="mt-2 mb-5">
				<Input
					name="name"
					register={form.register}
					required
					type="text"
					defaultValue={exchangePlace.name}
					error={form.formState.errors.name}
					label="Libellé"
					placeholder="Entrer un libellé"
				/>
				<LocationField
					register={form.register}
					error={form.formState.errors.address}
					setValue={form.setValue}
					required
				/>
			</div>

			<Button type="submit" className="bg-primary ">
				{exchangePlace.id ? "Sauvegarder" : "Créer"}
			</Button>
		</form>
	)
}

export default ExchangePlaceForm
