class PostType {
	id: number = 0

	label: string = ""

	constructor(id: number, label: string) {
		this.id = id
		this.label = label
	}
}

export default PostType
