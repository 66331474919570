import {
	ChatAlt2Icon,
	EyeIcon,
	PencilAltIcon,
	PencilIcon,
	TrashIcon,
} from "@heroicons/react/outline"
import dayjs from "dayjs"
import { useState } from "react"
import {
	UseMutationResult,
	UseQueryResult,
	useMutation,
	useQuery,
} from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import Market from "../../../../../api/models/Market"
import Page from "../../../../../api/models/Page"
import Post from "../../../../../api/models/Post"
import ToggleStatusModel from "../../../../../api/models/ToggleStatusModel"
import marketService from "../../../../../api/services/MarketService"
import postService from "../../../../../api/services/PostService"
import Status, { parseStatus } from "../../../../../enums/Status"
import { showConfirm, showConfirmAction } from "../../../../../utils/Popup"

interface UseMarketPostsAdminReturn {
	rows: any[]
	setSelectedPost: React.Dispatch<React.SetStateAction<Post>>
	selectedPost: Post
	market: UseQueryResult<Market>
	updateStatus: UseMutationResult<any, unknown, ToggleStatusModel>
	posts: UseQueryResult<Page<Post>>
}

function useMarketPostsAdmin(
	query: string,
	size: number,
	page: number,
	sortBy: string,
	statuses: Status[]
): UseMarketPostsAdminReturn {
	const { id } = useParams()
	const navigate = useNavigate()

	const [rows, setRows] = useState<any[]>([])
	const [selectedPost, setSelectedPost] = useState<Post>(new Post())

	const market = useQuery<Market>("market", () =>
		marketService.getById(Number(id))
	)

	const posts = useQuery(
		["posts", page, size, query, id, statuses, sortBy],
		() =>
			postService.getAllPaginated({
				page,
				size,
				marketId: Number(id),
				askedInMarket: true,
				query: query || undefined,
				sort: sortBy || undefined,
				statuses: statuses.length > 0 ? statuses.join(",") : undefined,
			}),
		{
			retry: false,
			keepPreviousData: false,
			onSuccess: (data: Page<Post>) => {
				setRows(
					data.content.map(p => ({
						...p,
						author: p.user
							? `${p.user.firstName} ${p.user.lastName}`
							: undefined,
						highlighting: p.reactivated,
						createdAt: dayjs(p.createdAt).format(
							"DD MMMM YYYY HH:mm"
						),
						state: p.reactivated
							? "Réactivé"
							: parseStatus(p.status),
						action: (
							<span className="cell-action">
								<span title="Consulter">
									<EyeIcon
										className="w-6 transition duration-200 text-secondary mr-2 cursor-pointer hover:scale-125"
										onClick={() =>
											window.open(
												`/post/view/${p.id}`,
												"_blank"
											)
										}
									/>
								</span>

								{p.status !== Status.ARCHIVED ? (
									<>
										{p.user ? (
											<span title="Envoyer un message">
												<ChatAlt2Icon
													className="w-6 transition duration-200 text-primary mr-2 cursor-pointer hover:scale-125"
													onClick={() =>
														navigate(
															`/start-chat/${
																p.user!.id
															}/post/${p.id}`
														)
													}
												/>
											</span>
										) : (
											""
										)}

										{p.status !== Status.TO_VALIDATE ? (
											<span title="Mettre à jour le statut">
												<PencilAltIcon
													className="w-6 transition duration-200 cursor-pointer text-primary mr-2 hover:scale-125"
													onClick={() =>
														toggleStatus(p)
													}
												/>
											</span>
										) : (
											""
										)}

										<span title="Editer">
											<PencilIcon
												className="w-6 transition duration-200 cursor-pointer text-primary mr-2 hover:scale-125"
												onClick={() =>
													navigate(
														`/post/edit/${p.id}`
													)
												}
											/>
										</span>

										<span title="Supprimer l'annonce">
											<TrashIcon
												className="w-6 transition duration-200 cursor-pointer text-redCustom mr-2 hover:scale-125"
												onClick={() =>
													showConfirmRemoval(p)
												}
											/>
										</span>
									</>
								) : (
									""
								)}
							</span>
						),
					}))
				)
			},
		}
	)

	const updateStatus = useMutation(
		(data: ToggleStatusModel) => postService.toggleStatus(data),
		{
			onSuccess: (_data, variables: ToggleStatusModel) => {
				posts.refetch()
				showConfirm(
					variables.status === Status.ACTIVATED
						? `L'annonce est désormais disponible sur le marché "${variables.market?.name}".`
						: `L'annonce n'est plus disponible sur le marché "${variables.market?.name}".`
				)
				setSelectedPost(new Post())
			},
		}
	)

	const deletePost = useMutation(
		(data: Post) => postService.delete(data.id),
		{
			onSuccess: (_data, variables: Post) => {
				posts.refetch()
				showConfirm(
					`L'annonce n°${variables.id} "${variables.title}" a été définitivement supprimée.`
				)
			},
		}
	)

	const toggleStatus = (data: Post) => {
		setSelectedPost(data)
	}

	const showConfirmRemoval = (data: Post) => {
		showConfirmAction(() => {
			deletePost.mutate(data)
		})
	}

	return {
		rows,
		selectedPost,
		setSelectedPost,
		market,
		updateStatus,
		posts,
	}
}

export default useMarketPostsAdmin
