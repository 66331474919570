import React from "react"

interface Suggestion {
	text: string
	value: string
}

interface InputProps {
	label: string
	name: string
	type: string
	register: any
	required: boolean
	error?: any
	className?: string
	onChange: any
	suggestions: Suggestion[]
	selectValue: any
	isLoading: boolean
}

function Autocomplete({
	label,
	name,
	type = "text",
	register,
	required = false,
	error,
	className,
	onChange,
	suggestions = [],
	selectValue,
	isLoading = false,
}: InputProps) {
	return (
		<div className="relative">
			<label htmlFor={name} className="text-sm font-medium">
				{error?.type !== "required" ? (
					label
				) : (
					<span className="input-error">{label} est requis</span>
				)}
			</label>
			<input
				{...register(name, { required })}
				type={type}
				name={name}
				id={name}
				placeholder="Commencer à taper..."
				autoComplete={name}
				className={`input-control ${className ?? ""} `}
				onChange={onChange}
			/>
			{isLoading && <div className="spin suggestions__spin" />}
			{suggestions.length > 0 ? (
				<ul className="suggestions">
					{suggestions.map(s => (
						// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
						<li
							key={s.value}
							className="suggestions-item"
							data-testid="suggestion"
							onClick={() => selectValue(Number(s.value))}
						>
							{s.text}
						</li>
					))}
				</ul>
			) : (
				""
			)}
		</div>
	)
}

export default Autocomplete
