import SubCategory from "./SubCategory"

class Category {
	id: number = 0

	name: string = ""

	subCategories: SubCategory[] = []

	numbersOfPosts: number = 0
}

export default Category
