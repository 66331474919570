import News from "../../../api/models/News"
import Card from "../../../components/Card/Card"
import { newsId } from "../../Home/Constants"
import useGetNewsAsEditorState from "./hooks/useGetNewsAsEditorState"
import usePatchNews from "./hooks/usePatchNews"
import NewsForm from "./NewsForm"

function NewsPage() {
	const { patchNews } = usePatchNews()
	const { content, setContent } = useGetNewsAsEditorState()
	return (
		<div className="page admin-news">
			<h1 className="text-center pb-3 pt-3">Les actualités</h1>

			{content && (
				<Card className="py-4 px-8">
					<NewsForm
						content={content}
						setContent={setContent}
						onSave={() =>
							patchNews.mutate(new News(newsId, content))
						}
					/>
				</Card>
			)}
		</div>
	)
}

export default NewsPage
