import Modal from "../../../components/Dialog/Modal"
import TableInstance from "../../../components/Table/Instance"
import usePaginationSearchSortOnTable from "../../../hooks/usePaginationSearchSortOnTable"
import { columns } from "./Constants"
import UserForm from "./UserForm"
import useUserForm from "./hooks/useUserForm"
import useUsersAdmin from "./hooks/useUsersAdmin"

function UserPage() {
	const {
		query,
		page,
		size,
		onChangeSize,
		onSearch,
		onChangePage,
		sortBy,
		onChangeSortBy,
	} = usePaginationSearchSortOnTable()

	const {
		openUserDialog,
		users,
		rows,
		selectedUser,
		isUserDialogOpen,
		closeUserDialog,
		saveItem,
	} = useUsersAdmin(query, size, page, sortBy)

	const { onSubmit } = useUserForm(saveItem)

	return (
		<div className="page admin-users">
			<h1 className="text-center pb-3 pt-3">Les utilisateurs</h1>
			<TableInstance
				cols={columns}
				rows={rows}
				openFormDialog={openUserDialog}
				query={{ value: query, setValue: onSearch }}
				manualPagination
				page={page}
				size={size}
				setPage={onChangePage}
				setSize={onChangeSize}
				rowData={users.data}
				setSortBy={onChangeSortBy}
			/>

			<Modal
				title={`Editer ${selectedUser.firstName} ${selectedUser.lastName}`}
				isOpen={isUserDialogOpen}
				onClose={closeUserDialog}
			>
				<UserForm user={selectedUser} onSubmit={onSubmit} />
			</Modal>
		</div>
	)
}

export default UserPage
