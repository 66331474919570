import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import "./assets/scss/index.scss"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"

const container = document.getElementById("root") as HTMLElement
const root = ReactDOM.createRoot(container)
root.render(
	<BrowserRouter>
		<App />
	</BrowserRouter>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
