class SubCategory {
	id: number = 0

	name: string = ""

	categoryId: number = 0

	numbersOfPosts: number = 0
}

export default SubCategory
