import { UseFormReturn } from "react-hook-form"
import ReactSelectOption from "../../../api/models/ReactSelectOption"
import PostInfoForm from "./PostInfoForm"
import PostLocationForm from "./PostLocationForm"
import PostTypeForm from "./PostTypeForm"

export interface ITabList {
	title: string
	position: number
	Tab: any
}

export interface ITabProps {
	form: UseFormReturn
	exchangePlaceOptions: ReactSelectOption[]
	setSelectedIndex: (index: number) => void
}
export const TABS_LIST_ITEMS: ITabList[] = [
	{
		title: "Choix du type d'annonce",
		position: 0,
		Tab: PostTypeForm,
	},
	{
		title: "Informations générales",
		position: 1,
		Tab: PostInfoForm,
	},
	{
		title: "Choix de l'adresse",
		position: 2,
		Tab: PostLocationForm,
	},
]

export const POST_TYPE_FORM_REQUIRED_INPUTS: Array<string> = ["type"]
export const POST_INFO_FORM_REQUIRED_INPUTS: Array<string> = [
	"title",
	"description",
	"categoryId",
	"subCategoryId",
]
export const POST_LOCATION_FORM_REQUIRED_INPUTS: Array<string> = [
	"exchangePlaceId",
	"address",
]
