import { useMutation, UseMutationResult } from "react-query"
import { useNavigate } from "react-router-dom"
import Post from "../../../../../api/models/Post"
import postService from "../../../../../api/services/PostService"
import { showConfirm } from "../../../../../utils/Popup"

interface UseAddPostQueryType {
	addPostQuery: UseMutationResult<Post, unknown, Post>
}

function useAddPostQuery(): UseAddPostQueryType {
	const navigate = useNavigate()
	const addPostQuery = useMutation((data: Post) => postService.post(data), {
		onSuccess: () => {
			showConfirm(
				"Votre annonce a bien été publiée sur la plateforme des Chrysalides.",
				"success",
				3000
			)
			navigate("/")
		},
	})

	return { addPostQuery }
}

export default useAddPostQuery
