import { PaperAirplaneIcon } from "@heroicons/react/outline"
import { UseFormReturn } from "react-hook-form"
import ChatMessage from "../../../api/models/ChatMessage"
import Input from "../../../components/Form/Input/Input"

interface ChatMessageFormProps {
	handleSubmit: any
	form: UseFormReturn<ChatMessage, any>
}

function ChatMessageForm({ handleSubmit, form }: ChatMessageFormProps) {
	return (
		<form
			className="chat-message-form"
			onSubmit={form.handleSubmit(handleSubmit)}
		>
			<Input
				name="message"
				placeholder="Votre message"
				register={form.register}
				type="text"
				required
				error={form.formState.errors.message}
			/>
			<button type="submit">
				<PaperAirplaneIcon className="w-8 text-primary rotate-90" />
			</button>
		</form>
	)
}

export default ChatMessageForm
