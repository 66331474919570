import { useState } from "react"
import { useMutation } from "react-query"
import Location from "../../../api/models/Location"
import geoService from "../../../api/services/GeoService"
import Autocomplete from "./Autocomplete"

interface LocationFieldProps {
	register: any
	error: any
	setValue: any
	required?: boolean
}

function LocationField({
	register,
	error,
	setValue,
	required = false,
}: LocationFieldProps) {
	const [suggestions, setSuggestions] = useState<Location[]>([])

	const getLocations = useMutation(
		(address: string) => geoService.findLocationsByTerm(address),
		{
			onSuccess: (locations: Location[]) => {
				setSuggestions(locations)
			},
		}
	)

	const handleChange = (evt: React.FormEvent<HTMLInputElement>) => {
		if (evt.currentTarget.value) {
			evt.currentTarget.value.length >= 3 &&
				getLocations.mutate(evt.currentTarget.value)
		} else {
			setValue("address", null)
			setValue("latitude", null)
			setValue("longitude", null)
		}
	}

	const selectAddress = (index: number) => {
		if (suggestions[index]) {
			setValue("address", suggestions[index].address)
			setValue("latitude", suggestions[index].latitude)
			setValue("longitude", suggestions[index].longitude)
			setSuggestions([])
		}
	}

	return (
		<Autocomplete
			name="address"
			register={register}
			required={required}
			type="text"
			error={error}
			label="Adresse postale"
			onChange={handleChange}
			selectValue={selectAddress}
			suggestions={suggestions.map((s, i) => ({
				text: s.address,
				value: String(i),
			}))}
			isLoading={getLocations.isLoading}
		/>
	)
}

export default LocationField
