import { useState } from "react"
import { useForm } from "react-hook-form"
import { useQuery } from "react-query"
import Page from "../../../api/models/Page"
import Reason from "../../../api/models/Reason"
import Report from "../../../api/models/Report"
import reasonService from "../../../api/services/ReasonService"
import Button from "../../Button/Button"
import Select, { Option } from "../../Form/Select/Select"

interface ReportFormProps {
	report: Report
	setReport: (newReport: Report) => any
	onSave: any
}

function ReportForm({ report, setReport, onSave }: ReportFormProps) {
	const [options, setOptions] = useState<Option[]>([
		{ text: "Sélectionner une raison", value: "" },
	])

	const form = useForm<{ reasonId: string }>({
		defaultValues: {
			reasonId: String(report.reason.id),
		},
	})

	const reasons = useQuery<Page<Reason>>(
		"reasons",
		() => reasonService.getAllPaginated(undefined, "/unpaged"),
		{
			onSuccess: (data: Page<Reason>) => {
				setOptions([
					...options,
					...data.content.map((r: Reason) => ({
						text: r.label,
						value: String(r.id),
					})),
				])
			},
		}
	)

	const handleChange = (evt: any) => {
		setReport({
			...report,
			reason:
				reasons.data?.content.find(
					(r: Reason) => r.id === Number(evt.target.value)
				) ?? new Reason(),
		})
	}

	const onSubmit = () => {
		onSave()
	}

	return (
		<form onSubmit={form.handleSubmit(onSubmit)}>
			<Select
				name="reason"
				register={form.register}
				required
				error={form.formState.errors.reasonId}
				value={String(report.reason.id) || ""}
				onChange={handleChange}
				label="Sélectionner une raison"
				options={options}
			/>

			<Button type="submit" className="bg-primary mt-4 w-full">
				Envoyer
			</Button>
		</form>
	)
}

export default ReportForm
