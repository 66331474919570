import Photo from "../../api/models/Photo"
import DefaultImage from "../../assets/images/Images-rafiki.svg"
import useCarousel from "./hooks/useCarousel"

interface CarouselProps {
	photos: Photo[]
}

function Carousel({ photos }: CarouselProps) {
	const { currentImage, sliderControl } = useCarousel(photos)

	return (
		<div className="carousel" data-testid="carousel">
			{photos.length > 0 ? (
				<>
					{photos.map((p: Photo, i: number) => {
						if (currentImage === i) {
							return (
								<img
									key={`photo_${i + 1}`}
									src={p.image}
									alt={`Photo n°${i + 1}`}
									data-testid={`photo_${i + 1}`}
									width="512"
								/>
							)
						}
						return <span />
					})}
					<div className="carousel-actions">
						{photos.length > 1 ? sliderControl(true) : ""}
						{photos.length > 1 ? sliderControl() : ""}
					</div>
				</>
			) : (
				<img
					src={DefaultImage}
					alt="Photo par défaut"
					data-testid="photo_default"
					key="photo_default"
					width="512"
				/>
			)}
		</div>
	)
}

export default Carousel
