import Alert from "./Alert"
import Location from "./Location"
import PostWithoutUser from "./PostWithoutUser"
import RoleModel from "./RoleModel"

class User extends Location {
	id: number = 0

	firstName: string = ""

	lastName: string = ""

	gender: string = ""

	email: string = ""

	password?: string = ""

	phone: string = ""

	facebookAccessToken: string = ""

	note?: number

	noteCount?: number

	roles: RoleModel[] = []

	alerts?: Alert[] = []

	posts?: PostWithoutUser[] = []

	activated: boolean = false

	facebookId: number = 0

	createdAt?: Date = new Date()

	updatedAt?: Date = new Date()
}

export default User
