import { ReactNode, useEffect, useMemo } from "react"
import { useSortBy, useTable } from "react-table"
import Page from "../../api/models/Page"
import { ITableColumn } from "../../utils/Constants"
import TableLayout from "./Layout"

interface TableInstanceProps<T> {
	rows: any[]
	cols: ITableColumn[]
	setSortBy?: (sortby: string) => void
	openFormDialog?: any
	query?: any
	manualPagination?: boolean
	page?: number
	size?: number
	setPage?: (newPage: any) => any
	setSize?: (newSize: any) => any
	rowData?: Page<T>
	Filter?: ReactNode
}

function TableInstance<T>({
	rows,
	cols,
	openFormDialog,
	query = {},
	manualPagination = false,
	page = 0,
	size = 0,
	setPage = () => 0,
	setSize = () => 0,
	rowData = new Page<T>(),
	Filter,
	setSortBy,
}: TableInstanceProps<T>) {
	const [columns, data] = useMemo(() => [cols, rows], [rows])

	const tableInstance = useTable(
		{
			columns,
			data,
			manualSortBy: true,
		},
		useSortBy
	)

	const { sortBy } = tableInstance.state

	useEffect(() => {
		if (sortBy.length > 0) {
			if (setSortBy !== undefined)
				setSortBy(`${sortBy[0].id},${sortBy[0].desc ? "desc" : "asc"}`)
		}
	}, [sortBy])

	return (
		<TableLayout
			Filter={Filter}
			query={query}
			{...tableInstance}
			openFormDialog={openFormDialog}
			pagination={manualPagination}
			rowData={rowData}
			page={page}
			size={size}
			setPage={setPage}
			setSize={setSize}
		/>
	)
}

export default TableInstance
