import Modal from "../../../components/Dialog/Modal"
import TableInstance from "../../../components/Table/Instance"
import usePaginationSearchSortOnTable from "../../../hooks/usePaginationSearchSortOnTable"
import { columns } from "./Constants"
import ExchangePlaceForm from "./ExchangePlaceForm"
import useExchangePlacesAdmin from "./hooks/useExchangePlacesAdmin"

function ExchangePlaces() {
	const {
		query,
		page,
		size,
		onChangeSize,
		onSearch,
		onChangePage,
		sortBy,
		onChangeSortBy,
	} = usePaginationSearchSortOnTable()

	const {
		rows,
		openPlaceDialog,
		exchangePlaces,
		place,
		isPlaceDialogOpen,
		closePlaceDialog,
		onSubmit,
	} = useExchangePlacesAdmin(query, size, page, sortBy)

	return (
		<div className="page places">
			<h1 className="text-center pb-3 pt-3">Les lieux d'échanges</h1>
			<TableInstance
				cols={columns}
				rows={rows}
				openFormDialog={openPlaceDialog}
				query={{ value: query, setValue: onSearch }}
				manualPagination
				page={page}
				size={size}
				setSortBy={onChangeSortBy}
				setPage={onChangePage}
				setSize={onChangeSize}
				rowData={exchangePlaces.data}
			/>

			<Modal
				title={place.id ? "Editer un lieu" : "Créer un lieu"}
				isOpen={isPlaceDialogOpen}
				onClose={closePlaceDialog}
			>
				<ExchangePlaceForm exchangePlace={place} onSubmit={onSubmit} />
			</Modal>
		</div>
	)
}

export default ExchangePlaces
