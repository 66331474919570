class AuthResponse {
	firstName: string = ""

	lastName: string = ""

	jwtToken: string = ""

	id: number = 0
}

export default AuthResponse
