import React, { useEffect, useState } from "react"
import { useQuery } from "react-query"
import Category from "../../../api/models/Category"
import SubCategory from "../../../api/models/SubCategory"
import categoryService from "../../../api/services/CategoryService"
import Select, { Option } from "./Select"

interface CategoryFieldProps {
	register: any
	category: Input
	subCategory: Input
	getValues: any
	setValue: any
	required?: boolean
}

interface Input {
	error: any
	value?: string
	label: string
}

function CategoryField({
	register,
	category,
	subCategory,
	getValues,
	setValue,
	required = true,
}: CategoryFieldProps) {
	const [cat, setCat] = useState<string>("")
	const [subCat, setSubCat] = useState<string>("")
	const [subOptions, setSubOptions] = useState<Option[]>([
		{
			text: "Sélectionner une sous-catégorie",
			value: "",
		},
	])

	const categories = useQuery<Category[]>("categories", () =>
		categoryService.getAllWithFilters()
	)

	const getCategoryOptions = () => {
		if (categories.data) {
			return [
				{
					text: "Selectionner une catégorie",
					value: "",
				},
				...categories.data.map(c => ({
					text: c.name,
					value: String(c.id),
				})),
			]
		}
		return []
	}

	const handleChangeCategory = (evt: any) => {
		setCat(evt.target.value)
		if (categories.data) {
			const catVal = categories.data.find(
				c => c.id === Number(evt.target.value)
			)
			setValue("categoryId", evt.target.value || "")
			setValue("category", evt.target.value ? catVal : undefined)
		}
	}

	const handleChangeSubCategory = (evt: any) => {
		setSubCat(evt.target.value)
		if (categories.data) {
			const catVal = categories.data.find(
				c => c.id === Number(getValues().categoryId)
			)
			if (cat) {
				const subCatVal = catVal?.subCategories.find(
					s =>
						s.id ===
						Number(evt.target.value || getValues().subCategoryId)
				)
				setValue("subCategoryId", evt.target.value || "")
				setValue(
					"subCategory",
					evt.target.value ? subCatVal : undefined
				)
			}
		}
	}

	useEffect(() => {
		handleChangeCategory({
			target: { value: String(category.value || getValues().categoryId) },
		})
		handleChangeSubCategory({
			target: {
				value: String(subCategory.value || getValues().subCategoryId),
			},
		})
	}, [])

	useEffect(() => {
		if (getValues().category) {
			setSubOptions([
				{
					text: "Sélectionner une sous-catégorie",
					value: "",
				},
				...getValues().category.subCategories.map((s: SubCategory) => ({
					text: s.name,
					value: String(s.id),
				})),
			])
		} else {
			setSubOptions([
				{
					text: "Sélectionner une sous-catégorie",
					value: "",
				},
			])
		}
	}, [getValues().categoryId])

	return (
		<>
			<Select
				name="categoryId"
				register={register}
				error={category.error}
				label={category.label}
				value={cat}
				required={required}
				onChange={handleChangeCategory}
				options={getCategoryOptions()}
			/>

			<Select
				name="subCategoryId"
				register={register}
				error={subCategory.error}
				label={subCategory.label}
				value={subCat}
				required={required}
				onChange={handleChangeSubCategory}
				options={subOptions}
			/>
		</>
	)
}

export default CategoryField
