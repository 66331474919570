import { UseFormReturn, useForm } from "react-hook-form"
import { UseQueryResult, useMutation, useQuery } from "react-query"
import { useContext } from "react"
import UpdatePassword from "../../../../api/models/UpdatePassword"

import User from "../../../../api/models/User"
import { showConfirm } from "../../../../utils/Popup"
import selfService from "../../../../api/services/SelfService"
import UserContext from "../../../../contexts/UserContext"

interface UseChangePasswordFormReturn {
	onProfilSubmit: (user: User) => void
	onPasswordSubmit: (updatePassword: UpdatePassword) => void
	passwordForm: UseFormReturn<UpdatePassword>
	selfUser: UseQueryResult<User>
}

export default function useChangePasswordForm(): UseChangePasswordFormReturn {
	const passwordForm = useForm<UpdatePassword>()
	const { setUser } = useContext(UserContext)

	const selfUser = useQuery<User>(
		"currentUser",
		() => selfService.getCurrentUser(),
		{
			onSuccess: (user: User) => {
				passwordForm.setValue("userId", user.id)
			},
		}
	)

	const patchProfile = useMutation(
		(user: User) => selfService.patchProfile(user),
		{
			onSuccess: (reponse, variables: User) => {
				showConfirm(
					"Vos nouvelles informations ont bien été sauvegardées."
				)
				const user = localStorage.getItem("user")
				if (user != null) {
					const parsedUser: User = JSON.parse(user)
					parsedUser.firstName = variables.firstName
					parsedUser.lastName = variables.lastName
					localStorage.setItem("user", JSON.stringify(parsedUser))
					setUser(parsedUser)
				}
			},
		}
	)

	const patchPassword = useMutation(
		(updatePassword: UpdatePassword) =>
			selfService.patchPassword(updatePassword),
		{
			onSuccess: () => {
				showConfirm(
					"Votre nouveau mot de passe a bien été sauvegardée."
				)
				passwordForm.setValue("oldPassword", "")
				passwordForm.setValue("newPassword", "")
				passwordForm.setValue("confirmPassword", "")
			},
		}
	)

	const onProfilSubmit = (user: User) => {
		if (user.latitude && user.longitude) {
			patchProfile.mutate(user)
		} else {
			showConfirm("Vous devez sélectionner une adresse.", "error")
		}
	}

	const onPasswordSubmit = (updatePassword: UpdatePassword) => {
		patchPassword.mutate(updatePassword)
	}

	return {
		onProfilSubmit,
		onPasswordSubmit,
		passwordForm,
		selfUser,
	}
}
