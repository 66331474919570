import ChatMessage from "../models/ChatMessage"
import ChatRoom from "../models/ChatRoom"
import Page from "../models/Page"
import GeneralService from "./GeneralService"

export class ChatRoomService extends GeneralService<ChatRoom | any> {
	getChatMessages(filters: Object, id: number): Promise<Page<ChatMessage>> {
		return this.getAllPaginated(filters, `/${id}/messages`)
	}
}

const chatRoomService = new ChatRoomService("chat-rooms")

export default chatRoomService
