import { LatLngExpression } from "leaflet"
import { Circle, MapContainer, TileLayer } from "react-leaflet"

interface MapProps {
	position: LatLngExpression[]
}

function Map({ position }: MapProps) {
	return (
		<MapContainer
			center={position[0]}
			zoom={12}
			scrollWheelZoom={false}
			style={{ height: 400, width: "100%" }}
		>
			<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
			{position.map(p => (
				<Circle
					key={p.toString()}
					center={p}
					fillColor="#f03"
					fillOpacity={0.3}
					pathOptions={{ color: "red" }}
					radius={2500}
				/>
			))}
		</MapContainer>
	)
}

export default Map
