import { ITableColumn } from "../../../../utils/Constants"

export const PAGE: number = 0
export const SIZE: number = 10

export const columns: ITableColumn[] = [
	{
		Header: "Référence",
		accessor: "id",
	},
	{
		Header: "Titre",
		accessor: "title",
	},
	{
		Header: "Etat",
		accessor: "state",
	},
	{
		Header: "Auteur",
		accessor: "author",
	},
	{
		Header: "Créé le",
		accessor: "createdAt",
	},
	{
		accessor: "action",
		Header: "Actions",
	},
]
