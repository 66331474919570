import { Line } from "rc-progress"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation, useQuery } from "react-query"
import { useNavigate } from "react-router-dom"
import GtuAnswer from "../../../../api/models/GtuAnswer"
import GtuQuestion from "../../../../api/models/GtuQuestion"
import gtuQuestionService from "../../../../api/services/GtuQuestionService"
import Button from "../../../../components/Button/Button"
import Checkbox from "../../../../components/Form/Input/Checkbox"

interface FormAnswer {
	id: number
	checked: boolean
}

function Quiz() {
	const navigate = useNavigate()
	const [currentQuestion, setCurrentQuestion] = useState<number>(0)
	const [answers, setAnswers] = useState<number[]>([])
	const [submittedResponse, setSubmittedResponse] = useState<boolean>(false)
	const [formAnswers, setFormAnswers] = useState<FormAnswer[]>([])

	const questions = useQuery<GtuQuestion[]>(
		[],
		() => gtuQuestionService.findAllQuestions(),
		{
			retry: false,
		}
	)

	const form = useForm()

	const checkQuestion = useMutation(
		(id: number) => gtuQuestionService.checkById(id),
		{
			onSuccess: (correctAnswers: number[]) => {
				setAnswers(correctAnswers)
				setSubmittedResponse(true)
			},
		}
	)

	const handleValidate = () => {
		checkQuestion.mutate(questions.data![currentQuestion].id)
	}

	const handleNext = () => {
		if (currentQuestion === questions.data!.length - 1) {
			localStorage.setItem("quiz_status", "success")
			navigate("/register")
		} else {
			setCurrentQuestion(currentQuestion + 1)
			setAnswers([])
			setFormAnswers([])
			form.reset()
			setSubmittedResponse(false)
		}
	}

	const handleCheck = (answer: GtuAnswer, checked: boolean) => {
		const temp: FormAnswer[] = [...formAnswers]
		if (checked) {
			temp.push({
				id: answer.id,
				checked,
			})
		} else {
			const currentIndex = formAnswers.findIndex(x => x.id === answer.id)
			temp.splice(currentIndex, 1)
		}
		setFormAnswers(temp)
	}

	const getClasses = (answer: GtuAnswer): string => {
		if (submittedResponse) {
			if (answers.includes(answer.id)) {
				return "text-green outline-green"
			}
			return "text-redCustom outline-red"
		}
		return ""
	}

	const getLabelClasses = (answer: GtuAnswer): string => {
		if (submittedResponse) {
			if (answers.includes(answer.id)) {
				return "text-green"
			}
			return "text-redCustom"
		}
		return ""
	}

	const goRules = () => {
		navigate("/rules")
	}

	return (
		<form
			className="page rules"
			onSubmit={form.handleSubmit(handleValidate)}
		>
			{questions?.data && (
				<>
					<h1 className="rules-title">
						Question {currentQuestion + 1} /{" "}
						{questions.data?.length}
					</h1>

					<Line
						className="max-w-5xl mx-auto"
						percent={
							((currentQuestion + 1) * 100) /
							questions?.data!.length
						}
						strokeColor="#5D3589"
					/>
				</>
			)}
			<div className="rules-card max-w-5xl ">
				<h3 className="text-center mb-4 text-color">
					{questions.data &&
						`${currentQuestion + 1}. ${
							questions.data![currentQuestion].text
						}`}
				</h3>
				<div className="rules-answers">
					{questions.data &&
						questions.data![currentQuestion].answers?.map(
							(a, i) => (
								<Checkbox
									label={a.text}
									name={`answer-${i}`}
									register={form.register}
									checked={
										formAnswers.find(x => x.id === a.id)
											?.checked ?? false
									}
									onChange={(evt: any) =>
										handleCheck(a, evt.target.checked)
									}
									key={`answer-${i}`}
									labelClassName={getLabelClasses(a)}
									className={`scale-150 ml-2 ${getClasses(
										a
									)}`}
								/>
							)
						)}
				</div>
				<div className="rules-actions">
					<Button
						type="button"
						className="border-redCustom  !text-redCustom"
						onClick={goRules}
					>
						Recommencer le quiz
					</Button>
					<div>
						<Button
							type="submit"
							className="border-secondary !text-secondary mr-1"
						>
							Valider
						</Button>
						<Button
							type="button"
							className="bg-primary"
							disabled={!submittedResponse}
							onClick={handleNext}
						>
							{currentQuestion + 1 === questions.data?.length
								? "Passer à l'inscription"
								: "Suivant"}
						</Button>
					</div>
				</div>
			</div>
		</form>
	)
}

export default Quiz
